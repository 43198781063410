const GEO_SERVICE = '/geo-service';
const API_VERSION = '/api/v1';

const geo = {
  location: {
    country: '/lookup-service/api/v1/country/search',
    city: '/lookup-service/api/v1/city/search',
    getCountryById: `${GEO_SERVICE}${API_VERSION}/country`,
    getCountryByCode: `${GEO_SERVICE}${API_VERSION}/location/country`,
    getCityById: `${GEO_SERVICE}${API_VERSION}/city`,
    searchPlace: `${GEO_SERVICE}${API_VERSION}/place/search`,
    placeDetail: `${GEO_SERVICE}${API_VERSION}/place`,
    suggestPlace: `${GEO_SERVICE}${API_VERSION}/place/suggest`,
    suggestCity: `${GEO_SERVICE}${API_VERSION}/place/suggest-city`,
    searchCity: `${GEO_SERVICE}${API_VERSION}/city/search`,
    searchCountry: `${GEO_SERVICE}${API_VERSION}/country/search`,
  },
  discover: {
    place: `${GEO_SERVICE}${API_VERSION}/auto-suggest-place`,
    searchCity: `${GEO_SERVICE}${API_VERSION}/city/search`,
  },
  searchTimezone: `${GEO_SERVICE}${API_VERSION}/timezone/search`,
  getTimeZone: `${GEO_SERVICE}${API_VERSION}/timezone`,
};

export default geo;
