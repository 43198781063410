import request from '../utils/request';
import { lookupEndpoints } from '../constants';
import type {
  IIndustry,
  ICountry,
  PaginateResponse,
  CategoryType,
} from '../types';
import { lookupNormalizer } from '..';

export const getPopularPageCategories = async (): Promise<
  PaginateResponse<CategoryType>
> => {
  const { data } = await request.get(lookupEndpoints.page.popularCategories);

  return data;
};
export const getPopularPeopleCategories = async (): Promise<
  PaginateResponse<CategoryType>
> => {
  const { data } = await request.get(lookupEndpoints.people.popularCategories);

  return data;
};

export const getCategory = async (id: string): Promise<CategoryType> => {
  const { data } = await request.get(lookupEndpoints.page.category(id));

  return data;
};

export const getOccupationById = async ({
  params,
}: {
  params: {
    title: string;
  };
}): Promise<Array<ICountry>> => {
  const { data } = await request.get(lookupEndpoints.getOccupationById, {
    params,
  });
  return data;
};

export const getIndustryById = async ({
  params,
}: {
  params: {
    industryIds: Array<string>;
  };
}): Promise<Array<IIndustry>> => {
  const { data } = await request.get(lookupEndpoints.getIndustryById, {
    params,
  });
  return data;
};

export const searchBenefits = async ({
  params,
}: {
  params: {
    text: string;
  };
}): Promise<Array<ICountry>> => {
  const { data } = await request.get(lookupEndpoints.getBenefit, {
    params,
  });
  return lookupNormalizer.searchBenefits(data);
};

export default {
  getPopularPageCategories,
  getCategory,
  getOccupationById,
  getIndustryById,
  getPopularPeopleCategories,
};
