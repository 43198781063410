// import _useTranslation from 'next-translate/useTranslation';
// import Cookies from '../utils/cookies';
// import { type siteLanguages } from '../site-settings/app-settings';
// import getBrowserLanguage from '../utils/getBrowserLanguage';
// import { useSetLanguage } from './useSetLanguage';
// import enLocales from '../../../../locales/en/common.json';
//
// type TFunction = (arg: string) => string;
// export type ChangeLanguageType = (lng: string) => void;
// export type UseTranslationType = {
//   t: TFunction;
//   changeLanguage: ChangeLanguageType;
//   currentLanguage: keyof typeof siteLanguages;
// };
//
// const useTranslation = (): UseTranslationType => {
//   const { t, lang } = _useTranslation('common');
//   const currentLanguage = getBrowserLanguage();
//   const setLanguage = useSetLanguage();
//
//   const changeLanguage: ChangeLanguageType = (lng) => {
//     if (currentLanguage !== lng) {
//       Cookies.set('lng', lng);
//       setLanguage(lng);
//     }
//   };
//
//   function _t(key: string): string {
//     return enLocales?.[key] || key;
//   }
//
//   return { t: _t, changeLanguage, currentLanguage };
// };
//
// export default useTranslation;

import { useLanguage } from '../contexts/Language';

interface Translations {
  [key: string]: string;
}

const useTranslation = () => {
  const { language, switchLanguage } = useLanguage();

  const t = (key: string): string => {
    let translations: Translations = {};
    try {
      translations = require(`../locales/${language}/common.json`);
    } catch (error) {
      console.error(`Translation file for '${language}' not found.`);
    }
    return translations[key] || key;
  };

  return { t, changeLanguage: switchLanguage, currentLanguage: language };
};

export default useTranslation;
