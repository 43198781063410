const isElementVisible = (el: any) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;
  const efp = (x: any, y: any) => document.elementFromPoint(x, y);

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false;

  // Return true if any of its four corners are visible
  return (
    (el.contains(efp(rect.left + 10, rect.top + 10)) ||
      el.contains(efp(rect.right - 10, rect.top + 10))) &&
    (el.contains(efp(rect.right - 10, rect.bottom - 10)) ||
      el.contains(efp(rect.left + 10, rect.bottom - 10)))
  );
};

export default isElementVisible;
