export const siteLanguages: any = {
  en: 'en',
  tr: 'tr',
  es: 'es',
  fr: 'fr',
  de: 'de',
} as const;

export const siteLanguagesEnum: any = {
  en: { value: 'en', label: 'English', iso2: 'us' },
  tr: { value: 'tr', label: 'Turkish', iso2: 'tr' },
  fr: { value: 'fr', label: 'French', iso2: 'fr' },
  de: { value: 'de', label: 'German', iso2: 'de' },
  es: { value: 'es', label: 'Spanish', iso2: 'es' },
};
export const POPULAR_PAGES_TITLE_LIMITATION = 25;
export const XS_MOBILE = 320;
export const MID_MOBILE = 375;
export const MID_TABLET = 769;
export const LG_TABLET = 1210;
