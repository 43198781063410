import React from 'react';

const useModalCount = (): void => {
  React.useEffect(() => {
    const { body } = document;
    body.style.overflow = 'hidden';
    body.setAttribute(
      'data-modal-count',
      `${(Number(body.getAttribute('data-modal-count')) || 0) + 1}`
    );

    return () => {
      const unmountModalCount = (Number(body.getAttribute('data-modal-count')) || 0) - 1;
      if (unmountModalCount > -1) {
        body.setAttribute('data-modal-count', `${unmountModalCount}`);
      }
      if (unmountModalCount === 0) {
        body.style.overflow = 'unset';
      }
    };
  }, []);  
}

export default useModalCount;
