import formValidator from './formValidator';

export const emailValidation2 = (t: Function): any =>
  formValidator.object().shape({
    email: formValidator
      .string()
      .required(t('req_email'))
      .email(t('enter_valid_email')),
  });

export const getEmailValidation = (t: Function): any =>
  formValidator.object().shape({
    email: formValidator
      .string()
      .required(t('req_email'))
      .email(t('enter_valid_email')),
    password: formValidator
      .string()
      .required(t('req_password'))
      .min(6, t('password_length')),
  });
export const emailValidation = (t: Function): any =>
  formValidator.object().shape({
    email: formValidator
      .string()
      .required(t('req_email'))
      .email(t('enter_valid_email')),
    password: formValidator
      .string()
      .required(t('req_password'))
      .min(6, t('password_length')),
  });

export const getCountryValidation = formValidator.object().shape({
  country: formValidator.object().required('req_country'),
  city: formValidator.object().required('req_cityDistrict'),
});

export const resetPassWordValidation = (t: Function): any =>
  formValidator.object().shape({
    password: formValidator
      .string()
      .required(t('req_password'))
      .min(6, t('password_length')),
    cPassword: formValidator
      .string()
      .required(t('req_password'))
      .oneOf([formValidator.ref('password'), null], t('password_not_match')),
  });

export const getXEmailValidation = (t: Function): any =>
  formValidator.object().shape({
    xemail: formValidator
      .string()
      .required(t('req_email'))
      .email(t('enter_valid_email')),
    xpassword: formValidator
      .string()
      .required(t('req_password'))
      .min(6, t('password_length')),
  });

export const getCountryValidation2 = formValidator.object().shape({
  countryId: formValidator
    .object()
    .test('value', 'select_one_of_sug_countries', (val) => val?.value),
  cityId: formValidator
    .object()
    .test('value', 'select_one_of_sug_cites', (val) => val?.value),
});
