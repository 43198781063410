const STATISTICS_SERVICE = '/statistics-service';
const API_VERSION = '/api/v1';

const statisticsEndpoints = {
  counter: `${STATISTICS_SERVICE}${API_VERSION}/count`,
  topPosts: `${STATISTICS_SERVICE}${API_VERSION}/top-posts`,
  diagram: {
    follow: `${STATISTICS_SERVICE}${API_VERSION}/histogram/follow`,
    view: `${STATISTICS_SERVICE}${API_VERSION}/histogram/view`,
  },
};

export default statisticsEndpoints;
