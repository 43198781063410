import { APP_ENTITIES, db, locationZoomLevelValues } from '../../constants';
import type {
  PageApiResponseType,
  BeforeCachePageDetailType,
} from '../../types';
import collectionToObjectByKey from '../collectionToObjectByKey';

const pagesCategories = collectionToObjectByKey(db.CATEGORY_TYPES);
const companySizes = collectionToObjectByKey(db.COMPANY_SIZE_TYPES);

const beforeCachePageDetail = (
  pageData: PageApiResponseType
): BeforeCachePageDetailType => {
  const {
    username,
    category,
    companySize,
    industryName,
    industryLookupId,
    areaOfInterestLookupId,
    areaOfInterestName,
    locations,
    pageNetworkModel,
    title,
    ownerId,
    languageLookupId,
    languageName,
    ...rest
  } = pageData;
  return {
    ...rest,
    type: APP_ENTITIES.page,
    isPage: true,
    title,
    category: pagesCategories[category as any],
    companySize: companySizes[companySize as any],
    network: pageNetworkModel,
    username,
    fullName: title,
    usernameAtSign: `@${username}`,
    pageLink: `/${username}`,
    // establishmentDate: establishmentDate && formatDate(establishmentDate, 'y'),
    industry: {
      label: industryName,
      value: industryLookupId,
    },
    language: {
      label: languageName,
      value: languageLookupId,
    },
    areaOfInterest: {
      label: areaOfInterestName,
      value: areaOfInterestLookupId,
    },
    locations: locations?.map((location) => ({
      name: location.name,
      id: location?.id,
      location: {
        lat: location?.lat,
        lon: location?.lon,
        externalId: location?.externalId,
        countryCode: location?.countryCode,
        cityCode: location?.cityCode,
        category: location?.category,
        label: location?.title,
        title: location?.title,
        value: location?.externalId,
        privacy: location?.access,
        // @ts-ignore
        zoom: locationZoomLevelValues[location?.category] || 10,
      },
    })),
    ownerId,
  };
};

export default beforeCachePageDetail;
