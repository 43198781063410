const AUTH_SERVICE = '/auth-service';
const API_VERSION = '/api/v1';

const auth = {
  refreshToken: `${AUTH_SERVICE}${API_VERSION}/refresh`,
  enterToPage: `${AUTH_SERVICE}${API_VERSION}/enter-to-page`,
  usernameDetector: `${AUTH_SERVICE}${API_VERSION}/detect-username`,
  name: `${AUTH_SERVICE}${API_VERSION}/users/name`,
  username: `${AUTH_SERVICE}${API_VERSION}/users/username`,
  phone: `${AUTH_SERVICE}${API_VERSION}/users/phone`,
  occupation: `${AUTH_SERVICE}${API_VERSION}/users/occupation`,
  location: `${AUTH_SERVICE}${API_VERSION}/users/location`,
  birthDate: `${AUTH_SERVICE}${API_VERSION}/users/birth-date`,
  gender: `${AUTH_SERVICE}${API_VERSION}/users/gender`,
  deactivate: `${AUTH_SERVICE}${API_VERSION}/users/deactivate`,
  deleteMe: `${AUTH_SERVICE}${API_VERSION}/users/me`,
  logout: `${AUTH_SERVICE}${API_VERSION}/logout`,
  password: {
    check: `${AUTH_SERVICE}${API_VERSION}/users/check-password`,
    change: `${AUTH_SERVICE}${API_VERSION}/change-password`,
    forgetPassword: `${AUTH_SERVICE}${API_VERSION}/forgot-password`,
  },
  device: {
    register: `${AUTH_SERVICE}${API_VERSION}/devices`,
    getAll: `${AUTH_SERVICE}${API_VERSION}/devices/logged-in`,
    logoutOthers: `${AUTH_SERVICE}${API_VERSION}/logout-from-other-devices`,
  },
  email: {
    base: `${AUTH_SERVICE}${API_VERSION}/emails`,
    resend: `${AUTH_SERVICE}${API_VERSION}/resend-email-verification`,
    updatePrivacy: `${AUTH_SERVICE}${API_VERSION}/users/email-access`,
    primary: (email: string): string =>
      `${AUTH_SERVICE}${API_VERSION}/emails/${email}/primary`,
    remove: (email: string): string =>
      `${AUTH_SERVICE}${API_VERSION}/emails/${email}`,
  },
  setting: `${AUTH_SERVICE}${API_VERSION}/setting`,
  setAllowNonLoboxUsersToFindYou: `${AUTH_SERVICE}${API_VERSION}/set-allow-non-lobox-users-to-find-you`,
  setAllowPageRoleAssign: `${AUTH_SERVICE}${API_VERSION}/users/set-page-role-assign`,
  checkIsTwoFactorAuthActive: `${AUTH_SERVICE}${API_VERSION}/totp/me/active`,
  enableTwoFactorAuth: `${AUTH_SERVICE}${API_VERSION}/totp/enable`,
  confirmTwoFactorAuth: `${AUTH_SERVICE}${API_VERSION}/totp/confirm`,
  getBackupCode: `${AUTH_SERVICE}${API_VERSION}/totp/backup-code`,
  disableTwoFactorAuth: `${AUTH_SERVICE}${API_VERSION}/totp/deactivate`,
  loginWithTwoFactorAuth: `${AUTH_SERVICE}${API_VERSION}/login/totp`,
  clientAppSetting: `${AUTH_SERVICE}${API_VERSION}/client-app/setting`,
};

export default auth;
