import type { IMeeting, ISchedulesEvent } from '../../types/Schedules';
import { schedulesDb } from '../../constants';
import collectionToObjectByKey from '../collectionToObjectByKey';
import beforeCacheUserInfo from './beforeCacheUserInfo';
import { schedulesEventValues } from '../../constants/enums';
import type { PaginateResponse } from '../../types';

const meetingReminders = collectionToObjectByKey(schedulesDb.meetingReminder);
const meetingDurations = collectionToObjectByKey(schedulesDb.meetingDuration);
const meetingTypes = collectionToObjectByKey(schedulesDb.meetingType);
const meetingTools = collectionToObjectByKey(schedulesDb.meetingTools);
const timeOptions = collectionToObjectByKey(schedulesDb.timeOptions);
const contactTypes = collectionToObjectByKey(schedulesDb.meetingModel);

const getMeetingDetails = ({
  duration,
  meetingType,
  contactType,
  remind,
  externalConferenceProviderType,
  startTime,
  attendees,
  timezoneLabel,
  timezoneId,
  timezoneCode,
  timezoneOffset,
  creator,
  location,
  ...rest
}: any): IMeeting => ({
  ...rest,
  schedulesEventType: schedulesEventValues.MEETING,
  attendees: attendees?.map((user: any) => ({
    ...beforeCacheUserInfo(user?.userModel || {}),
    permissions: user?.permissions || [],
  })),
  duration: meetingDurations[duration],
  remind: meetingReminders[remind],
  meetingType: meetingTypes[meetingType],
  contactType: contactTypes[contactType],
  externalConferenceProviderType: meetingTools[externalConferenceProviderType],
  timezone: {
    value: timezoneId,
    label: timezoneLabel,
    code: timezoneCode,
    offset: timezoneOffset,
  },
  startTime: timeOptions[startTime],
  creator: beforeCacheUserInfo(creator || {}),
  location: location
    ? [
        {
          location: {
            ...location,
            label: location?.title,
            value: location?.externalId,
          },
        },
      ]
    : undefined,
});

const getReminderDetails = ({ startTime, creator, ...rest }: any) => ({
  ...rest,
  schedulesEventType: schedulesEventValues.REMINDER,
  startTime: timeOptions[startTime],
  creator: beforeCacheUserInfo(creator || {}),
});

const getHolidayDetails = ({ startTime, endTime, ...rest }: any) => ({
  ...rest,
  schedulesEventType: schedulesEventValues.HOLIDAY,
  startTime: undefined,
  endTime: undefined,
});

const getBirthdayDetails = ({ startTime, endTime, user, ...rest }: any) => ({
  ...rest,
  schedulesEventType: schedulesEventValues.BIRTHDAY,
  startTime: undefined,
  endTime: undefined,
  user: beforeCacheUserInfo(user || {}),
});

const getTaskDetails = ({
  startTime,
  endTime,
  categoryId,
  categoryName,
  creator,
  collaborators,
  ...rest
}: any) => ({
  ...rest,
  creator: beforeCacheUserInfo(creator || {}),
  collaborators: collaborators?.map((user: any) => ({
    ...beforeCacheUserInfo(user?.userModel || {}),
    permissions: user?.permissions || [],
  })),
  schedulesEventType: schedulesEventValues.TASK,
  startTime: timeOptions[startTime],
  endTime: timeOptions[endTime],
  category:
    categoryId && categoryName
      ? { value: categoryId, label: categoryName }
      : undefined,
});

const getCalendarEvents = (
  data: Array<ISchedulesEvent>
): Array<ISchedulesEvent> =>
  data?.map((event) => ({
    ...event,
    // @ts-ignore
    attendees: event?.attendees?.map((user: any) => ({
      ...beforeCacheUserInfo(user?.userModel || {}),
      permissions: user?.permissions || [],
    })),
    collaborators: event?.collaborators?.map((user: any) => ({
      ...beforeCacheUserInfo(user?.userModel || {}),
      permissions: user?.permissions || [],
    })),
  }));

const getCalendarUpcoming = (
  data: PaginateResponse<ISchedulesEvent>
): PaginateResponse<ISchedulesEvent> => ({
  ...data,
  content: data.content.map((item: any) => {
    if (item.type === schedulesEventValues.MEETING) {
      return getMeetingDetails(item);
    }
    if (item.type === schedulesEventValues.TASK) {
      return getTaskDetails(item);
    }
    if (item.type === schedulesEventValues.HOLIDAY) {
      return getHolidayDetails(item);
    }
    if (item.type === schedulesEventValues.BIRTHDAY) {
      return getBirthdayDetails(item);
    }
    return getReminderDetails(item);
  }),
});

const schedulesNormalizer = {
  getMeetingDetails,
  getCalendarEvents,
  getReminderDetails,
  getTaskDetails,
  getCalendarUpcoming,
  getHolidayDetails,
  getBirthdayDetails,
};

export default schedulesNormalizer;
