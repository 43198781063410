import { db } from '../../constants';

const { LANGUAGE_LEVELS } = db;

export type LanguageNormalizerType = {
  id: string;
  name: string;
  languageLookupId: string | number;
  level: any;
};

const languageNormalizer = (item: LanguageNormalizerType): any => {
  const { id, name, languageLookupId, level } = item;
  const levelIndex = LANGUAGE_LEVELS.findIndex((i) => i.value === level);
  const progress = levelIndex + 1;
  const levelLabel = LANGUAGE_LEVELS?.[levelIndex]?.label;
  const realData = {
    name: { label: name, value: languageLookupId },
    id,
    level: {
      value: level,
      label: levelLabel,
    },
    progress,
  };

  // const invalidLookupId = Number(languageLookupId) > 0;

  return {
    id,
    name,
    level: levelLabel,
    realData,
    progress,
  };
};

export default languageNormalizer;
