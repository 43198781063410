import request from '../../utils/request';
import { networkEndpoints } from '../../constants';
import type { NetworkObjectNormalizerType } from '../../utils/normalizers/networkObjectNormalizer';
import networkObjectNormalizer from '../../utils/normalizers/networkObjectNormalizer';
import type {
  IGetFollowers,
  PaginateResponse,
  IGetFollowings,
  IGetFollowingsResponse,
  IGetFollowRequests,
  IGetFollowRequestsRespons,
  IUserNetworkRelation,
  PaginateParamType,
  PageAndPeopleType,
} from '../../types';

export type NetworkItem = {
  isPage: boolean;
  back: boolean;
  croppedImageUrl: string;
  follow: string;
  followStatus: string;
  followersCounter: string;
  followingsCounter: string;
  id: string;
  imageUrl: string;
  name: string;
  occupationId: string;
  occupationName: string;
  point: string;
  pointMultiplier: string;
  privateProfile: boolean;
  score: string;
  surname: string;
  username: string;
};

export interface IPaginateParams {
  size: number;
  page: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

interface ISearchParams extends IPaginateParams {
  text?: string;
  userId: string;
  isAuthObjectFollowing?: boolean;
}

const search = async <T>({
  url,
  ...params
}: ISearchParams & { url: string }): Promise<PaginateResponse<T>> => {
  if (!url) {
    throw new Error('url is required.');
  }

  const { data } = await request.get<PaginateResponse<T>>(url, { params });
  return data;
};

export const getFollowers = async (
  params: ISearchParams
): Promise<PaginateResponse<IGetFollowers>> => {
  const url = networkEndpoints.common.followers;
  const data = await search<PageAndPeopleType>({ ...params, url });

  return networkObjectNormalizer(data);
};

export const getFollowings = async (
  params: ISearchParams
): Promise<PaginateResponse<IGetFollowings>> => {
  const { isAuthObjectFollowing, ...rest } = params;
  const url = networkEndpoints.common.followings;
  const data = await search<IGetFollowingsResponse>({ ...rest, url });

  // @ts-ignore
  return networkObjectNormalizer(data, isAuthObjectFollowing);
};

export const getPendingRequestsPaginated = async ({
  page = 0,
  size,
}: any): Promise<NetworkObjectNormalizerType> => {
  const { data } = await request.get(networkEndpoints.people.pendingRequests, {
    params: { size, page },
  });
  // @ts-ignore
  return networkObjectNormalizer(data);
};

export const getFollowRequestPaginated = async (
  params: ISearchParams
): Promise<PaginateResponse<IGetFollowRequests>> => {
  const url = networkEndpoints.people.myFollowRequests;
  const data = await search<IGetFollowRequestsRespons>({ ...params, url });

  // @ts-ignore
  return networkObjectNormalizer(data);
};

export const getUserNetworkRelation = async (params: {
  withUserId: string;
}): Promise<IUserNetworkRelation> => {
  const url = networkEndpoints.common.relation;
  const { data } = await request.get(url, {
    params,
  });
  return data;
};

export const getBlockedObject = async (
  params: PaginateParamType & {
    text?: string;
  } = {}
): Promise<NetworkObjectNormalizerType<IGetFollowers>> => {
  const { data } = await request.get<PaginateResponse<PageAndPeopleType>>(
    networkEndpoints.common.blocked,
    {
      params,
    }
  );

  return networkObjectNormalizer(data);
};

export default {
  getUserNetworkRelation,
  getFollowers,
  getFollowings,
  getFollowRequestPaginated,
  getPendingRequestsPaginated,
  getBlockedObject,
};
