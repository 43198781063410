import { useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import type { ListResponse, PaginateResponse, QueryKeyType } from '../types';
import type { UseQueryResult } from '@tanstack/react-query';

type GetAppType<T> = T | ListResponse<T> | PaginateResponse<T> | undefined;

export type UseUpdateQueryDataType<T> = {
  getAll: () => GetAppType<T>;
  get: (id?: string) => T | undefined;
  add: (newItem: T) => void;
  replace: (replaceItem: T & { id?: string }) => void;
  remove: (id: string) => void;
  refetch: () => void;
  replaceAll: (data: QueryCacheDataType<T>) => void;
};

type QueryCacheDataType<T> = UseQueryResult<PaginateResponse<T>>['data'];

const useUpdateQueryData = <T>(
  key: QueryKeyType
): UseUpdateQueryDataType<T> => {
  const queryClient = useQueryClient();

  const _updateCache = (data: any) => {
    queryClient.setQueriesData(key, data);
  };
  const getAll = (): GetAppType<T> =>
    queryClient.getQueryData(key) as PaginateResponse<T>;

  const get = (id?: string): T | undefined => {
    const data = queryClient.getQueryData(key) as PaginateResponse<T>;

    if (!isNil(data) && isPlainObject(data) && data.content?.length) {
      return data.content.find((x: any) => x.id === id);
    }
    if (!isNil(data) && isArray(data)) {
      return data.find((x: any) => x.id === id);
    }

    return undefined;
  };
  const add = (newItem: T) => {
    const data = queryClient.getQueryData(key) as QueryCacheDataType<T>;

    if (!isNil(data) && isPlainObject(data)) {
      const { content: c = [], totalElements: tE, ...rest } = data;

      _updateCache({
        ...rest,
        content: [...c, newItem],
        totalElements: parseInt(tE, 10) + 1,
      });
    }
    if (!isNil(data) && isArray(data)) {
      _updateCache([...data, newItem]);
    }
  };
  const replace = (replaceItem: T & { id?: string }) => {
    const data = queryClient.getQueryData(key) as QueryCacheDataType<T>;

    if (!isNil(data) && isPlainObject(data) && data.content?.length) {
      const { content: c = [], ...rest } = data;

      _updateCache({
        ...rest,
        content: c.map((item: any) =>
          item.id === replaceItem.id ? replaceItem : item
        ),
      });
    }

    if (!isNil(data) && isPlainObject(data) && !data.content) {
      _updateCache({
        ...data,
        ...replaceItem,
      });
    }
    if (!isNil(data) && isArray(data)) {
      _updateCache(
        data.map((item) => (item?.id === replaceItem?.id ? replaceItem : item))
      );
    }
  };
  const remove = (id: string) => {
    const data = queryClient.getQueryData(key) as QueryCacheDataType<T>;

    if (!isNil(data) && isPlainObject(data) && data.content?.length) {
      const { content: c = [], totalElements: tE, ...rest } = data;

      _updateCache({
        ...rest,
        content: c.filter(({ id: ID }: any) => ID !== id),
        totalElements: parseInt(tE, 10) - 1,
      });
    }
    if (!isNil(data) && isArray(data)) {
      _updateCache(data.filter((x: any) => x?.id !== id));
    }
  };
  const refetch = () => {
    queryClient.refetchQueries(key, {
      active: true,
    });
  };
  const replaceAll = (data: QueryCacheDataType<T>) => {
    queryClient.setQueriesData(key, data);
  };

  return {
    getAll,
    get,
    add,
    replace,
    remove,
    refetch,
    replaceAll,
  };
};

export default useUpdateQueryData;
