import { QueryKeys } from '../constants/enums';
import { getAuthUser } from '../api/user';
import { useAuthState } from '../contexts';
import useReactQuery from './useReactQuery';
import type { UseQueryResult } from '@tanstack/react-query';
import type { UserType } from '../types';

export type UseAuthUserReturnType = UseQueryResult<UserType>;

const useAuthUser = (): UseAuthUserReturnType => {
  const isLoggedIn = useAuthState('isLoggedIn');

  return useReactQuery<UserType, any>({
    action: {
      key: [QueryKeys.authUser],
      apiFunc: getAuthUser,
    },
    config: {
      enabled: isLoggedIn,
      // staleTime: 2 * 1000,
      refetchOnMount: false,
    },
  });
};

export default useAuthUser;
