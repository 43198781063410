import type { UserType, UserApiResponse } from '../../types';
import { APP_ENTITIES } from '../../constants/app-entities';

const beforeCacheUserInfo = ({
  hasAnyPublicCollection,
  email,
  username,
  name,
  surname,
  occupationId,
  occupationName,
  birthDate,
  birthDateAccess,
  phone,
  emailAccess,
  phoneAccess,
  imageUrl,
  location,
  personNetworkModel,
  ...rest
}: UserApiResponse): UserType => ({
  ...rest,
  type: APP_ENTITIES.person,
  hasAnyPublicCollection,
  username,
  email: {
    value: email,
    privacy: emailAccess,
  },
  occupation: {
    label: occupationName,
    value: occupationId,
  },
  birthDate: {
    value: birthDate,
    privacy: birthDateAccess,
  },
  phone: {
    value: phone || '',
    privacy: phoneAccess,
  },
  location: {
    ...location,
    label: location?.title,
    value: location?.externalId,
    privacy: location?.access,
  },
  profileSmallFotoName: imageUrl,
  fullName: `${name} ${surname}`,
  usernameAtSign: `@${username}`,
  userLink: `/${username}`,
  network: personNetworkModel,
  surname,
  name,
  imageUrl,
});

export default beforeCacheUserInfo;
