const INVITE_SERVICE = '/invitation-service';
const EMAIL_SERVICE = '/email-service';
const API_VERSION = '/api/v1';

const invitation = {
  send: `${INVITE_SERVICE}${API_VERSION}/sendInvitation`,
  google: `${INVITE_SERVICE}${API_VERSION}/connections/google`,
  followList: `${INVITE_SERVICE}${API_VERSION}/followList`,
  invitationList: `${INVITE_SERVICE}${API_VERSION}/invitationList`,
  unsubscribeAll: `${EMAIL_SERVICE}${API_VERSION}/unsubscribe-all`,
};

export default invitation;
