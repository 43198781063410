import request from '../utils/request';
import { Endpoints } from '../constants';
import type { Recommendation } from '../types';

export const getRecommendationDetail = async ({
  params,
}: {
  params: { id: string };
}): Promise<Recommendation> => {
  const { data } = await request.get(
    Endpoints.App.User.Recommendation.writeRecommend(params.id)
  );

  return data;
};

export const declineRecommendation = (id: string) =>
  request.put(Endpoints.App.User.Recommendation.decline(id), {});

export const deleteRecommendation = (item: Recommendation) =>
  request.delete(Endpoints.App.User.Recommendation.delete(item?.id), {});

export const acceptRecommendation = (id: string) =>
  request.put(Endpoints.App.User.Recommendation.accept(id), {});
