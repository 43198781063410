import appEnvironment from '@lobox/utils/constants/env';

export const cookieKeys = {
  userObjToken: '_utlo',
  businessObjToken: '_btlo',
  businessId: '_lbs',
  deviceId: '_ldi',
  notSignupCompletedData: '_nsc',
  twoFactorLoginData: '_tfd',
  redirectRouteName: '_rrn',
  authUser: '_aur',
  businessPage: '_abp',
  isDark: '_drk',
  locale: '_lcl',
} as const;

// lobox.com
// (dev|qa).lobox.com
// business.lobox.com
// business.(dev|qa).lobox.com

const getCookieKey = (key: keyof typeof cookieKeys): string => {
  const cookieSuffix = appEnvironment.cookieSuffix;
  try {
    const _k = cookieKeys[key];

    if (!_k) {
      throw new Error(
        `to get cookie key: it must be one of <${Object.keys(cookieKeys).join(
          ', '
        )}>`
      );
    }

    if (!cookieSuffix) {
      console.error(`KEY: ${key} - CHECK THE cookieSuffix at env`);
      throw new Error(`KEY: ${key} - CHECK THE cookieSuffix at env`);
    }

    if (['localhost', 'dev', 'qa'].includes(cookieSuffix as any)) {
      return `${_k}${cookieSuffix}`;
    }

    return `${_k}`;
  } catch (err) {
    console.log('error in reading cookie key', err);
    return key;
  }
};

export default getCookieKey;
