export const SKILL_LEVELS = [
  { value: 'BEGINNER', label: 'Beginner' },
  { value: 'INTERMEDIATE', label: 'Intermediate' },
  { value: 'ADVANCED', label: 'Advanced' },
  { value: 'EXPERT', label: 'Expert' },
];

const skillNormalizer = (item: any) => {
  const { id, level, name, skillLookupId, type } = item;
  const levelIndex = SKILL_LEVELS.findIndex((i) => i.value === level);
  const progress = levelIndex + 1;
  const levelLabel = SKILL_LEVELS?.[levelIndex]?.label;
  const realData = {
    name: { label: name, type, value: skillLookupId },
    id,
    level: {
      value: level,
      label: levelLabel,
    },
    progress,
    type,
  };

  return {
    id,
    name,
    level: levelLabel,
    realData,
    progress,
    label: name,
    type,
  };
};

export default skillNormalizer;
