import { useEffect } from 'react';

type CallbackType = (...args: any[]) => void;

const useResizeWindow = (
  callback: CallbackType,
  runFirstTime?: boolean
): void => {
  useEffect(() => {
    if (runFirstTime) {
      callback();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback, runFirstTime]);
};

export default useResizeWindow;
