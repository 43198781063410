type MyKey =
  | 'title'
  | 'name'
  | 'id'
  | 'pageId'
  | 'croppedImageUrl'
  | 'username';

type DataItemType = Record<MyKey, string>;
type DataType = DataItemType[];

const hereApiResponseNormalizer = (response: DataType) =>
  response
    ?.map(({ id, pageId, title, croppedImageUrl, username }) => ({
      value: id || pageId || `${title?.trim().toLowerCase()}_temp`,
      label: title,
      image: croppedImageUrl,
      helperText: username,
    }))
    .slice(0, 6);

export default hereApiResponseNormalizer;
