import React, { useContext, createContext } from 'react';

export default function useCreateContext<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType
): any {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState;
  const stateCtx = createContext(initialState);
  const dispatchCtx = createContext(defaultDispatch);

  function useStateCtx<K extends keyof StateType>(property: K) {
    const state = useContext(stateCtx);
    return state[property]; // only one depth selector for comparison
  }

  function useDispatchCtx() {
    return useContext(dispatchCtx);
  }

  function Provider({
    children,
    initialValue,
  }: React.PropsWithChildren<{ initialValue?: any }>) {
    const [state, dispatch] = React.useReducer<
      React.Reducer<StateType, ActionType>
    >(reducer, initialState);

    return (
      <dispatchCtx.Provider value={dispatch}>
        <stateCtx.Provider value={{ ...state, ...initialValue }}>
          {children}
        </stateCtx.Provider>
      </dispatchCtx.Provider>
    );
  }
  return [useStateCtx, useDispatchCtx, Provider] as const;
}

// example
// app.reduecr
//= =================
// export const initialState = {
//   isSticky: false,
//   isOpenDrawer: false,
// }

// type ActionType =
//   | { type: 'SET_STICKY'; payload: boolean }
//   | { type: 'TOGGLE_DRAWER'; payload: boolean }

// type StateType = typeof initialState

// export function appReducer(state: StateType, action: ActionType): StateType {
//   switch (action.type) {
//     case 'SET_STICKY':
//       return {
//         ...state,
//         isSticky: action.payload,
//       }
//     case 'TOGGLE_DRAWER':
//       return {
//         ...state,
//         isOpenDrawer: action.payload,
//       }
//     default: {
//       throw new Error(`Unsupported action type at App Reducer`)
//     }
//   }
// }

//= ===========
// app.provider
// import { useCreateContext } from '../create-context';
// import { appReducer, initialState } from './app.reducer';

// const [state, dispatch, provider] = useCreateContext(appReducer, initialState);
// export const useAppState = state;
// export const useAppDispatch = dispatch;
// export const AppProvider = provider;
