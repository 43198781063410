import { useLocation } from '@lobox/utils';
import { routeNames } from '../constants';

const useIsPageCreation = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  return pathname === `${routeNames.page}${routeNames.pageCreation}`;
};

export default useIsPageCreation;
