import type { PaginateResponse, PageOrPeopleType } from '../../types';

const followingNormalizer = <T = PageOrPeopleType>(
  data: PaginateResponse<T>
): PaginateResponse<T> => {
  const { content, ...rest } = data;
  return {
    ...rest, // @ts-ignore
    content: content.map(({ follow, ...r }) => ({
      ...r,
      follow: true,
      fullName: `${r?.name} ${r?.surname}`,
    })),
  };
};
export default followingNormalizer;
