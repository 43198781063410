const collectionToObjectByKey = (collection: Array<any>, key = 'value') =>
  collection.reduce(
    (prev: any, curr) => ({
      ...prev,
      [curr[key]]: curr,
    }),
    {}
  );

export default collectionToObjectByKey;
