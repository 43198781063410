const POST_SEARCH_SERVICE = '/post-search-service';
const API_VERSION = '/api/v1';

const postSearchEndpoints = {
  feed: {
    single: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/single-element`,
    taggedMentioned: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/tagged-mentioned`,
    hidePosts: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/my-hide-posts`,
    considerCommentsInBuildingFeed: `${POST_SEARCH_SERVICE}${API_VERSION}/setting/consider-comments-in-building-feed`,
    considerActionsInBuildingFeed: `${POST_SEARCH_SERVICE}${API_VERSION}/setting/consider-actions-in-building-feed`,
  },
  collection: {
    feed: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/collection`,
  },
  setting: `${POST_SEARCH_SERVICE}${API_VERSION}/setting`,
};

export default postSearchEndpoints;
