import Cz from 'js-cookie';
import appEnvironment from '../constants/env';

const set = (key: string, value: string, options: any = {}): any => {
  console.log({ key, value, options, domain: appEnvironment.domain });
  return Cz.set(key, value, { ...options, domain: appEnvironment.domain });
};

const remove = (key: string, options: any = {}): void => {
  Cz.remove(key, { domain: appEnvironment.domain, ...options });
};

const Cookies = {
  ...Cz,
  set,
  remove,
};

export default Cookies;
