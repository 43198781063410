import request from '../utils/request';
import { reportEndpoints } from '../constants/servicesEndpoints';
import type { APP_ENTITIES } from '../constants';
import type { ReportEntityType } from '@lobox/utils';

export const submitReport = async ({
  entityType,
  id,
  reason,
  description,
  title,
}: {
  entityType: ReportEntityType;
  id: string;
  reason: string;
  description: string;
  title: string;
}): Promise<any> => {
  const URL =
    entityType === 'job'
      ? reportEndpoints.job(id)
      : entityType === 'user'
        ? reportEndpoints.profile(id)
        : entityType === 'page'
          ? reportEndpoints.page(id)
          : entityType === 'post'
            ? reportEndpoints.post(id)
            : entityType === 'comment'
              ? reportEndpoints.comment(id)
              : entityType === 'message'
                ? reportEndpoints.message(id)
                : null;

  if (!URL) {
    throw new Error('Entity type should be profile, page, post or comment.');
  }
  const { data } = await request.post(URL, {
    id,
    reason,
    description,
    title,
  });
  return data;
};

type Keys = keyof typeof APP_ENTITIES;
export type EntityType = (typeof APP_ENTITIES)[Keys];

export const block = async ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: EntityType;
}) => {
  const URL = reportEndpoints.block;
  const { data } = await request.post(URL, {
    blockedUserId: entityId,
    blockedUserType: entityType,
  });
  return data;
};
export const unblock = async ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: EntityType;
}): Promise<void> => {
  const URL = reportEndpoints.unblock;
  const { data } = await request.post(URL, {
    blockedUserId: entityId,
    blockedUserType: entityType,
  });
  return data;
};
export const feedback = async ({
  body,
  title,
}: {
  body: string;
  title: string;
}) => {
  const URL = reportEndpoints.feedback;
  const { data } = await request.post(URL, {
    body,
    title,
  });
  return data;
};

export default {
  submitReport,
  block,
  unblock,
  feedback,
};
