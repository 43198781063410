import React from 'react';

const useDoubleClick = (callback: () => void) => {
  const [elem, setElem] = React.useState(null);
  const countRef = React.useRef(0);
  const timerRef = React.useRef<any>(0);
  const inputCallbackRef = React.useRef<any>(null);
  const callbackRef = React.useCallback<any>(
    (node: React.SetStateAction<null>) => {
      setElem(node);
      callbackRef.current = node;
    },
    []
  );

  React.useEffect(() => {
    inputCallbackRef.current = callback;
  });

  React.useEffect(() => {
    function handler() {
      const isDoubleClick = countRef.current + 1 === 2;
      const timerIsPresent = timerRef.current;
      if (timerIsPresent && isDoubleClick) {
        clearTimeout(timerRef?.current);
        timerRef.current = null;
        countRef.current = 0;
        if (inputCallbackRef.current) {
          inputCallbackRef?.current();
        }
      }
      if (!timerIsPresent) {
        countRef.current += 1;
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          countRef.current = 0;
        }, 200);
        timerRef.current = timer;
      }
    }
    if (elem) {
      // @ts-ignore
      elem.addEventListener('click', handler);
    }

    return () => {
      if (elem) {
        // @ts-ignore
        elem.removeEventListener('click', handler);
      }
    };
  }, [elem]);
  return { callbackRef, elem };
};

export default useDoubleClick;
