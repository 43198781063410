export const isInViewport = (elem: HTMLElement): boolean => {
  const {
    top = 0,
    bottom = 0,
    left = 0,
    right = 0,
    height = 0,
  } = elem?.getBoundingClientRect() || {};

  const heightThreshold = height * (2 / 3);
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  return (
    top >= -heightThreshold &&
    bottom <= windowHeight + heightThreshold &&
    left >= 0 &&
    right <= windowWidth
  );
};

export default isInViewport;
