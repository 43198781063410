import get from 'lodash/get';
import { useQueryClient } from '@tanstack/react-query';

const useQuerySelector = (
  path: Array<string | Array<string>>,
  defaultValue?: any
) => {
  const queryClient = useQueryClient();
  const [queryKey, ...rest] = path;
  if (!queryKey) {
    console.warn('queryKey is Required');
  }
  const key = typeof queryKey === 'string' ? [queryKey] : queryKey;
  const data = queryClient.getQueryData(key);
  return data && rest?.length > 0
    ? get(data, rest as string[], defaultValue)
    : data;
};

export default useQuerySelector;
