import { useEffect } from 'react';
import { useLocation } from '@lobox/utils';

const useScrollTop = (
  el?: HTMLElement,
  behavior?: ScrollBehavior,
  scrollWindow?: boolean
): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (el && scrollWindow) {
      window.scrollTo({ top: el.offsetTop, behavior: behavior || 'smooth' });
    } else {
      el?.scrollTo({ top: 0, left: 0, behavior: behavior || 'smooth' });
    }
  }, [el, behavior, pathname, scrollWindow]);
};

export default useScrollTop;
