import { useQueryClient } from '@tanstack/react-query';
import { Cookies, getCookieKey } from '../utils';
import { Endpoints, landingRouteNames, useReactMutation } from '@lobox/utils';
import onSuccessLogout from '../utils/onSuccessLogout';
import { useRouter } from 'next/navigation';

const useLogoutMutation = () => {
  const queryClient = useQueryClient();
  const DEVICE_ID = getCookieKey('deviceId');
  const deviceId = Cookies.get(DEVICE_ID);
  const router = useRouter();
  router.prefetch(`${landingRouteNames.login}?prefetch=true`);

  const logoutHandler = async () => {
    onSuccessLogout();
    queryClient.clear();
  };

  const { mutate } = useReactMutation({
    url: Endpoints.Auth.logout,
    method: 'DELETE',
    onSettled: logoutHandler,
  });

  return () => mutate({ deviceId });
};

export default useLogoutMutation;
