const Endpoints = {
  Auth: {
    postLogin: '/auth-service/api/v1/login',
    postForgetPassword: '/auth-service/api/v1/forgot-password',
    postForgetPasswordConfirm: '/auth-service/api/v1/reset-password',
    postEmail: '/auth-service/api/v1/signup',
    postDetail: 'auth-service/api/v1/signup-completion',
    postResendEmail: '/auth-service/api/v1/resend-email-verification',
    checkToken: '/auth-service/api/v1/verify-email',
    logout: 'auth-service/api/v1/logout',
    getAuthUserInfo: 'auth-service/api/v1/users/me',
    birthDate: '/auth-service/api/v1/users/birth-date',
    phone: '/auth-service/api/v1/users/phone',
    setLocation: '/auth-service/api/v1/users/location',
    setUserAvatar: '/auth-service/api/v1/users/image',
    validateUsername: 'auth-service/api/v1/validate-username',
  },
  App: {
    Common: {
      employmentTypesApi: '/lookup/v1/api/contract-types',
      getCountries: 'geo-service/api/v1/country/search',
      getCities: '/lookup/v1/api/location/search-cities',
      getOccupations: '/lookup-service/api/v1/occupation/search',
      getMajors: '/lookup-service/api/v1/major/search',
      upload: '/storage-service/api/v1/upload',
      suggestPlace: '/user-search-service/api/v1/suggest-page',
      getSkills: '/lookup-service/api/v1/skill/search',
      getLanguages: '/lookup-service/api/v1/language/search',
      getIndustry: '/lookup-service/api/v1/industry/search',
      getGeocode: '/lookup-service/api/v1/geocode',
      discoverPlace: '/lookup-service/api/v1/discover-place',
      searchCurrency: '/lookup-service/api/v1/currency/search',
      searchInstrument: '/lookup-service/api/v1/instrument/search',
      searchHobby: '/lookup-service/api/v1/hobby/search',
      searchSport: '/lookup-service/api/v1/sport/search',
      searchCertification: '/lookup-service/api/v1/certification/search',
      searchAward: '/lookup-service/api/v1/award/search',
    },
    User: {
      Profile: {
        get: 'profile-service/api/v1/profile',
        getUserProfileByUserPublicID: `auth-service/api/v1/user-limited-information`,
        getAllProfiles: '/profile-service/api/v1/profiles',
      },
      Update: {
        webSites: '/profile-service/api/v1/links',
        cover: '/profile-service/api/v1/header-image',
        biography: '/profile-service/api/v1/bio',
      },
      Experiences: {
        get: '/profile-service/api/v1/experience',
      },
      Education: {
        get: '/profile-service/api/v1/education',
      },
      Course: {
        get: '/profile-service/api/v1/course',
      },
      Licence: {
        get: '/profile-service/api/v1/certification',
      },
      Publication: {
        get: '/profile-service/api/v1/publication',
      },
      Honor: {
        get: '/profile-service/api/v1/award',
      },
      Patent: {
        get: '/profile-service/api/v1/patent',
      },
      Language: {
        get: '/profile-service/api/v1/language',
      },
      Skill: {
        get: '/profile-service/api/v1/skill',
        one: (id: number): string => `/profile-service/api/v1/skill/${id}`,
      },
      Recommendation: {
        ask: '/profile-service/api/v1/recommendation/ask',
        revision: (id: string): string =>
          `profile-service/api/v1/recommendation/${id}/ask-revision`,
        decline: (id: string): string =>
          `profile-service/api/v1/recommendation/${id}/decline`,
        delete: (id: string): string =>
          `profile-service/api/v1/recommendation/${id}`,
        accept: (id: string): string =>
          `profile-service/api/v1/recommendation/${id}/accept`,
        recommend: '/profile-service/api/v1/recommendation',
        writeRecommend: (id: string): string =>
          `profile-service/api/v1/recommendation/${id}`,
      },
      Resume: {
        get: 'profile-service/api/v1/resume',
      },
    },
    Page: {
      base: 'page-service/api/v1/page',
      get: 'page-service/api/v1/page',
      validatePageUsername: 'page-service/api/v1/validate-page-username',
      myPages: 'page-service/api/v1/page-member/me',
      pageMember: 'page-service/api/v1/page-member',
      getMembers: 'page-service/api/v1/page-members',
      setAvatar: (id: string): string => `page-service/api/v1/page/${id}/image`,
      setHeaderBackground: (id: string): string =>
        `page-service/api/v1/page/${id}/header-image`,
      publish: (id: string): string => `page-service/api/v1/page/${id}/publish`,
    },
    Post: {
      create: '/post-service/api/v1/post',
      feedList: '/post-search-service/api/v1/feed',
      feedListByHashtag: '/post-search-service/api/v1/posts/by-hashtag',
    },
    profile: {
      addExperience: '/profile-service/api/v1/experience',
    },
    search: {
      suggestObject: '/user-search-service/api/v1/suggest-user',
    },
  },
};

export default Endpoints;
