const REPORT_SERVICE = '/report-service';
const API_VERSION = '/api/v1';

const base = `${REPORT_SERVICE}${API_VERSION}`;

const Report = {
  comment: (commentId: string): string => `${base}/comment/${commentId}`,
  page: (pageId: string): string => `${base}/page/${pageId}`,
  post: (postId: string): string => `${base}/post/${postId}`,
  profile: (profileId: string): string => `${base}/profile/${profileId}`,
  message: (chatRoomId: string): string => `${base}/chat/${chatRoomId}`,
  job: (jobId: string): string => `${base}/job/${jobId}`,
  block: `${base}/block`,
  unblock: `${base}/unblock`,
  feedback: `${base}/feedback`,
};

export default Report;
