const QueryKeys = {
  authUser: 'authUser',
  aboutSections: ['aboutSections'],
  isLoggedIn: ['isLoggedIn'],
  allUsers: ['allUsers'],
  isMyProfile: ['isMyProfile'],
  objectDetail: 'objectDetail',
  myPages: ['myPages'],
  myPagesNotifications: ['myPagesNotifications'],
  myPagesMessageNotifications: ['myPagesMessageNotifications'],
  businessPage: 'businessPage',
  homeFeedList: 'homeFeedList',
  discoverPlace: ['discoverPlace'],
  userExperiences: ['experiences'],
  collections: ['collections'],
  collection: ['collection'],
  collectionFeed: ['collectionFeed'],
  hashtags: ['hashtags'],
  postComments: ['postComments'],
  commentReplies: ['commentReplies'],
  userHashtags: ['userHashtags'],
  popularHashtags: 'popularHashtags',
  getViewCount: ['getViewCount'],
  getPostInteractionsCount: ['getPostInteractionsCount'],
  getFollowDiagram: ['getFollowDiagram'],
  getViewDiagram: ['getViewDiagram'],
  getTopPosts: ['getTopPosts'],
  getOpenForJobOpportunities: ['getOpenForJobOpportunities'],
  getSuggestionPages: ['getSuggestionPages'],
  getMyFollowingPages: ['getMyFollowingPages'],
  getSuggestionPeople: ['getSuggestionPeople'],
  getMyFollowRequests: ['getMyFollowRequests'],
  getMyFollowPeopleRequest: ['getMyFollowPeopleRequest'],
  getMyFollowPageRequest: ['getMyFollowPageRequest'],
  getMyFollowingPeople: ['getMyFollowingPeople'],
  getMyFollowerPeople: ['getMyFollowerPeople'],
  getMyFollowerPages: ['getMyFollowerPages'],
  getPendingRequests: ['getPendingRequests'],
  getPopularPageCategories: ['getPopularPageCategories'],
  getFilteredPages: ['getFilteredPages'],
  getCategory: ['getCategory'],
  getFeedDetail: ['getFeedDetail'],
  readyToPostFeed: ['readyToPostFeed'],
  followers: ['followers'],
  followings: ['followings'],
  followRequests: ['followRequests'],
  postActionsStatus: ['postActionsStatus'],
  pageMembers: ['pageMembers'],
  userAllPosts: ['userAllPosts'],
  userPosts: ['userPosts'],
  userHighlights: ['userHighlights'],
  feedSidebarHighlights: ['feedSidebarHighlights'],
  postReactions: ['postOrCommentReactions'],
  suggestObjects: ['suggestObjects'],
  suggestHashtags: ['suggestHashtags'],
  searchUsers: ['searchUsers'],
  searchPages: ['searchPages'],
  searchHashtags: ['searchHashtags'],
  searchPosts: ['searchPosts'],
  userNetworkRelation: ['UserNetworkRelation'],
  upcomingBirthDays: ['upcomingBirthDays'],
  popularPages: ['popularPages'],
  homeFeedListByHashtag: ['homeFeedListByHashtag'],
  getCountryById: ['getCountryById'],
  getCityById: ['getCityById'],
  getCompanyById: ['getCompanyById'],
  getSchoolById: ['getSchoolById'],
  getOccupationById: ['getOccupationById'],
  getIndustryById: ['getIndustryById'],
  getInvitationList: ['getInvitationList'],
  getContactFollowList: ['getContactFollowList'],
  notifications: ['notifications:list'],
  unSeenNotifications: ['notifications:unSeen'],
  unSeenNotificationCounts: ['notifications:unSeenCount'],
  getRoomsList: ['getRoomsList'],
  getChatRoomsList: 'getChatRoomsList',
  getAllDevices: 'getAllDevices',
  getUserEmails: ['getUserEmails'],
  getBlockedObjects: ['getBlockedObjects'],
  getFeedOfTaggedMentioned: ['getFeedOfTaggedMentioned'],
  getFeedOfHiddenPosts: ['getFeedOfHiddenPosts'],
  getSetting: ['getSetting'],
  getNotificationSetting: ['getNotificationSetting'],
  hashtag: ['hashtag'],
  postMenuItemsStatus: ['post:menuItemsStatus'],
  getRoomMessages: ['getRoomMessages'],
  searchRoomMessages: ['searchRoomMessages'],
  staredMessages: ['staredMessages'],
  archivedMessages: ['archivedMessages'],
  getMyOwnPages: ['getMyOwnPages'],
  getRoomDetail: ['getRoomDetail'],
  recommendationDetail: ['recommendationDetail'],
  getRoomMemberInfo: ['getRoomMemberInfo'],
  openJobs: ['openJobs'],
  jobDetails: 'jobDetails',
  checkHasJobs: ['checkHasJobs'],
  appliedJobs: ['appliedJobs'],
  savedJobs: ['savedJobs'],
  openSavedJobs: ['openSavedJobs'],
  activeAppliedJobs: ['activeAppliedJobs'],
  appliedSavedJobs: ['appliedSavedJobs'],
  closedSavedJobs: ['closedSavedJobs'],
  closedAppliedJobs: ['closedAppliedJobs'],
  topSuggestionJobs: ['topSuggestionJobs'],
  jobPopularCategories: 'jobPopularCategories',
  subCategoryJobList: ['subCategoryJobList'],
  searchJobs: 'searchJobs',
  getResume: ['getResume'],
  popularJobs: ['PopularJobs'],
  recentSearchJobs: ['recentSearchJobs'],
  getJobOwnersLis: ['getJobOwnersLis'],
  jobCreatorDetail: ['jobCreatorDetail'],
  userSavedJobsAvailable: ['userSavedJobsAvailable'],
  getAllJobs: ['getAllJobs'],
  pageMutual: ['pageMutual'],
  peopleMutual: ['peopleMutual'],
  getSearchFilters: ['getSearchFilters'],
  getWorkingAtCompany: ['getWorkingAtCompany'],
  getCurrentTimeZone: ['getCurrentTimeZone'],
  schedulesEvents: ['schedulesEvents'],
  getUpcomingMeetings: ['getUpcomingMeetings'],
  getPastMeetings: ['getPastMeetings'],
  getAllSearchJobsSuggestions: ['getAllSearchJobsSuggestions'],
  unsavedJobsDuringThisSession: ['unsavedJobsDuringThisSession'],
  twoFactorAuthData: ['twoFactorAuthData'],
  getBackupCode: ['getBackupCode'],
  currentEntityId: 'currentEntityId',
  searchTabOrders: ['searchTabOrders'],
  schedulesPreferences: ['schedulesPreferences'],
  getBookedMeetings: ['getBookedMeetings'],
  getPopularPeople: ['getPopularPeople'],
  getPopularPeopleCategories: 'getPopularPeopleCategories',
  redirectUrl: ['redirectUrl'],
  getAllProviders: ['getAllProviders'],
  getAllProvidersKeys: ['getAllProvidersKeys'],
  getCandidatesList: ['getCandidatesList'],
  textEditorFocusButton: ['textEditorFocusButton'],
  getDeviceId: 'getDeviceId',
  getChatStatus: 'getChatStatus',
};

export default QueryKeys;
