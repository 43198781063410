import React from 'react';

const useViewportTop = (element: HTMLElement): void => {
  const viewportHandler = React.useCallback(() => {
    if (element?.style) {
      element.style.transform = `translateY(${Math.max(0, window?.visualViewport?.offsetTop || 0)}px)`;
    }
  }, [element]);

  React.useEffect(() => {
    viewportHandler();

    window?.visualViewport?.addEventListener('resize', viewportHandler);

    return () => {
      window?.visualViewport?.removeEventListener('resize', viewportHandler);
    }
  }, [viewportHandler]);
};

export default useViewportTop;
