import isClient from './isClient';

type ParamsType = { [key: string]: string | number };

const _createUrl = (url: string, params?: ParamsType): URL => {
  try {
    const _url = new URL(url);

    if (params) {
      const keyList = Object.keys(params);
      for (let i = 0; i < keyList.length; i += 1) {
        const key = keyList[i];
        if (params[key]) {
          _url.searchParams.set(keyList[i], params[key].toString());
        }
      }
    }

    return _url;
  } catch (e) {
    throw new Error('The URL is not valid');
  }
};

export const redirectUrl = (url: string, params?: ParamsType): void => {
  console.log('redirectUrl', url);
  if (isClient()) {
    const _url = _createUrl(url, params);

    window.location.assign(_url.href);
  }
};

export const replaceUrl = (url: string, params?: ParamsType): void => {
  console.log('replaceUrl', url);

  if (isClient()) {
    const _url = _createUrl(url, params);
    console.log('redirectUrl', _url);

    window.location.replace(_url.href);
  }
};
