import { useEffect } from 'react';

const useOutsideListener = (
  el: HTMLElement,
  callback: Function,
  exceptionIdsList: Array<string> = []
): void => {
  const handleClickOutside = (event: any) => {
    if (
      el &&
      !el.contains?.(event.target) &&
      !exceptionIdsList.some((id) =>
        document.getElementById(id)?.contains(event.target)
      )
    ) {
      callback(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [el, callback]); // eslint-disable-line
};

export default useOutsideListener;
