import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useCallbackRef from './useCallbackRef';
import type { RefObject } from 'react';

type UseMeasureType = {
  height: number;
  width: number;
};

const useMeasure = (ref: RefObject<any>): UseMeasureType => {
  const [element, attachRef] = useCallbackRef();
  const [bounds, setBounds] = useState<UseMeasureType>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const onResize = ([entry]: any) => {
      setBounds({
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      });
    };

    const observer = new ResizeObserver(onResize);

    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [element]);

  useEffect(() => {
    attachRef(ref);
  }, [attachRef, ref]);

  return bounds;
};

export default useMeasure;
