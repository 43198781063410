import React from 'react';
import { I18nextProvider } from 'react-i18next';

export interface I18nProviderProps {
  children: React.ReactNode;
  i18n: any;
}

const I18nProvider: React.FC<I18nProviderProps> = ({ children, i18n }) => (
  <I18nextProvider i18n={i18n as any}>{children}</I18nextProvider>
);

export default I18nProvider;
