const imageUrlToBlobAndBase64 = (url: string): Promise<any> =>
  new Promise((resolve) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64 = reader.result;
          const blobData = new Blob([blob], { type: 'image/png' });
          resolve({ base64, blob: blobData });
        };
      });
  });

export default imageUrlToBlobAndBase64;
