import request from '../utils/request';
import { schedulesEndpoints } from '../constants';
import type {
  ICreateMeetingData,
  ICreateReminderData,
  ICreateTaskData,
  ISchedulesEvent,
  SchedulesEventTypes,
} from '../types/Schedules';
import schedulesNormalizer from '../utils/normalizers/schedules';
import { schedulesEventValues } from '../constants/enums';
import type { PaginateResponse } from '../types';

export const getCalendarEvents = async ({ params }: any): Promise<any> => {
  const { data } = await request.get<Array<ISchedulesEvent>>(
    schedulesEndpoints.getCalendarEvents,
    {
      params,
    }
  );
  return schedulesNormalizer.getCalendarEvents(data as any);
};

export const getCalendarUpcoming = async (
  params: any
): Promise<PaginateResponse<ISchedulesEvent>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    schedulesEndpoints.getCalendarUpcoming,
    {
      params,
    }
  );
  return schedulesNormalizer.getCalendarUpcoming(data);
};
export const getCalendarPast = async (
  params: any
): Promise<PaginateResponse<ISchedulesEvent>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    schedulesEndpoints.getCalendarPast,
    {
      params,
    }
  );
  return schedulesNormalizer.getCalendarUpcoming(data);
};

export const getMeetingDetails = async ({ params }: any) => {
  if (!params.id) {
    throw new Error('To get meet details id is required.');
  }
  const { data } = await request.get(
    schedulesEndpoints.getMeetingDetails(params.id)
  );
  return schedulesNormalizer.getMeetingDetails(data);
};

export const getReminderDetails = async ({ params }: any) => {
  if (!params.id) {
    throw new Error('To get reminder details id is required.');
  }
  const { data } = await request.get(
    schedulesEndpoints.getReminderDetails(params.id)
  );
  return schedulesNormalizer.getReminderDetails(data);
};

export const getTaskDetails = async ({ params }: any) => {
  if (!params.id) {
    throw new Error('To get task details id is required.');
  }
  const { data } = await request.get(
    schedulesEndpoints.getTaskDetails(params.id)
  );
  return schedulesNormalizer.getTaskDetails(data);
};

export const deleteEvent = async (params: {
  schedulesEventType: SchedulesEventTypes;
  eventId: string;
}) => {
  const { schedulesEventType, eventId } = params || {};
  if (!eventId) {
    throw new Error('To delete meeting id is required.');
  }
  const urls = {
    [schedulesEventValues.MEETING]: schedulesEndpoints.getMeetingDetails,
    [schedulesEventValues.REMINDER]: schedulesEndpoints.getReminderDetails,
    [schedulesEventValues.TASK]: schedulesEndpoints.getTaskDetails,
    [schedulesEventValues.BIRTHDAY]: schedulesEndpoints.getBirthdayDetails,
  } as any;

  const url = urls[schedulesEventType]?.(eventId);
  const { data } = await request.delete(url);
  return data;
};

export const createMeeting = async (
  meetingData: ICreateMeetingData
): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.createMeeting,
    meetingData
  );
  return data;
};

export const createReminder = async (
  reminderData: ICreateMeetingData
): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.createReminder,
    reminderData
  );
  return data;
};

export const createTask = async (
  reminderData: ICreateTaskData
): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.createTask,
    reminderData
  );
  return data;
};

export const updateReminder = async (
  reminderData: ICreateReminderData
): Promise<any> => {
  const { data } = await request.put(
    schedulesEndpoints.createReminder,
    reminderData
  );
  return data;
};

export const updateMeeting = async (
  meetingData: ICreateMeetingData
): Promise<any> => {
  const { data } = await request.put(
    schedulesEndpoints.createMeeting,
    meetingData
  );
  return data;
};

export const updateTask = async (
  meetingData: ICreateTaskData
): Promise<any> => {
  const { data } = await request.put(
    schedulesEndpoints.createTask,
    meetingData
  );
  return data;
};

export const getHolidayDetails = async ({ params }: any) => {
  if (!params.id) {
    throw new Error('To get reminder details id is required.');
  }
  const { data } = await request.get(
    schedulesEndpoints.getHolidayDetails(params.id)
  );
  return schedulesNormalizer.getHolidayDetails(data);
};

export const getBirthdayDetails = async ({ params }: any) => {
  if (!params.id) {
    throw new Error('To get reminder details id is required.');
  }
  const { data } = await request.get(
    schedulesEndpoints.getBirthdayDetails(params.id)
  );
  return schedulesNormalizer.getBirthdayDetails(data);
};

export const getPreferences = async (params: any): Promise<any> => {
  const { data } = await request.get<any>(schedulesEndpoints.getPreferences, {
    params,
  });
  return data;
};

export const postTimeSheet = async (payload: any): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.postTimeSheet,
    payload
  );
  return data;
};
export const putTimeSheet = async (payload: any): Promise<any> => {
  const { data } = await request.put(schedulesEndpoints.postTimeSheet, payload);
  return data;
};

export const deleteTimesheet = async ({ id }: { id: string }): Promise<any> => {
  const { data } = await request.delete(schedulesEndpoints.deleteTimesheet(id));
  return data;
};

export const getUserTimesheet = async ({
  userId,
}: {
  userId: string;
}): Promise<any> => {
  const { data } = await request.get(
    schedulesEndpoints.getUserTimesheet(userId)
  );
  return data;
};

export const getBookedMeetings = async ({
  params,
}: {
  params: { timesheetId: string };
}): Promise<any> => {
  const { data } = await request.get(schedulesEndpoints.getBookedMeetings, {
    params,
  });
  return data;
};

export const getAllProviders = async ({ params }: any): Promise<any> => {
  const { data } = await request.get(schedulesEndpoints.calendarProvider, {
    params,
  });
  return data;
};

export const getAllProviderUrls = async ({ params }: any): Promise<any> => {
  const { data } = await request.get(schedulesEndpoints.calendarProviderUrls, {
    params,
  });
  return data;
};

export const addProvider = async (args: {
  externalUserName: string;
  authorizationCode: string;
  type: string;
}): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.calendarProvider,
    args
  );
  return data;
};

export const connectProvider = async (args: { id: string }): Promise<any> => {
  const { data } = await request.post(schedulesEndpoints.connectProvider, args);
  return data;
};

export const disconnectProvider = async (args: {
  id: string;
}): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.disconnectProvider,
    args
  );
  return data;
};

export const removeCalendarProvider = async ({
  id,
}: {
  id: string;
}): Promise<any> => {
  const { data } = await request.delete(
    schedulesEndpoints.removeCalendarProvider(id)
  );
  return data;
};

export const celebrateHoliday = async (args: {
  id: string;
  message: string;
  userIds: Array<string>;
}): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.celebrateholiday,
    args
  );
  return data;
};

export const celebrateBirthday = async (args: {
  id: string;
  message: string;
}): Promise<any> => {
  const { data } = await request.post(
    schedulesEndpoints.celebrateBirthday,
    args
  );
  return data;
};

export const uploadFile = async (args: { file: any }): Promise<any> => {
  const file = args?.file;
  const formData = new FormData();
  const extension = file.type.split('/')[1];
  const fileName = file.name ? file.name : `filename.${extension}`;
  formData.append('file', file, fileName);
  formData.append('multiSizeImage', 'false');

  const { data } = await request.post(schedulesEndpoints.uploadFile, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 0,
  });
  return data;
};

export const getFile = async (
  { id }: { id: string },
  config: any
): Promise<any> => {
  const { data } = await request.get(schedulesEndpoints.getFile(id), config);
  return data;
};

export const deleteFile = async ({ id }: { id: string }): Promise<any> => {
  const { data } = await request.delete(schedulesEndpoints.deleteFile(id));
  return data;
};

export default {
  uploadFile,
  getFile,
  deleteFile,
  celebrateBirthday,
  celebrateHoliday,
  removeCalendarProvider,
  getAllProviderUrls,
  getAllProviders,
  addProvider,
  connectProvider,
  disconnectProvider,
  createMeeting,
  createReminder,
  updateMeeting,
  updateReminder,
  createTask,
  updateTask,
  getCalendarEvents,
  getMeetingDetails,
  getReminderDetails,
  getTaskDetails,
  deleteEvent,
  getCalendarUpcoming,
  getCalendarPast,
  getBirthdayDetails,
  getHolidayDetails,
  getPreferences,
  postTimeSheet,
  putTimeSheet,
  deleteTimesheet,
  getUserTimesheet,
  getBookedMeetings,
};
