const NotificationVariants = {
  YOUR_POST_GOT_REACTION: 'YOUR_POST_GOT_REACTION',
  YOUR_POST_GOT_COMMENT: 'YOUR_POST_GOT_COMMENT',
  YOUR_COMMENT_GOT_REACTION: 'YOUR_COMMENT_GOT_REACTION',
  YOUR_COMMENT_GOT_REPLY: 'YOUR_COMMENT_GOT_REPLY',
  YOUR_POST_GET_SHARED: 'YOUR_POST_GET_SHARED',
  YOU_TAGGED_ON_POST: 'YOU_TAGGED_ON_POST',
  YOU_MENTIONED_ON_POST: 'YOU_MENTIONED_ON_POST',
  ADD_PROFILE_PHOTO: 'ADD_PROFILE_PHOTO',
  ADD_HEADER_IMAGE: 'ADD_HEADER_IMAGE',
  ADD_PROFILE_INFORMATION: 'ADD_PROFILE_INFORMATION',
  COMPLETE_YOUR_PROFILE: 'COMPLETE_YOUR_PROFILE',
  TURN_ON_OPEN_FOR_OPPORTUNITIES: 'TURN_ON_OPEN_FOR_OPPORTUNITIES',
  RECOMMENDATION_ASKED: 'RECOMMENDATION_ASKED',
  RECOMMENDATION_WRITTEN: 'RECOMMENDATION_WRITTEN',
  RECOMMENDATION_REVISION_REQUESTED: 'RECOMMENDATION_REVISION_REQUESTED',
  NEW_BIRTHDAY: 'NEW_BIRTHDAY',
  PERSON_YOU_MAY_KNOW: 'PERSON_YOU_MAY_KNOW',
  PAGE_YOU_MAY_KNOW: 'PAGE_YOU_MAY_KNOW',
  NEW_PERSON_FROM_YOUR_SCHOOL: 'NEW_PERSON_FROM_YOUR_SCHOOL',
  NEW_PERSON_FROM_YOUR_COMPANY: 'NEW_PERSON_FROM_YOUR_COMPANY',
  NEW_PAGE_ROLE_ASSIGNED: 'NEW_PAGE_ROLE_ASSIGNED',
  NEW_FOLLOWER: 'NEW_FOLLOWER',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  NEW_DEVICE_LOGGED_IN: 'NEW_DEVICE_LOGGED_IN',
  NEW_FOLLOW_REQUEST: 'NEW_FOLLOW_REQUEST',
  PUBLISHED_A_NEW_PAGE: 'PUBLISHED_A_NEW_PAGE',
  PAGE_ROLE_ACCEPTED: 'PAGE_ROLE_ACCEPTED',
  PAGE_ROLE_DECLINED: 'PAGE_ROLE_DECLINED',
  YOUR_FOLLOW_REQUEST_ACCEPTED: 'YOUR_FOLLOW_REQUEST_ACCEPTED',
  YOU_MENTIONED_ON_COMMENT: 'YOU_MENTIONED_ON_COMMENT',
  REMINDER_ALARMED: 'REMINDER_ALARMED',
  SAVED_BUT_NOT_APPLIED_JOB_FOUNDED: 'SAVED_BUT_NOT_APPLIED_JOB_FOUNDED',
  JOB_APPLICANT_COUNT_CHANGED: 'JOB_APPLICANT_COUNT_CHANGED',
  JOB_CANDIDATE_RECOMMENDATION_UPDATED: 'JOB_CANDIDATE_RECOMMENDATION_UPDATED',
  TOP_JOB_SUGGESTION_FOUNDED: 'TOP_JOB_SUGGESTION_FOUNDED',
  TASK_UPDATED: 'TASK_UPDATED',
  TASK_REMOVED: 'TASK_REMOVED',
  ATTENDEE_DECLINED: 'ATTENDEE_DECLINED',
  ATTENDEE_ACCEPTED: 'ATTENDEE_ACCEPTED',
  MEETING_REMOVED: 'MEETING_REMOVED',
  MEETING_UPDATED: 'MEETING_UPDATED',
  MEETING_CREATED: 'MEETING_CREATED',
  MEETING_ALARMED: 'MEETING_ALARMED',
  JOB_CLOSED: 'JOB_CLOSED',
  JOB_ALERT_MATCHED: 'JOB_ALERT_MATCHED',
} as const;

export const ToggleNotificationList = [
  'YOUR_POST_GOT_REACTION',
  'YOUR_POST_GOT_COMMENT',
  'YOUR_COMMENT_GOT_REACTION',
  'YOUR_COMMENT_GOT_REPLY',
  'YOUR_POST_GET_SHARED',
  'YOU_TAGGED_ON_POST',
  'YOU_MENTIONED_ON_POST',
  'NEW_BIRTHDAY',
  'PERSON_YOU_MAY_KNOW',
  'PAGE_YOU_MAY_KNOW',
  'NEW_PERSON_FROM_YOUR_SCHOOL',
  'NEW_PERSON_FROM_YOUR_COMPANY',
  'NEW_FOLLOWER',
  'NEW_FOLLOW_REQUEST',
  'YOUR_FOLLOW_REQUEST_ACCEPTED',
  'NEW_PAGE_ROLE_ASSIGNED',
  'YOU_MENTIONED_ON_COMMENT',
];

export default NotificationVariants;
