import getCookieKey from './getCookieKey';
import removeEmptyFromObject from './removeEmptyFromObject';
import Cookies from './cookies';
import isServer from './isServer';
import env from '../../constants/env';
import { apiUrlMaker } from './urlMaker';
import authEndpoints from '@lobox/utils/constants/servicesEndpoints/services/auth';
import onSuccessLogout from './onSuccessLogout';
import getBrowserLanguage from '@lobox/utils/utils/getBrowserLanguage';

const isBusinessApp = env.app === 'business';

let isPending = false;

type OptionType = {
  params?: object;
  headers?: object;
  isUserSideApi?: boolean;
  accessToken?: string;
  isWholePath?: boolean;
};

const _call = async <T = any>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  data?: any,
  options?: OptionType
): Promise<{ data: T }> => {
  if (isServer()) {
    const headers = {
      // 'Accept-Language': getBrowserLanguage(),
      'Content-Type': 'application/json',
      ...(options?.accessToken && {
        Authorization: `Bearer ${options.accessToken}`,
      }),

      ...options?.headers,
    };

    const urlSearchParams = new URLSearchParams(
      removeEmptyFromObject(options?.params || {})
    );

    const URL = apiUrlMaker(
      url,
      urlSearchParams.toString(),
      options?.isWholePath
    );

    let response = await fetch(URL, {
      method,
      headers: {
        ...headers,
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
    });
    const responseText = await response.text();
    const result = responseText === '' ? {} : JSON.parse(responseText);

    if (!response.ok) {
      const err = new Error('Error:');
      throw { ...err, ...result, data: undefined };
    }
    return { data: result };
  }

  const hostname = window.location?.hostname;

  let tokenObj;
  let deviceId: any;
  const userObjKey = getCookieKey('userObjToken', hostname);
  const businessObjKey = getCookieKey('businessObjToken', hostname);
  const objCookieKey =
    isBusinessApp && !options?.isUserSideApi ? businessObjKey : userObjKey;
  const deviceIdKey = getCookieKey('deviceId', hostname);

  tokenObj = Cookies.get(objCookieKey);
  tokenObj = JSON.parse(tokenObj || '{}');
  deviceId = Cookies.get(deviceIdKey);
  const isUploadFile =
    options?.headers?.['Content-Type'] === 'multipart/form-data';

  const accessToken = options?.accessToken || tokenObj?.accessToken;
  const headers = {
    ...(isUploadFile
      ? {}
      : {
          'Accept-Language': getBrowserLanguage(),
          'Content-Type': 'application/json',
          ...options?.headers,
        }),
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  };
  const urlSearchParams = new URLSearchParams(
    removeEmptyFromObject(options?.params || {})
  );

  const URL = apiUrlMaker(
    url,
    urlSearchParams.toString(),
    options?.isWholePath
  );

  function makeRequestWhenCan(): Promise<any> {
    return new Promise(async (resolve) => {
      const interval = setInterval(async () => {
        if (!isPending) {
          const request = await getRequest();
          clearInterval(interval);
          resolve(fetch(...request));
        }
      }, 250);
    });
  }

  async function getToken() {
    let tokenObj;

    tokenObj = Cookies.get(objCookieKey);
    tokenObj = JSON.parse(tokenObj || '{}');

    return options?.accessToken || tokenObj.accessToken;
  }

  async function getRequest(): Promise<any> {
    const token = await getToken();

    return [
      URL,
      {
        method,
        ...(isUploadFile && options ? { ...options } : {}),
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        ...(data ? { body: isUploadFile ? data : JSON.stringify(data) } : {}),
      },
    ];
  }

  function is401Client(response: any) {
    console.log('is401Client', response.status, tokenObj?.refreshToken);
    return response.status === 401 && tokenObj?.refreshToken;
  }

  async function handleRefreshToken() {
    if (!isPending) {
      isPending = true;
      const refreshToken = tokenObj?.refreshToken;

      try {
        const refreshResponse = await fetch(
          apiUrlMaker(authEndpoints.refreshToken, '', options?.isWholePath),
          {
            method: 'POST',
            headers,
            body: JSON.stringify({
              deviceId,
              userId: tokenObj.userId,
              refresh: refreshToken,
              // fromPageId: pageDetail?.id,
            }),
          }
        );

        if (refreshResponse.ok) {
          const refreshData = await refreshResponse?.json();
          Cookies.set(objCookieKey, JSON.stringify(refreshData));
          isPending = false;
        } else {
          const err = new Error('Error: refresh response is not ok');
          throw { ...err, data: undefined, status: 403 };
        }
      } catch (error) {
        const err = new Error('Error:error at refreshing ');
        throw { ...err, data: undefined, status: 403 };
      } finally {
        isPending = false;
      }
    }
    return await makeRequestWhenCan();
  }

  try {
    let response: any = await makeRequestWhenCan();

    if (is401Client(response)) {
      response = await handleRefreshToken();
    }

    const responseText = await response.text();
    const result = responseText === '' ? {} : JSON.parse(responseText);

    if (!response.ok) {
      const err = new Error('Error:');
      throw { ...err, ...result, data: undefined };
    }
    return { data: result };
  } catch (e) {
    // Sentry.captureException(e);

    if (e?.status === 403) {
      console.log('dadadaman222222222');
      onSuccessLogout();
    }

    throw {
      response: {
        data: e,
      },
    };
  }
};

const request = {
  get: async (url: string, options?: OptionType) => {
    return _call('GET', url, undefined, options);
  },
  post: async (url: string, data: any, options?: OptionType) => {
    return _call('POST', url, data, options);
  },
  put: async (url: string, data?: any, options?: OptionType) => {
    return _call('PUT', url, data, options);
  },
  delete: async (url: string, data: any, options?: OptionType) => {
    return _call('DELETE', url, data, options);
  },
};

export default request;
