const types: { [key: string]: string } = {
  // 100
  restaurant: 'utensils',
  coffeetea: 'mug-hot',
  // 200
  nightlifeentertainment: 'champagne-glasses',
  cinema: 'camera-movie',
  'theatre, Music and Culture': 'masks-theater-s',
  gamblinglotterybetting: 'dice',
  // 300
  landmarkattraction: 'archway',
  museum: 'landmark',
  religiousplace: 'place-of-worship',
  // 350
  bodyofwater: 'water',
  mountainorhill: 'mountains',
  underseafeature: 'fish',
  forestheathorothervegetation: 'trees',
  naturalandgeographical: 'flower-tulip',
  // 400
  airport: 'plane-departure',
  publictransport: 'bus',
  cargotransportation: 'square-this-way-up',
  restarea: 'square-parking',
  // 500
  hotelmotel: 'hotel',
  lodging: 'bed-bunk',
  // 550
  outdoorrecreation: 'hiking',
  leisure: 'campfire',
  // 600
  conveniencestore: 'store',
  shoppingmall: 'bag-shopping',
  mall: 'bag-shopping',
  shoppingcentre: 'bag-shopping',
  departmentstore: 'store',
  foodanddrink: 'shopping-cart',
  // TODO import the rest

  drugstoreorpharmacy: 'prescription-bottle-alt',
  electronics: 'phone-laptop',
  hardwarehouseandgarden: 'home-heart',
  bookstore: 'books',
  clothingandaccessories: 'tshirt',
  consumergoods: 'store',
  hairandbeauty: 'chair-office',
  // 700
  bank: 'university',
  atm: 'hand-holding-usd',
  moneycashservices: 'credit-card',
  communicationmedia: 'tv-music',
  commercialservices: 'building',
  businessfacility: 'building',
  policefireemergency: 'ambulance',
  consumerservices: 'building',
  postoffice: 'mailbox',
  fuelingstation: 'gas-pump',
  cardealersales: 'car',
  carrepairservice: 'car-mechanic',
  rentalcaragency: 'car',
  trucksemidealerservices: 'truck',
  // 800
  hospital: 'hospital',
  hospitalorhealthcarefacility: 'hospital',
  governmentorcommunityfacility: 'university',
  educationfacility: 'university',
  library: 'university',
  eventspaces: 'theater-masks',
  parking: 'parking',
  sportsfacilityvenue: 'running',
  facilities: 'building',
  // 900
  citytownorvillage: 'map-marker-alt',
  outdoorareacomplex: 'campground',
  building: 'home',
  administrativeregionstreets: 'university',
  // ===
  other: 'map-marker-alt',
};

const getPlaceIcon = (category: string): string => {
  const type = category
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replaceAll('/', '')
    .toLocaleLowerCase();

  return types[type] || types.other;
};
export default getPlaceIcon;
