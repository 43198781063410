import { useState } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import type { UseReactQueryType } from './useReactQuery';
import useReactQuery from './useReactQuery';
import type { PaginateResponse } from '../types';

export type UsePaginateQueryType<T = unknown, E = unknown> = Omit<
  UseQueryResult<T, E>,
  'data'
> & {
  queryKey: string | string[];
  page: number;
  setPage: (page: number) => void;
  isEmpty: boolean;
  content: Array<T>;
  totalElements: number;
  totalPages: number;
  searchId?: string;
};

const pageSize = 50;

const usePaginateQuery = <T = unknown, E = unknown>({
  action,
  config,
}: UseReactQueryType): UsePaginateQueryType<T, E> => {
  const [page, setPage] = useState(0);
  const queryKey = Array.isArray(action.key)
    ? [...action.key, `${page}`]
    : `${action?.key} ${page}`;

  const { data, ...rest } = useReactQuery<PaginateResponse<T>>({
    action: {
      ...action,
      key: queryKey,
      params: {
        page,
        pageSize,
        ...(action.params || {}),
      },
    },
    config: { keepPreviousData: true, ...(config || {}) },
  });

  return {
    ...rest,
    // @ts-ignore,
    searchId: data?.searchId,
    queryKey,
    page,
    setPage,
    content: data?.content,
    totalElements: parseInt(data?.totalElements || '0', 10),
    isEmpty: data?.empty,
    totalPages: parseInt(data?.totalPages || '0', 10),
  } as UsePaginateQueryType<T, E>;
};

export default usePaginateQuery;
