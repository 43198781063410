import { v4 as create, validate as isUuid } from 'uuid';

export type UuIdType = {
  create: () => string;
  isUuid: (id: string) => boolean;
};

const uuIdGenerator = (): UuIdType => ({
  create,
  isUuid,
});

export default uuIdGenerator;
