import request from '../utils/request';
import { networkEndpoints, postSearchEndpoints } from '../constants';
import factorFeeds from '../utils/normalizers/factorFeeds';
import type {
  IFeedElement,
  HashtagType,
  ListResponse,
  PaginateResponse,
  PaginateParamType,
  SettingType,
} from '../types';

export const getFeedOfCollection = async ({
  collectionId,
  text,
}: {
  collectionId: string;
  text?: string;
}): Promise<PaginateResponse<IFeedElement>> => {
  const { data } = await request.get(postSearchEndpoints.collection.feed, {
    params: {
      collectionId,
      text,
    },
  });

  return factorFeeds(data);
};

export const getMostPopularHashtags = async ({
  includeMyFollowing = true,
  includefollowersCounter = true,
  size = 99,
}: {
  includeMyFollowing: boolean;
  includefollowersCounter: boolean;
  size: number;
}): Promise<ListResponse<HashtagType>> => {
  const { data } = await request.get(networkEndpoints.hashtag.mostPopular, {
    params: {
      includeMyFollowing,
      includefollowersCounter,
      size,
    },
  });

  return data;
};

export const getSingleFeedElement = async ({
  params,
}: {
  params: {
    feedId: string | number;
  };
}): Promise<IFeedElement> => {
  const { feedId } = params;
  const { data } = await request.get(postSearchEndpoints.feed.single, {
    params: {
      postId: feedId,
    },
  });

  return data;
};

export const getFeedOfTaggedMentioned = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<any>> => {
  const { data } = await request.get(postSearchEndpoints.feed.taggedMentioned, {
    params,
  });

  return factorFeeds(data);
};

export const getFeedOfMyHidePosts = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<any>> => {
  const { data } = await request.get(postSearchEndpoints.feed.hidePosts, {
    params,
  });

  return factorFeeds(data);
};

export const setConsiderCommentInBuildingFeed = async ({
  value,
}: {
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(
    postSearchEndpoints.feed.considerCommentsInBuildingFeed,
    { value }
  );

  return data;
};

export const setConsiderActionsInBuildingFeed = async ({
  value,
}: {
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(
    postSearchEndpoints.feed.considerActionsInBuildingFeed,
    { value }
  );

  return data;
};

export const getSetting = async (): Promise<SettingType> => {
  const { data } = await request.get(postSearchEndpoints.setting);

  return data;
};

export default {
  getFeedOfCollection,
  getMostPopularHashtags,
  getSingleFeedElement,
  getFeedOfTaggedMentioned,
  getFeedOfMyHidePosts,
  setConsiderCommentInBuildingFeed,
  setConsiderActionsInBuildingFeed,
  getSetting,
};
