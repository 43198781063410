import { useMutation } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';
import request from '../utils/request';

export type MutationResult = UseMutationResult;

type useReactMutationType = {
  url?: string;
  method?: 'POST' | 'DELETE' | 'PUT';
  headers?: object;
  params?: any;
  apiFunc?: Function;
  signal?: any;
  cancelToken?: any;
};

const useReactMutation = ({
  apiFunc,
  url,
  method = 'POST',
  headers = {},
  params,
  signal,
  cancelToken,
  onSettled,
}: useReactMutationType): MutationResult => {
  const requestFunc = (data?: any) => {
    const METHOD = request[method?.toLowerCase()];

    return METHOD(url, data, {
      headers,
      params,
      signal, // TODO: Do we need it? if yes, how to use it?
      cancelToken, // TODO: Do we need it? if yes, how to use it?
    });
  };

  /*request({
      url,
      cancelToken,
      method,
      data,
      params,
      signal,
      headers: { ...headers },
    });*/

  return useMutation({
    mutationFn: (props) => apiFunc?.(props) || requestFunc?.(props),
    onSettled,
  });
};
export default useReactMutation;
