import { useEffect } from 'react';

const useEscPress = (callback: Function): void => {
  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        if (typeof callback === 'function') callback(e);
      }
    });
    return () => {
      window.removeEventListener('keyup', () => null, {});
    };
  }, []);
};

export default useEscPress;
