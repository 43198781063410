import getCookieKey from '../utils/getCookieKey';
import Cookies from '../utils/cookies';
import { registerDevice } from '../api/auth';
import deviceInfo from '../utils/deviceInfo';
import uuId from '../utils/uuIdGenerator';
import useReactQuery from './useReactQuery';
import QueryKeys from '../constants/enums/queryKeys';
import isServer from '../utils/isServer';
import type { UseQueryResult } from '@tanstack/react-query';

const useGetDeviceId = (): UseQueryResult | undefined => {
  try {
    if (isServer()) return undefined;
    const userAgent = window.navigator.userAgent;
    const DEVICE_ID = getCookieKey('deviceId');
    const cookieDeviceId = Cookies.get(DEVICE_ID);
    const device = deviceInfo(userAgent);
    const deviceId = cookieDeviceId || uuId().create();

    const reactQuery = useReactQuery({
      action: {
        key: [QueryKeys.getDeviceId],
        apiFunc: () =>
          registerDevice({
            id: deviceId,
            location: undefined,
            browserName: device.browser.name as string,
            osName: device.os.name as string,
          }),
      },
      config: {
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: 60 * 60 * 1000,
        retry: 3,
      },
    });
    return reactQuery;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export default useGetDeviceId;
