import React, { createContext, useContext, ReactNode, useState } from 'react';
import Cookies from '../../utils/cookies';
import getCookieKey from '../../utils/getCookieKey';

interface LanguageContextState {
  language: string;
  switchLanguage: (language: string) => void;
}

const LanguageContext = createContext<LanguageContextState | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{
  children: ReactNode;
  lng: string;
}> = ({ children, lng }) => {
  const LOCALE = getCookieKey('locale');
  const [language, setLanguage] = useState(lng);

  const switchLanguage = (lang: string) => {
    Cookies.set(LOCALE, lang, { expires: 365 });
    setLanguage(lang);
    window.location.reload();
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
