export const generateBadgeNumber = (
  badgeNumber: string | number,
): string => {
  const number = Number(badgeNumber) || 0;
  return (
    (number > 99 && '+99')
    || (number > 0 && `${number}`)
    || ''
  );
}

export default generateBadgeNumber;
