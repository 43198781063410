import isClient from './isClient';

type EventKeyTypes = string;

const EVENTS: any = {};

const _dispatchEvent = <DataType = any>(
  eventName: string,
  data: DataType
): void => {
  const event = new CustomEvent(eventName, {
    detail: data,
  });

  document.dispatchEvent(event);
};

const _CustomEventPolyfill = () => {
  if (!isClient()) {
    return;
  }
  if (typeof window?.CustomEvent === 'function') {
    return;
  }

  const CustomEvent = (event: EventKeyTypes, params: any) => {
    const evt = document.createEvent('CustomEvent');

    params = params || { bubbles: false, cancelable: false, detail: undefined };
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );

    return evt;
  };

  CustomEvent.prototype = window.Event.prototype;
  // @ts-ignore
  window.CustomEvent = CustomEvent;
};

export default {
  on: (
    eventName: EventKeyTypes,
    callback: EventListenerOrEventListenerObject
  ): void => {
    _CustomEventPolyfill();

    if (EVENTS[eventName]) {
      EVENTS[eventName]?.callbacks?.push(callback);
    } else {
      EVENTS[eventName] = {
        callbacks: [callback],
      };
    }

    document.addEventListener(eventName, callback);
  },

  off: (eventName: EventKeyTypes): void => {
    if (!EVENTS[eventName]) {
      return;
    }

    EVENTS[eventName].callbacks.forEach(
      (callback: EventListenerOrEventListenerObject) => {
        document.removeEventListener(eventName, callback);
      }
    );

    delete EVENTS[eventName];
  },

  trigger: <DataType = any>(eventName: EventKeyTypes, data?: DataType): void => {
    _dispatchEvent(eventName, data || null);
  },
};
