import Cookies from './cookies';
import getCookieKey from './getCookieKey';

const removePageCookies = (): void => {
  const BUSINESS_ID = getCookieKey('businessId');
  const BUSINESS_OBJ = getCookieKey('businessObjToken');
  const BUSINESS_PAGE = getCookieKey('businessPage');
  const IS_DARK_KEY = getCookieKey('isDark');

  Cookies.remove(BUSINESS_OBJ);
  Cookies.remove(IS_DARK_KEY);
  Cookies.remove(BUSINESS_ID);
  Cookies.remove(BUSINESS_PAGE);
};

export default removePageCookies;
