import request from '../utils/request';
import Endpoints from '../constants/endpoints';
import { profileEndpoints } from '../constants/servicesEndpoints';
import type {
  ProfileType,
  WorkStatusType,
  ResumePermitType,
  PaginateParamType,
  PaginateResponse,
  PeopleType,
} from '../types';
import lookupApi from './lookup';
import networkNormalizer from '../utils/normalizers/network';
import geoApi from '@lobox/utils/api/geo';

export const publishToMyProfile = async ({
  url,
  formData,
}: any): Promise<any> => {
  const { data } = await request.post(url, formData);
  return data;
};

export const getAboutSectionsData = async ({
  params,
  accessToken,
}: any): Promise<ProfileType> => {
  const { data } = await request.get(Endpoints.App.User.Profile.get, {
    accessToken,
    params: {
      username: params.username,
    },
  });

  return data;
};

export const getAllProfiles = async (): Promise<any> => {
  const { data } = await request.get(
    Endpoints.App.User.Profile.getAllProfiles,
    { params: { size: 100 } }
  );

  return data?.content?.map((item: any) => ({
    id: item.id,
    title: `${item.name || item.username} ${item.surname || ''}`,
    job: item.occupationName,
    username: item.username,
    avatar: item.croppedImageUrl,
  }));
};

export const getUserExperiences = async ({
  params,
}: {
  params: any;
}): Promise<any> => {
  if (!params?.username) {
    throw new Error('username is required');
  }
  const url = profileEndpoints.experience.get;
  const { data } = await request.get(url, {
    params,
  });
  return data;
};

export const updateExperience = async ({
  id,
  experienceItem,
}: any): Promise<any> => {
  const url = `${profileEndpoints.experience.post}/${id}`;
  const { data } = await request.put(url, experienceItem);
  return data;
};

export const setOpenForJobOpportunities = async ({
  openForJobOpportunities,
}: {
  openForJobOpportunities: boolean;
}): Promise<any> => {
  await request.put(profileEndpoints.job.opportunities, {
    openForJobOpportunities,
  });
};

export const getOpenForJobOpportunities = async (): Promise<boolean> => {
  const { data } = await request.get(profileEndpoints.job.opportunities);

  return !!data?.value;
};

export const setProfilePrivacy = async (privacyData: {
  privateProfile: boolean;
}): Promise<any> => {
  const { data } = await request.put(profileEndpoints.privacy, privacyData);

  return data;
};

export const setLinks = async (linksData: {
  links: Array<string>;
}): Promise<void> => {
  const { data } = await request.put(profileEndpoints.links, linksData);

  return data;
};

export const setWorkStatus = async (workStatusData: {
  workStatus: WorkStatusType;
  workStatusDescription?: string;
}): Promise<void> => {
  const { data } = await request.put(
    profileEndpoints.workStatus,
    workStatusData
  );

  return data;
};

export const deleteWorkStatus = async (): Promise<void> => {
  const { data } = await request.delete(profileEndpoints.workStatus);

  return data;
};

export const setResumeLinkPermit = async (resumePermitData: {
  resumeLinkPermit: ResumePermitType;
}): Promise<void> => {
  const { data } = await request.put(
    profileEndpoints.resumeLinkPermit,
    resumePermitData
  );

  return data;
};

export const deleteResumeLinkPermit = async (): Promise<void> => {
  const { data } = await request.delete(profileEndpoints.resumeLinkPermit);

  return data;
};

export const updateProfile = async (profileData: any) => {
  const { location, ...rest } = profileData;
  console.log('location', location);
  // const locationDetails = await geoApi.getLocationDetails(location.value);
  const { data } = await request.put(Endpoints.Auth.getAuthUserInfo, {
    ...rest,
    location,
    // location: locationDetails,
  });

  return data;
};

export const getWorkingAtCompany = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(profileEndpoints.getWorkingAtCompany, {
    params,
  });

  return networkNormalizer.suggestPeople(data);
};

export default {
  getAboutSectionsData,
  getAllProfiles,
  publishToMyProfile,
  getUserExperiences,
  updateExperience,
  setOpenForJobOpportunities,
  getOpenForJobOpportunities,
  setProfilePrivacy,
  setLinks,
  setWorkStatus,
  deleteWorkStatus,
  setResumeLinkPermit,
  deleteResumeLinkPermit,
  updateProfile,
  getWorkingAtCompany,
};
