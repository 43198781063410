const USER_SERVICE = '/user-search-service';
const POST_SEARCH_SERVICE = 'post-search-service';
const API_VERSION = '/api/v1';

const searchEndPoints = {
  suggestObject: `${USER_SERVICE}${API_VERSION}/suggest-user`,
  searchPage: `${USER_SERVICE}${API_VERSION}/search-page`,
  suggestHashtag: `${POST_SEARCH_SERVICE}${API_VERSION}/hashtags/suggest`,
  searchPerson: `${USER_SERVICE}${API_VERSION}/search-person`,
  searchPersonFilter: `${USER_SERVICE}${API_VERSION}/search-person/filter`,
  searchPagesFilter: `${USER_SERVICE}${API_VERSION}/search-page/filter`,
  searchHashtags: `${POST_SEARCH_SERVICE}${API_VERSION}/hashtags`,
  searchPosts: `${POST_SEARCH_SERVICE}${API_VERSION}/posts`,
  searchPostFilter: `${POST_SEARCH_SERVICE}${API_VERSION}/posts/filter`,
  mentions: `${USER_SERVICE}${API_VERSION}/mention-helper`,
  getObjectById: `${USER_SERVICE}${API_VERSION}/user`,
  suggestPage: `${USER_SERVICE}${API_VERSION}/suggest-page`,
};

export default searchEndPoints;
