import request from '../utils/request';
import Endpoints from '../constants/endpoints';

export const createSkill = async (
  payload: Record<string, any>
): Promise<any> => {
  const url = Endpoints.App.User.Skill.get;

  const { data } = await request.post(url, payload);

  return data;
};

export const editSkill = async (payload: Record<string, any>): Promise<any> => {
  const url = Endpoints.App.User.Skill.one(payload?.id);

  const { data } = await request.put(url, payload);

  return data;
};

export const deleteSkill = (payload: Record<string, any>): Promise<any> => {
  const url = Endpoints.App.User.Skill.one(payload?.id);

  return request.delete(url);
};

export default {
  deleteSkill,
  editSkill,
  createSkill,
};
