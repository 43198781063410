import { useEffect } from 'react';

const useScrollToHash = () => {
  useEffect(() => {
    const hash = window.location.hash || '';
    const id = hash.length ? hash.substring(1) : '';
    const el = document.getElementById(id);

    if (el) {
      window.scrollTo({
        top: el.offsetTop - 16,
        behavior: 'smooth',
      });
    }
  }, []);
};

export default useScrollToHash;
