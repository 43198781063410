import { siteLanguages } from '../site-settings/app-settings';
import Cookies from './cookies';
import getCookieKey from './getCookieKey';

const defaultLang = 'en';

const getBrowserLanguage = (req?: any): string => {
  const LOCALE = getCookieKey('locale');

  if (req) {
    return (
      req?.cookies.lng ||
      siteLanguages[req?.i18n?.language?.split('-')?.[0]] ||
      defaultLang
    );
  }

  if (typeof navigator === 'undefined') {
    return defaultLang;
  }
  const cookieLng = Cookies.get(LOCALE);
  if (cookieLng) {
    return siteLanguages[cookieLng] || defaultLang;
  }

  const browserLng = navigator.language || (navigator as any).userLanguage;
  if (!browserLng) {
    return defaultLang;
  }
  const languageCode = browserLng.split('-')[0];
  return siteLanguages[languageCode] || defaultLang;
};

export default getBrowserLanguage;
