import profileEndpoints from './services/profile';
import postEndpoints from './services/post';
import postSearchEndpoints from './services/postSearch';
import statisticsEndpoints from './services/statistics';
import networkEndpoints from './services/network';
import lookupEndpoints from './services/lookup';
import pageEndpoints from './services/page';
import reportEndpoints from './services/report';
import searchEndPoints from './services/search';
import invitationEndpoints from './services/invitation';
import authEndpoints from './services/auth';
import notificationEndpoints from './services/notification';
import messageEndPoints from './services/message';
import jobsEndpoints from './services/jobs';
import adminEndpoints from './services/admin';
import schedulesEndpoints from './services/schedules';
import depotsEndpoints from './services/depot';
import geoEndpoints from './services/geo';

export {
  profileEndpoints,
  postEndpoints,
  postSearchEndpoints,
  statisticsEndpoints,
  networkEndpoints,
  lookupEndpoints,
  pageEndpoints,
  reportEndpoints,
  searchEndPoints,
  invitationEndpoints,
  authEndpoints,
  notificationEndpoints,
  messageEndPoints,
  jobsEndpoints,
  schedulesEndpoints,
  depotsEndpoints,
  adminEndpoints,
  geoEndpoints,
};
