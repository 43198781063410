import dayjs from 'dayjs';
import {
  I18nProvider,
  useAuthDispatch,
  useAuthState,
  useGlobalDispatch,
  useGlobalState,
} from './contexts';
import withFormikContext from './utils/withFormikContext';
import type { WithFormikProps } from './utils/withFormikContext';
import usePreventClickOnDrag from './hooks/usePreventClickOnDrag';
import useComponentDidUpdate from './hooks/useComponentDidUpdate';
import useScrollMove from './hooks/useScrollMove';
import useResizeWindow from './hooks/useResizeWindow';
import useOutsideListener from './hooks/useOutsideListener';
import useStateCallback from './hooks/useStateCallback';
import useInfiniteQuery from './hooks/useInfiniteQuery';
import usePaginateQuery from './hooks/usePaginateQuery';
import useUpdateInfinityData from './hooks/useUpdateInfinityData';
import useUpdateQueryData from './hooks/useUpdateQueryData';
import useAuthUser from './hooks/useAuthUser';
import useCreateContext from './hooks/useCreateContext';
import useDisclosure from './hooks/useDisclosure';
import useIsPageCreation from './hooks/useIsPageCreation';
import useLongPress from './hooks/useLongPress';
import useMakeCollectionRoute from './hooks/useMakeCollectionRoute';
import useGetObjectDetail from './hooks/useGetObjectDetail';
import useScrollTop from './hooks/useScrollTop';
import useScrollToHash from './hooks/useScrollToHash';
import useTranslation from './hooks/useTranslation';
import useBusinessPage from './hooks/useBusinessPage';
import useCallbackRef from './hooks/useCallbackRef';
import useMeasure from './hooks/useMeasure';
import { useDebounce, useDebounceState } from './hooks/useDebounceState';
import useReactQuery from './hooks/useReactQuery';
import useReactMutation from './hooks/useReactMutation';
import useQuerySelector from './hooks/useQuerySelector';
import useEscPress from './hooks/useEscPress';
import getLocalforageMethods from './utils/getLocalforageMethods';
import useUrlQuery from './hooks/useUrlQuery';
import useRecentSearches from './hooks/useRecentSearches';
import useScrollReachEnd from './hooks/useScrollReachEnd';
import useBrowserBack from './hooks/useBrowserBack';
import useKeyPress from './hooks/useKeyPress';
import useWindowDimensions from './hooks/useWindowDimensions';
import useMobileWindowHeightChanges from './hooks/useMobileWindowHeightChanges';
import useClickOutside from './hooks/useClickOutside';
import useDoubleClick from './hooks/useDoubleClick';
import useLogout from './hooks/useLogoutMutation';
import useModalCount from './hooks/useModalCount';
import useViewportTop from './hooks/useViewportTop';
import useVisualViewportResize from './hooks/useVisualViewportResize';
import useUrlState from './hooks/useUrlState';
import useLocation from './hooks/useLocation';

import fileApi, { uploadFile } from './api/file';
import {
  getObjectDetail,
  setAllowOthersToShareYourPosts,
  setAllowOthersToTagOrMentionYou,
} from './api/object';
import postApi, {
  addReaction,
  createPost,
  deleteCollection,
  deletePost,
  deletePostFromCollection,
  editPost,
  getFeedList,
  getFeedListByHashtag,
  getPostActionsStatus,
  getPostOrCommentReactions,
  getUserAllPosts,
  getUserFeedList,
  getUserHighlights,
  hidePost,
  removeReaction,
  savePostToCollection,
  togglePostNotifications,
  unHidePost,
  updateCollection,
} from './api/post';
import commentApi, {
  addComment,
  deleteComment,
  editComment,
  getCommentReplies,
  getPostComments,
  hideComment,
  unHideComment,
} from './api/comment';
import lookupApi, {
  getCategory,
  getIndustryById,
  getOccupationById,
  getPopularPageCategories,
  getPopularPeopleCategories,
} from './api/lookup';

import geoApi, {
  deleteLanguage,
  getCities,
  getCityById,
  getCountries,
  getCountryById,
  getDiscoverPlace,
} from './api/geo';
import pageApi, {
  acceptMemberShip,
  declineMemberShip,
  deletePage,
  deletePageMember,
  getFilteredPages,
  getMyOwnPages,
  getMyPages,
  getPageDetail,
  getPageMembers,
  givePageAccess,
  setAdultContent,
  setCategory,
  setCompanySize,
  setDescription,
  setEstablishmentDate,
  setIndustry,
  setPageEmail,
  setPageLink,
  setPageLocations,
  setPageName,
  setPagePhone,
  setPublish,
  setTitle,
  setUnpublish,
  updatePage,
  updatePageLocations,
  updatePageTitle,
} from './api/page';
import reportApi, {
  block,
  feedback,
  submitReport,
  unblock,
} from './api/report';
import profileApi, {
  deleteResumeLinkPermit,
  deleteWorkStatus,
  getAboutSectionsData,
  getAllProfiles,
  getOpenForJobOpportunities,
  getUserExperiences,
  publishToMyProfile,
  setLinks,
  setOpenForJobOpportunities,
  setProfilePrivacy,
  setResumeLinkPermit,
  setWorkStatus,
  updateProfile,
  getWorkingAtCompany,
} from './api/profile';
import { getAuthUser, getUser, getUserNameByObjectId } from './api/user';
import {
  createCollection,
  getCollectionItem,
  getCollectionsList,
  removeCollection,
} from './api/collection';
import {
  getFeedOfCollection,
  getFeedOfMyHidePosts,
  getFeedOfTaggedMentioned,
  getMostPopularHashtags,
  getSetting,
  getSingleFeedElement,
  setConsiderActionsInBuildingFeed,
  setConsiderCommentInBuildingFeed,
} from './api/postSearch';
import {
  getFollowDiagram,
  getPostInteractionsCount,
  getTopPosts,
  getViewCount,
  getViewDiagram,
} from './api/statistics';
import {
  followHashtag,
  getHashtagDetail,
  getUserHashtags,
  unfollowHashtag,
} from './api/network/hashtag';
import {
  followPage,
  getMutualPages,
  getMyFollowerPages,
  getMyFollowingPages,
  getPopularPages,
  getSuggestionPages,
  unfollowPage,
} from './api/network/page';
import {
  getBlockedObject,
  getFollowers,
  getFollowings,
  getFollowRequestPaginated,
  getPendingRequestsPaginated,
  getUserNetworkRelation,
} from './api/network/common';
import {
  acceptFollow,
  cancelFollowRequest,
  declineFollow,
  followPeople,
  getMutualPeoples,
  getMutual,
  getMyFollowerPeople,
  getMyFollowingPeople,
  getMyFollowRequests,
  getPendingRequests,
  getSuggestionPeople,
  getUpcomingBirthDays,
  unfollowPeople,
  getPopularPeople,
} from './api/network/people';
import {
  getObjectById,
  getObjectDetailByIds,
  getPopularHashtags,
  getUsersToMention,
  searchHashtags,
  searchPages,
  searchPerson,
  searchPosts,
  getSearchPersonFilters,
  getSearchPostFilters,
  getSearchPagesFilters,
  suggestHashtags,
  suggestObjects,
} from './api/search';
import {
  getFollowList,
  getInvitationList,
  sendInvitation,
  unsubscribe,
} from './api/invite';
import notificationApi, {
  deleteNotification,
  getNotifications,
  getNotificationSetting,
  getUnSeenNotificationsCount,
  seenAllNotification,
  seenNotification,
  setNotificationSetting,
  setNotificationsOpened,
  getMyPagesNotifications,
} from './api/notification';
import {
  addEmail,
  changePassword,
  checkPassword,
  deactivateMe,
  deleteMe,
  enterToPage,
  checkIsTwoFactorAuthActive,
  confirmTwoFactorAuth,
  enableTwoFactorAuth,
  getBackupCode,
  disableTwoFactorAuth,
  loginWithTwoFactorAuth,
  refreshBackupCode,
  forgetPassword,
  getAllDevices,
  getAllEmails,
  getPublicSetting,
  logout,
  logoutOtherDevices,
  refreshAccessToken,
  registerDevice,
  removeEmail,
  resendEmailVerification,
  setAllowNonLoboxUsersToFindYou,
  setAllowPageRoleAssign,
  setBirthDate,
  setEmailPrimary,
  setGender,
  setLocation,
  setName,
  setOccupation,
  setPhone,
  setPublicSetting,
  setUsername,
  updateEmailPrivacy,
  signupCompletion,
  postSignup,
  verifyEmail,
} from './api/auth';
import skillApi, { createSkill, deleteSkill, editSkill } from './api/skill';
import lookupNormalizer from './utils/normalizers/lookup';

import jobsApi from './api/jobs';
import adminApi from './api/admin';
import schedulesApi from './api/schedules';
import depotApi from './api/depot';
import useHistory from './hooks/useHistory';

import {
  depotsEndpoints,
  APP_DESKTOP_FOOTER,
  APP_ENTITIES,
  authEndpoints,
  CREATE_POST_BOX,
  db,
  Endpoints,
  fullNavigationPagesList,
  getPlaceIcon,
  hereApiModuleType,
  invitationEndpoints,
  jobsDb,
  schedulesDb,
  jobSelectedKeys,
  jobStatuses,
  jobStatusKeys,
  landingRouteNames,
  locationZoomLevelValues,
  lookupEndpoints,
  geoEndpoints,
  mainRoutes,
  messageEndPoints,
  needsAuthPages,
  doesNotNeedAuthPages,
  networkEndpoints,
  notificationEndpoints,
  NotificationVariants,
  objectPageNeedsAuthTabsList,
  pageEndpoints,
  postEndpoints,
  PostReactions,
  postSearchEndpoints,
  profileEndpoints,
  QueryKeys,
  reportEndpoints,
  routeNames,
  searchEndPoints,
  sectionsIds,
  statisticsEndpoints,
  ToggleNotificationList,
  defaultRoutesOfSections,
  urls,
  appEnvironment,
  LandingEndpoints,
} from './constants';
import {
  siteLanguages,
  siteLanguagesEnum,
  LG_TABLET,
  MID_MOBILE,
  MID_TABLET,
  POPULAR_PAGES_TITLE_LIMITATION,
  XS_MOBILE,
} from './site-settings/app-settings';
import {
  applyResolutionToImageSrc,
  callTo,
  checkLinkPrefix,
  convertBase64ToBlob,
  convertBlobToBase64,
  convertBlobToFile,
  Cookies,
  dateFromNow,
  datesDuration,
  deviceHeight,
  deviceInfo,
  deviceWidth,
  emailValidation,
  emailValidation2,
  encoder,
  event,
  formatDate,
  formatSeconds,
  formValidator,
  generateBadgeNumber,
  mediaCanBeUploaded,
  geocodeResponseNormalizer,
  getAutoHeightDuration,
  getCookieFromReqByKey,
  getCookieKey,
  getCountryValidation,
  getEmailValidation,
  getImageData,
  getVideoImage,
  hereApiResponseNormalizer,
  hex2rgba,
  i18n,
  imageUrlToBlobAndBase64,
  isClient,
  isElementVisible,
  isEmptyObjectValues,
  isInViewport,
  isIos,
  isPwa,
  isRichTextEmpty,
  isSameDay,
  isTouchDevice,
  libphonenumber,
  linkValidation,
  lookupResponseNormalizer,
  mailTo,
  makePostMediaFromFiles,
  preventClickHandler,
  redirectUrl,
  removeEmptyFromObject,
  removePageCookies,
  removeQueryString,
  removeRichTextBreakLines,
  removeUserCookies,
  reorderArray,
  replaceUrl,
  request,
  skillsResponseNormalizer,
  sortByKey,
  urlsBuilder,
  useInterval,
  uuId,
  webifyImage,
  getCountryValidation2,
  getQueryParams,
  getXEmailValidation,
  resetPassWordValidation,
} from './utils';
import translateReplacer from './utils/translateReplacer';
import skillNormalizer from './utils/normalizers/skillNormalizer';
import jobsNormalizer from './utils/normalizers/jobs';
import {
  DATE_FORMAT_VALUES,
  GENDER_VALUES,
  INVITE_STATUS,
  LANGUAGES_LIST_VALUES,
  MAIN_CENTER_WRAPPER_ID,
  PAGE_MEMBER_STATUS,
  PAGE_ROLES,
  privacyValues,
  RESUME_LINK_VALUES,
  TAB_PAGES_HEADER_SEARCH_ID,
  TIME_FORMAT_VALUES,
  WORK_STATUS_VALUES,
  schedulesEventValues,
} from './constants/enums';
import {
  getRecommendationDetail,
  declineRecommendation,
  acceptRecommendation,
  deleteRecommendation,
} from './api/recommendation';
import beforeCachePageDetail from './utils/normalizers/beforeCachePageDetail';
import languageNormalizer from './utils/normalizers/languageNormalizer';
import collectionToObjectByKey from './utils/collectionToObjectByKey';
import isPropsChanged from './utils/isPropsChanged';
import pluralize from './utils/pluralize';
import { cookieKeys } from './utils/getCookieKey';
import getLangData from './utils/getLangData';
import getBrowserLanguage from './utils/getBrowserLanguage';
import removeBreaksAndSpaces from './utils/removeBreaksAndSpaces';
import removeHtmlTagsInstring from './utils/removeHtmlTagsInstring';
import useOnSuccessLogin from './hooks/useOnSuccessLogin';
import useSignUpRedirections from './hooks/useSignUpRedirections';
import getVideoDuration from './utils/getVideoDuration';
import useGetLinkMetaData from './hooks/useGetLinkMetaData';
import matchPath from './utils/matchPath';
import useParams from './hooks/useParams';
import useGetDeviceId from './hooks/useGetDeviceId';

export * from './types';

export {
  useUrlState,
  useSignUpRedirections,
  getCountryValidation2,
  getQueryParams,
  getXEmailValidation,
  resetPassWordValidation,
  postSignup,
  verifyEmail,
  useOnSuccessLogin,
  LandingEndpoints,
  urls,
  appEnvironment,
  uuId,
  // enums
  TAB_PAGES_HEADER_SEARCH_ID,
  MAIN_CENTER_WRAPPER_ID,
  INVITE_STATUS,
  WORK_STATUS_VALUES,
  RESUME_LINK_VALUES,
  LANGUAGES_LIST_VALUES,
  TIME_FORMAT_VALUES,
  DATE_FORMAT_VALUES,
  GENDER_VALUES,
  PAGE_MEMBER_STATUS,
  PAGE_ROLES,
  privacyValues,
  CREATE_POST_BOX,
  APP_DESKTOP_FOOTER,
  schedulesEventValues,
  // contexts
  I18nProvider,
  useAuthState,
  useAuthDispatch,
  useGlobalState,
  useGlobalDispatch,
  // Constants
  NotificationVariants,
  ToggleNotificationList,
  PostReactions,
  APP_ENTITIES,
  getPlaceIcon,
  hereApiModuleType,
  Endpoints,
  reportEndpoints,
  profileEndpoints,
  postEndpoints,
  postSearchEndpoints,
  statisticsEndpoints,
  networkEndpoints,
  lookupEndpoints,
  geoEndpoints,
  pageEndpoints,
  invitationEndpoints,
  authEndpoints,
  notificationEndpoints,
  QueryKeys,
  db,
  jobsDb,
  schedulesDb,
  jobStatuses,
  jobStatusKeys,
  jobSelectedKeys,
  urlsBuilder,
  mainRoutes,
  sectionsIds,
  routeNames,
  landingRouteNames,
  fullNavigationPagesList,
  needsAuthPages,
  doesNotNeedAuthPages,
  objectPageNeedsAuthTabsList,
  searchEndPoints,
  messageEndPoints,
  // HOCs
  withFormikContext,
  WithFormikProps,
  // Hooks
  useWindowDimensions,
  useMobileWindowHeightChanges,
  useClickOutside,
  useDoubleClick,
  usePreventClickOnDrag,
  useComponentDidUpdate,
  useScrollMove,
  useResizeWindow,
  useOutsideListener,
  useStateCallback,
  useEscPress,
  useInfiniteQuery,
  usePaginateQuery,
  useDebounce,
  useDebounceState,
  useUpdateInfinityData,
  useUpdateQueryData,
  useAuthUser,
  useCreateContext,
  useDisclosure,
  getFollowers,
  getFollowings,
  getPendingRequestsPaginated,
  getFollowRequestPaginated,
  getUserNetworkRelation,
  getBlockedObject,
  useIsPageCreation,
  useLongPress,
  useMakeCollectionRoute,
  useGetObjectDetail,
  useScrollTop,
  useScrollToHash,
  useTranslation,
  useBusinessPage,
  useCallbackRef,
  useMeasure,
  useReactQuery,
  useReactMutation,
  useQuerySelector,
  useUrlQuery,
  useRecentSearches,
  useScrollReachEnd,
  useBrowserBack,
  useKeyPress,
  useLogout,
  useModalCount,
  useViewportTop,
  useVisualViewportResize,
  // api
  notificationApi,
  getNotifications,
  getUnSeenNotificationsCount,
  seenNotification,
  deleteNotification,
  seenAllNotification,
  setNotificationsOpened,
  getPostOrCommentReactions,
  updateCollection,
  deleteCollection,
  reportApi,
  skillApi,
  submitReport,
  block,
  unblock,
  feedback,
  hideComment,
  unHideComment,
  hidePost,
  unHidePost,
  deletePostFromCollection,
  getUserFeedList,
  getUserHighlights,
  getUserAllPosts,
  getPostActionsStatus,
  removeReaction,
  togglePostNotifications,
  addReaction,
  commentApi,
  getPostComments,
  addComment,
  editComment,
  deleteComment,
  getCommentReplies,
  savePostToCollection,
  updatePageLocations,
  getFilteredPages,
  updatePageTitle,
  getUserExperiences,
  getOpenForJobOpportunities,
  setOpenForJobOpportunities,
  setProfilePrivacy,
  setLinks,
  setWorkStatus,
  deleteWorkStatus,
  setResumeLinkPermit,
  deleteResumeLinkPermit,
  getCollectionsList,
  getCollectionItem,
  createCollection,
  removeCollection,
  getFeedOfCollection,
  getMostPopularHashtags,
  getSingleFeedElement,
  getFeedOfTaggedMentioned,
  getFeedOfMyHidePosts,
  setConsiderCommentInBuildingFeed,
  setConsiderActionsInBuildingFeed,
  getSetting,
  pageApi,
  updatePage,
  publishToMyProfile,
  lookupApi,
  geoApi,
  getPopularPageCategories,
  getPopularPeopleCategories,
  getCountries,
  getCities,
  getCategory,
  getCountryById,
  getCityById,
  getDiscoverPlace,
  fileApi,
  profileApi,
  uploadFile,
  getObjectDetail,
  setAllowOthersToShareYourPosts,
  setAllowOthersToTagOrMentionYou,
  getPageDetail,
  getMyPages,
  getMyPagesNotifications,
  getMyOwnPages,
  postApi,
  getAboutSectionsData,
  getAllProfiles,
  getAuthUser,
  getUserNameByObjectId,
  getUser,
  createPost,
  editPost,
  getFeedList,
  getFeedListByHashtag,
  deletePost,
  givePageAccess,
  acceptMemberShip,
  declineMemberShip,
  getPageMembers,
  deletePageMember,
  setTitle,
  setPageName,
  setCategory,
  setIndustry,
  setDescription,
  setEstablishmentDate,
  setCompanySize,
  setPublish,
  setUnpublish,
  setAdultContent,
  deletePage,
  setPageEmail,
  setPagePhone,
  setPageLink,
  setPageLocations,
  suggestHashtags,
  suggestObjects,
  getLocalforageMethods,
  searchPerson,
  searchPages,
  searchHashtags,
  searchPosts,
  getSearchPersonFilters,
  getSearchPagesFilters,
  getUsersToMention,
  getSearchPostFilters,
  getPopularHashtags,
  getObjectById,
  getOccupationById,
  getIndustryById,
  deleteSkill,
  createSkill,
  editSkill,
  deleteLanguage,
  jobsApi,
  depotApi,
  schedulesApi,
  // statistics-services
  getViewCount,
  getPostInteractionsCount,
  getFollowDiagram,
  getViewDiagram,
  getTopPosts,
  // network-hashtag
  getUserHashtags,
  getHashtagDetail,
  followHashtag,
  unfollowHashtag,
  // network-page
  followPage,
  unfollowPage,
  getMyFollowingPages,
  getMutualPages,
  getMutualPeoples,
  getMutual,
  getMyFollowerPages,
  getSuggestionPages,
  getPopularPages,
  // network-people
  followPeople,
  unfollowPeople,
  getSuggestionPeople,
  getPopularPeople,
  getMyFollowRequests,
  acceptFollow,
  declineFollow,
  getMyFollowingPeople,
  getMyFollowerPeople,
  getPendingRequests,
  cancelFollowRequest,
  getUpcomingBirthDays,
  // invitation
  sendInvitation,
  getInvitationList,
  getFollowList,
  unsubscribe,
  // settings api
  setGender,
  deactivateMe,
  checkPassword,
  changePassword,
  logout,
  deleteMe,
  registerDevice,
  refreshAccessToken,
  getAllDevices,
  logoutOtherDevices,
  getAllEmails,
  addEmail,
  setEmailPrimary,
  removeEmail,
  resendEmailVerification,
  getPublicSetting,
  setPublicSetting,
  setAllowNonLoboxUsersToFindYou,
  setAllowPageRoleAssign,
  forgetPassword,
  enterToPage,
  checkIsTwoFactorAuthActive,
  confirmTwoFactorAuth,
  enableTwoFactorAuth,
  getBackupCode,
  disableTwoFactorAuth,
  loginWithTwoFactorAuth,
  refreshBackupCode,
  setName,
  setUsername,
  setPhone,
  setLocation,
  setOccupation,
  setBirthDate,
  // site-settings
  siteLanguages,
  siteLanguagesEnum,
  POPULAR_PAGES_TITLE_LIMITATION,
  XS_MOBILE,
  MID_MOBILE,
  MID_TABLET,
  LG_TABLET,
  // utils
  getCookieKey,
  event,
  makePostMediaFromFiles,
  getAutoHeightDuration,
  hex2rgba,
  removeUserCookies,
  removePageCookies,
  lookupResponseNormalizer,
  hereApiResponseNormalizer,
  geocodeResponseNormalizer,
  skillsResponseNormalizer,
  i18n,
  emailValidation2,
  getEmailValidation,
  emailValidation,
  getCountryValidation,
  formatDate,
  datesDuration,
  sortByKey,
  checkLinkPrefix,
  formValidator,
  linkValidation,
  isTouchDevice,
  isIos,
  isClient,
  deviceWidth,
  deviceHeight,
  mailTo,
  callTo,
  Cookies,
  request,
  imageUrlToBlobAndBase64,
  preventClickHandler,
  redirectUrl,
  replaceUrl,
  convertBlobToFile,
  convertBlobToBase64,
  convertBase64ToBlob,
  isElementVisible,
  isEmptyObjectValues,
  locationZoomLevelValues,
  removeRichTextBreakLines,
  getNotificationSetting,
  setNotificationSetting,
  dateFromNow,
  deviceInfo,
  encoder,
  removeEmptyFromObject,
  useInterval,
  updateEmailPrivacy,
  getObjectDetailByIds,
  getRecommendationDetail,
  isSameDay,
  libphonenumber,
  isRichTextEmpty,
  getCookieFromReqByKey,
  isPwa,
  webifyImage,
  beforeCachePageDetail,
  getImageData,
  applyResolutionToImageSrc,
  removeQueryString,
  isInViewport,
  formatSeconds,
  getVideoImage,
  reorderArray,
  generateBadgeNumber,
  mediaCanBeUploaded,
  skillNormalizer,
  languageNormalizer,
  jobsNormalizer,
  collectionToObjectByKey,
  isPropsChanged,
  pluralize,
  dayjs,
  defaultRoutesOfSections,
  lookupNormalizer,
  signupCompletion,
  updateProfile,
  getWorkingAtCompany,
  cookieKeys,
  depotsEndpoints,
  getLangData,
  getBrowserLanguage,
  removeBreaksAndSpaces,
  translateReplacer,
  removeHtmlTagsInstring,
  adminApi,
  getVideoDuration,
  useGetLinkMetaData,
  useHistory,
  declineRecommendation,
  deleteRecommendation,
  acceptRecommendation,
  useLocation,
  matchPath,
  useParams,
  useGetDeviceId,
};
