import { useState, useCallback } from 'react';
import type { RefObject } from 'react';

type UseCallbackRefReturn = [
  RefObject<any> | undefined,
  (n: RefObject<any>) => void
];

const useCallbackRef = (): UseCallbackRefReturn => {
  const [ref, setRef] = useState<RefObject<any>>();
  const fn = useCallback((node: RefObject<any>) => {
    setRef(node);
  }, []);

  return [ref, fn];
};

export default useCallbackRef;
