import { usePathname, useSearchParams } from 'next/navigation';

const useLocation = () => {
  const pathname = usePathname();
  const path = `/${pathname.split('/').filter(Boolean)[0]}`;
  const searchParams = useSearchParams();
  const entireSearchString = new URLSearchParams(searchParams).toString();

  return {
    pathname,
    path,
    search: !!entireSearchString ? '?'.concat(entireSearchString) : '',
  };
};

export default useLocation;
