const PROFILE_SERVICE = '/profile-service';
const API_VERSION = '/api/v1';

const profileEndpoints = {
  experience: {
    get: `${PROFILE_SERVICE}${API_VERSION}/experience`,
    post: `${PROFILE_SERVICE}${API_VERSION}/experience`,
  },
  education: {
    get: `${PROFILE_SERVICE}${API_VERSION}/education`,
    post: `${PROFILE_SERVICE}${API_VERSION}/education`,
  },
  course: {
    get: `${PROFILE_SERVICE}${API_VERSION}/course`,
    post: `${PROFILE_SERVICE}${API_VERSION}/course`,
  },
  licence: {
    get: `${PROFILE_SERVICE}${API_VERSION}/certification`,
    post: `${PROFILE_SERVICE}${API_VERSION}/certification`,
  },
  patent: {
    get: `${PROFILE_SERVICE}${API_VERSION}/patent`,
    post: `${PROFILE_SERVICE}${API_VERSION}/patent`,
  },
  publication: {
    get: `${PROFILE_SERVICE}${API_VERSION}/publication`,
    post: `${PROFILE_SERVICE}${API_VERSION}/publication`,
  },
  language: {
    get: `${PROFILE_SERVICE}${API_VERSION}/language`,
    post: `${PROFILE_SERVICE}${API_VERSION}/language`,
  },
  award: {
    get: `${PROFILE_SERVICE}${API_VERSION}/award`,
    post: `${PROFILE_SERVICE}${API_VERSION}/award`,
  },
  skill: {
    get: `${PROFILE_SERVICE}${API_VERSION}/skill`,
    post: `${PROFILE_SERVICE}${API_VERSION}/skill`,
  },
  job: {
    opportunities: `${PROFILE_SERVICE}${API_VERSION}/open-for-job-opportunities`,
  },
  privacy: `${PROFILE_SERVICE}${API_VERSION}/privacy`,
  links: `${PROFILE_SERVICE}${API_VERSION}/links`,
  workStatus: `${PROFILE_SERVICE}${API_VERSION}/work-status`,
  resumeLinkPermit: `${PROFILE_SERVICE}${API_VERSION}/resume-link-permit`,
  allowOthersToShareYourPosts: `${PROFILE_SERVICE}${API_VERSION}/set-allow-others-to-share-your-posts`,
  allowOthersToTagOrMentionYou: `${PROFILE_SERVICE}${API_VERSION}/set-allow-others-to-tag-or-mention-you`,
  getWorkingAtCompany: `${PROFILE_SERVICE}${API_VERSION}/experience/working-at-company`,
};

export default profileEndpoints;
