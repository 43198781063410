const JOB_SERVICE = '/job-service';
const JOB_SEARCH_SERVICE = '/job-search-service';

const API_VERSION = '/api/v1';

const base = `${JOB_SERVICE}${API_VERSION}`;
const searchBase = `${JOB_SEARCH_SERVICE}${API_VERSION}`;

const jobs = {
  createJob: `${base}/job`,
  getJobsList: `${base}/job`,
  getJobTemplatesGeneral: `${base}/jobs-template-business/description/general`,
  getJobTemplatesPersonal: `${base}//jobs-template-business/description/personal`,
  getJobTemplates: `${base}/jobs-template-business/description/search`,
  getBusinessJobDetails: (id: string): string => `${base}/job/${id}`,
  updateStatus: (id: string): string => `${base}/job/status/${id}`,
  availabilitycheck: `${base}/job/availability-check?status=OPEN`,
  fileUpload: `${base}/file/upload?fileType=RESUME`,
  getJobsApplicationList: `${base}/application`,
  getJobsSavedList: `${base}/saved`,
  postApplication: `${base}/application`,
  withdraw: (id: string): string => `${base}/application/withdraw/${id}`,
  saveJob: `${base}/saved`,
  unSaveJob: (id: string): string => `${base}/saved/${id}`,
  userSavedJobsAvailable: `${base}/saved/availability-check`,
  getJobsTopSuggestionList: `${searchBase}/job/top-suggestion`,
  getJobsPopularList: `${searchBase}/job/popular`,
  getAllJobs: `${searchBase}/job/all`,
  getJobsRecentSearchList: `${searchBase}/recent-search`,
  getUserJobDetails: (id: string): string => `${base}/job/view/${id}`,
  getJobOwnersLis: (id: string): string => `${base}/owner/${id}`,
  postJobOwner: (id: string): string => `${base}/owner/${id}`,
  getPopularCategories: `${searchBase}/category/popular`,
  subCategoryJobList: `${searchBase}/job/category`,
  getResume: (userId: string): string =>
    `${base}/file/latest-file/${userId}?fileType=RESUME`,
  getJobCreatorDetail: (id: string): string => `${base}/owner/associate/${id}`,
  deleteResume: (id: string): string => `${base}/application/file/${id}`,
  shareResume: `${base}/file/share/`,
  recentSearch: (id: string): string => `${searchBase}/recent-search/${id}`,
  searchUserJobs: `${searchBase}/job/search/user`,
  searchBusinessJobs: `${base}/job/search/business`,
  getSearchUserFilters: `${searchBase}/filter`,
  getSearchBusinessFilters: `${searchBase}/filter`,
  getJobCompanyInfo: (id: string): string => `${base}/job/page/${id}`,
  getCompanyFollowerPage: `${base}/jobs-page-user/follower/page`,
  getCompanyFollowerPerson: `${base}/jobs-page-user/follower/person`,
  getCompanyOnLobox: `${base}/jobs-page-user/on-lobox`,
  getWorksHere: `${base}/jobs-page-user/works-here`,
  reportJob: `${base}/jobs-report-user/report`,
  getSearchJobsSuggestions: `${searchBase}/job/suggestions`,
  searchJobsSkillsPages: `${searchBase}/job/suggest`,
  getJobsByIds: `${searchBase}/job`,
};

export default jobs;
