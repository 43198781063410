'use client';
import { useEffect } from 'react';
import { isNull } from 'lodash';

type UseBrowserBackProps = {
  callback?: () => void;
  noBack?: boolean;
};

type UseBrowserBackType = {
  neutralizeBack: () => void;
  revivalBack: () => void;
};

const useBrowserBack = ({
  callback,
  noBack,
}: UseBrowserBackProps): UseBrowserBackType => {
  const revivalBack = (): void => {
    window.onpopstate = null;
    if (!noBack) {
      window.history.back();
    }
  };

  const neutralizeBack = (): void => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      if (callback) {
        callback();
      }
      revivalBack();
    };
  };

  useEffect(
    () => () => {
      if (!isNull(window.onpopstate)) {
        revivalBack();
      }
    },
    []
  );

  return { neutralizeBack, revivalBack };
};

export default useBrowserBack;
