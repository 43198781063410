import React from 'react';

const useVisualViewportResize = (
  callback: () => void,
  fireCallbackInitially = true,
): void => {
  React.useEffect(() => {
    if (fireCallbackInitially) {
      callback();
    }
    window?.visualViewport?.addEventListener('resize', callback);

    return () => {
      window?.visualViewport?.removeEventListener('resize', callback);
    }
  }, [callback, fireCallbackInitially]);
};

export default useVisualViewportResize;
