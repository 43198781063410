import isFunction from 'lodash/isFunction';
import useStateCallback from './useStateCallback';
import preventClickHandler from '../utils/preventClickHandler';

type UsePreventClickOnDragType = {
  onTouchStart: (e: any) => void;
  onTouchEnd: (e: any) => void;
  onMouseDown: (e: any) => void;
  onMouseUp: (e: any) => void;
};

const usePreventClickOnDrag = (
  callback: Function,
  movementBoundary = 20
): UsePreventClickOnDragType => {
  if (!isFunction(callback)) {
    throw new Error('you should pass callback function');
  }

  const [pageX, setPageX] = useStateCallback(0);

  return {
    onTouchStart: (e: any) => {
      preventClickHandler(e);

      setPageX(e.nativeEvent.touches[0].pageX);
    },
    onTouchEnd: (e: any) => {
      preventClickHandler(e);

      if (
        Math.abs(e.nativeEvent.changedTouches[0].pageX - (pageX || 0)) <
        movementBoundary
      ) {
        setPageX(0, callback);
      } else {
        setPageX(0);
      }
    },
    onMouseDown: (e: any) => {
      preventClickHandler(e);

      if (e?.nativeEvent?.which === 1) {
        setPageX(e.pageX);
      }
    },
    onMouseUp: (e: any) => {
      preventClickHandler(e);

      if (Math.abs(e?.pageX - (pageX || 0)) < movementBoundary) {
        setPageX(0, callback);
      } else {
        setPageX(0);
      }
    },
  };
};

export default usePreventClickOnDrag;
