import { useRef, useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type StateFunctionType<S> = Dispatch<SetStateAction<S | undefined>>;
export type SetStateCallbackGeneric<S> = (
  x: S | StateFunctionType<S>,
  cb?: Function
) => void;

const useStateCallback = <T = any>(
  initialState?: T
): [T | undefined, SetStateCallbackGeneric<T>] => {
  const [state, setState] = useState<T | undefined>(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((newState, cb) => {
    cbRef.current = cb;
    setState(newState);
  }, []);

  useEffect(() => {
    if (typeof cbRef?.current === 'function') {
      // @ts-ignore
      cbRef?.current?.(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

export default useStateCallback;
