import request from '../utils/request';
import { invitationEndpoints } from '../constants';
import type {
  PaginateResponse,
  ContactFollowItemType,
  PaginateParamType,
} from '../types';

export const sendInvitation = async (invitationData: {
  emails: Array<string>;
}): Promise<void> => {
  const { data } = await request.post(invitationEndpoints.send, invitationData);

  return data;
};

export const getFollowList = async (
  params: PaginateParamType & {
    text?: string;
  } = {}
): Promise<PaginateResponse<Omit<ContactFollowItemType, 'status'>>> => {
  const { data } = await request.get(invitationEndpoints.followList, {
    params,
  });

  return data;
};

export const getInvitationList = async (
  params: PaginateParamType & {
    text?: string;
  } = {}
): Promise<PaginateResponse<ContactFollowItemType>> => {
  const { data } = await request.get(invitationEndpoints.invitationList, {
    params,
  });

  return data;
};

export const unsubscribe = async (unsubscribeData: {
  email: string;
  validationKey: string;
}): Promise<void> => {
  const { data } = await request.post(
    invitationEndpoints.unsubscribeAll,
    unsubscribeData
  );

  return data;
};

export default {
  sendInvitation,
  getFollowList,
  getInvitationList,
  unsubscribe,
};
