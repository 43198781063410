import request from '../utils/request';
import { jobsEndpoints } from '../constants';
import type {
  IAppliedJobData,
  ICreateJobData,
  IJob,
  IJobApi,
  IResume,
  JobStatusType,
  CompanyInfoType,
} from '../types/job';
import jobsNormalizer, { jobItemNormalizer } from '../utils/normalizers/jobs';
import type { UploadFileProps } from './file';
import type {
  PaginateParamType,
  PaginateResponse,
  PeopleType,
  UserType,
  IPage,
} from '../types';
import networkNormalizer from '../utils/normalizers/network';
import type { JobTemplatesResponse } from '../types/response';

export const createJob = async (jobData: ICreateJobData): Promise<any> => {
  const { data } = await request.post(jobsEndpoints.createJob, jobData);
  return data;
};
export const postApplication = async (
  jobData: IAppliedJobData
): Promise<any> => {
  const { data } = await request.post(jobsEndpoints.postApplication, jobData);
  return { ...data, followPage: jobData.followPage };
};

export const updateJob = async (
  jobData: ICreateJobData & { id: string }
): Promise<any> => {
  const { data } = await request.put(
    jobsEndpoints.getBusinessJobDetails(jobData.id),
    jobData
  );
  return data;
};

export const getJobsList = async ({
  params,
}: any): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<IJobApi>>(
    jobsEndpoints.getJobsList,
    {
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

export const getJobDetails = async ({
  params,
  headers,
}: any): Promise<IJob> => {
  if (!params.id) {
    throw new Error('To get job details id is required.');
  }
  const url = params?.isBusinessApp
    ? jobsEndpoints.getBusinessJobDetails
    : jobsEndpoints.getUserJobDetails;
  const { data } = await request.get<IJobApi>(url(params?.id), { headers });
  return jobsNormalizer.getJobDetails(data);
};

export const getJobCreatorDetail = async ({
  params,
}: {
  params: { id: string };
}): Promise<Partial<UserType>> => {
  const url = jobsEndpoints.getJobCreatorDetail(params?.id);
  const { data } = await request.get(url);

  return jobsNormalizer.getCreator(data);
};

export const deleteJob = async ({ id }: { id: string }): Promise<any> => {
  const url = jobsEndpoints.getBusinessJobDetails(id);
  const { data } = await request.delete(url);
  return data;
};

export const setStatus = async (jobData: {
  id: string;
  status: JobStatusType;
}): Promise<any> => {
  const { status, id } = jobData;
  const { data } = await request.put(jobsEndpoints.updateStatus(id), {
    status,
  });
  return data;
};

export const withdraw = async (jobData: { id: string }): Promise<any> => {
  const { id } = jobData;
  const { data } = await request.post(jobsEndpoints.withdraw(id));
  return data;
};

export const saveJob = async (jobData: { id: string }): Promise<any> => {
  const { data } = await request.post(jobsEndpoints.saveJob, {
    jobId: jobData.id,
  });
  return data;
};

export const unSaveJob = async (jobData: { id: string }): Promise<any> => {
  const { data } = await request.delete(jobsEndpoints.unSaveJob(jobData?.id));
  return data;
};

export const checkHasJobs = async (): Promise<boolean> => {
  const { data } = await request.get<boolean>(jobsEndpoints.availabilitycheck);
  return data;
};

export const userSavedJobsAvailable = async (): Promise<boolean> => {
  const { data } = await request.get<boolean>(
    jobsEndpoints.userSavedJobsAvailable
  );
  return data;
};

export const getJobsApplicationList = async ({
  params,
}: any): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<IJobApi>>(
    jobsEndpoints.getJobsApplicationList,
    {
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

export const getJobsSavedList = async ({
  params,
}: any): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<IJobApi>>(
    jobsEndpoints.getJobsSavedList,
    {
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

export const getJobsTopSuggestionList = async ({
  params,
  headers,
}: any): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<IJobApi>>(
    jobsEndpoints.getJobsTopSuggestionList,
    {
      headers,
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

export const getAllJobs = async ({
  params,
  headers,
}: any): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<IJobApi>>(
    jobsEndpoints.getAllJobs,
    {
      headers,
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

export const getJobsPopularList = async ({
  params,
}: any): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<IJobApi>>(
    jobsEndpoints.getJobsPopularList,
    {
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

export const getJobsRecentSearchList = async ({
  params,
}: {
  params: {
    page: number;
    pageSize: number;
  };
}): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    jobsEndpoints.getJobsRecentSearchList,
    {
      params,
    }
  );
  return data;
};

export const getSearchJobsSuggestions = async ({
  params,
}: {
  params: {
    page: number;
    pageSize: number;
  };
}): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    jobsEndpoints.getSearchJobsSuggestions,
    {
      params,
    }
  );
  return data;
};

export const uploadFile = ({
  file,
  onProgress,
}: UploadFileProps): Promise<any> => {
  const formData = new FormData();
  const extension = file.type.split('/')[1];
  const fileName = file.name ? file.name : `filename.${extension}`;
  formData.append('file', file, fileName);
  return request.post(jobsEndpoints.fileUpload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress?.(percentCompleted);
    },
  });
};

const getResume = async ({ params = {} }: any): Promise<any> => {
  const { userId } = params;
  const { data } = await request.get<any>(jobsEndpoints.getResume(userId));
  return data;
};

export const deleteResume = async ({
  id,
}: {
  id: string;
}): Promise<IResume> => {
  const url = jobsEndpoints.deleteResume(id);
  const { data } = await request.delete(url);
  return data;
};

export const removeRecentSearch = async ({
  id,
}: {
  id: string;
}): Promise<any> => {
  const url = jobsEndpoints.recentSearch(id);
  const { data } = await request.delete(url);
  return data;
};

export const removeAllRecentSearch = async (): Promise<any> => {
  const url = jobsEndpoints.getJobsRecentSearchList;
  const { data } = await request.delete(url);
  return data;
};

const getJobTemplates = async ({
  params,
  headers,
}: any): Promise<JobTemplatesResponse<any>> => {
  const { data } = await request.get<JobTemplatesResponse<any>>(
    jobsEndpoints.getJobTemplates,
    {
      params,
      headers,
    }
  );
  return data;
};
const getJobTemplatesGeneral = async ({
  params,
  headers,
}: any): Promise<JobTemplatesResponse<any>> => {
  const { data } = await request.get<JobTemplatesResponse<any>>(
    jobsEndpoints.getJobTemplatesGeneral,
    {
      params,
      headers,
    }
  );
  return data;
};

const getJobTemplatesPersonal = async ({
  params,
  headers,
}: any): Promise<JobTemplatesResponse<any>> => {
  const { data } = await request.get<JobTemplatesResponse<any>>(
    jobsEndpoints.getJobTemplatesPersonal,
    {
      params,
      headers,
    }
  );
  return data;
};

const getPopularCategories = async ({
  params,
  headers,
}: any): Promise<PaginateResponse<any>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    jobsEndpoints.getPopularCategories,
    {
      params,
      headers,
    }
  );
  return jobsNormalizer.getPopularCategories(data);
};

const subCategoryJobList = async ({
  params,
}: {
  params: {
    categoryId: string;
    page: number;
    pageSize: number;
  };
}): Promise<PaginateResponse<any>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    jobsEndpoints.subCategoryJobList,
    {
      params,
    }
  );
  return jobsNormalizer.getJobsList(data);
};

const getOwnersLis = async ({
  params,
}: any): Promise<Array<Partial<UserType>>> => {
  const { data } = await request.get<Array<any>>(
    jobsEndpoints.getJobOwnersLis(params?.id)
  );
  return jobsNormalizer.getOwners(data);
};

export const postOwner = async (jobData: {
  userId: string;
  jobId: string;
}): Promise<any> => {
  const { data } = await request.post(
    jobsEndpoints.postJobOwner(jobData.jobId),
    {
      userId: jobData.userId,
    }
  );
  return data;
};

export const removeOwner = async (jobData: {
  userId: string;
  jobId: string;
}): Promise<any> => {
  const url = jobsEndpoints.postJobOwner(jobData.jobId);
  const { data } = await request.delete(url, { userId: jobData.userId });
  return data;
};

export const searchJob = async ({
  params,
}: any): Promise<PaginateResponse<IJob>> => {
  const url = params?.isBusinessApp
    ? jobsEndpoints.searchBusinessJobs
    : jobsEndpoints.searchUserJobs;

  const { data } = await request.get<PaginateResponse<IJobApi>>(url, {
    params,
  });

  return jobsNormalizer.getJobsList(data);
};
export const searchJobsSkillsPages = async ({
  params,
}: any): Promise<any[]> => {
  const url = jobsEndpoints.searchJobsSkillsPages;

  const { data } = await request.get<any[]>(url, {
    params,
  });

  return jobsNormalizer.searchJobsSkillsPages(data);
};

export const getSearchFilters = async ({ params }: any): Promise<any> => {
  const url = params?.isBusinessApp
    ? jobsEndpoints.getSearchBusinessFilters
    : jobsEndpoints.getSearchUserFilters;
  const { data } = await request.get<any>(url, {
    params,
  });
  return jobsNormalizer.getSearchFilters(data);
};

export const getJobCompanyInfo = async ({
  params,
}: any): Promise<CompanyInfoType> => {
  if (!params.jobId) {
    throw new Error('To get company info  id is required.');
  }

  const { data } = await request.get<any>(
    jobsEndpoints.getJobCompanyInfo(params.jobId)
  );
  return jobsNormalizer.getJobCompanyInfo(data);
};

export const getCompanyFollowerPerson = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(jobsEndpoints.getCompanyFollowerPerson, {
    params,
  });

  return networkNormalizer.suggestPeople(data);
};

export const getCompanyFollowerPage = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<IPage>> => {
  const { data } = await request.get(jobsEndpoints.getCompanyFollowerPage, {
    params,
  });

  return networkNormalizer.suggestPages(data);
};

export const reportJob = async ({
  jobId,
  title,
  description,
}: {
  jobId: string;
  title: string;
  description: string;
}): Promise<any> => {
  const { data } = await request.post(jobsEndpoints.reportJob, {
    jobId,
    title,
    description,
  });
  return data;
};

const shareResume = async (params: {
  fileId: number;
  userIds: number[];
}): Promise<any> => {
  const { data } = await request.post(jobsEndpoints.shareResume, params);

  return data;
};

export const getJobsByIds = async ({
  params,
}: {
  params: {
    ids: Array<string>;
  };
}): Promise<any> => {
  const { data } = await request.get(jobsEndpoints.getJobsByIds, {
    params,
  });
  return data?.map(jobItemNormalizer);
};

export default {
  getJobTemplates,
  getJobTemplatesGeneral,
  getJobTemplatesPersonal,
  postApplication,
  createJob,
  getJobsList,
  getJobDetails,
  updateJob,
  deleteJob,
  setStatus,
  checkHasJobs,
  getJobsApplicationList,
  getJobsTopSuggestionList,
  getJobsPopularList,
  getJobsRecentSearchList,
  uploadFile,
  getJobsSavedList,
  withdraw,
  saveJob,
  unSaveJob,
  getPopularCategories,
  subCategoryJobList,
  getResume,
  shareResume,
  deleteResume,
  removeAllRecentSearch,
  removeRecentSearch,
  getOwnersLis,
  postOwner,
  removeOwner,
  getJobCreatorDetail,
  userSavedJobsAvailable,
  searchJob,
  getSearchFilters,
  getJobCompanyInfo,
  getCompanyFollowerPerson,
  getCompanyFollowerPage,
  getAllJobs,
  reportJob,
  getSearchJobsSuggestions,
  searchJobsSkillsPages,
  getJobsByIds,
};
