import request from '../utils/request';
import { notificationEndpoints } from '../constants/servicesEndpoints';

import type {
  PaginateResponse,
  INotification,
  NotificationType,
  NotificationSettingType,
} from '../types';

export const getNotifications = async (
  params: any
): Promise<PaginateResponse<INotification>> => {
  const { data } = await request.get<PaginateResponse<INotification>>(
    notificationEndpoints.all,
    {
      params,
    }
  );
  return data;
};

export const getMyPagesNotifications = async ({
  params = { pageIds: '' },
}: { params: { pageIds: string } }): Promise<any> => {
  const { pageIds } = params;
  const { data } = await request.get(notificationEndpoints.getMyPagesNotifications, {
    params: { pageIds },
  });

  return data;
};

interface IUnSeenCount {
  lastOpenedDate: Date;
  unSeenCount: number;
}

export const getUnSeenNotificationsCount = async (): Promise<IUnSeenCount> => {
  const { data } = await request.get<IUnSeenCount>(
    notificationEndpoints.unSeenCount
  );
  return data;
};

export const getNotificationSetting = async (): Promise<
  Array<NotificationSettingType>
> => {
  const { data } = await request.get(notificationEndpoints.setting);

  return data;
};

export const setNotificationSetting = async ({
  ...settingData
}: {
  email: boolean;
  notificationType: NotificationType;
  push: boolean;
}): Promise<void> => {
  const { data } = await request.post(
    notificationEndpoints.setting,
    settingData
  );
  return data;
};

export const seenNotification = async ({
  notificationId,
  isSeen,
}: {
  notificationId: string;
  isSeen: boolean;
}): Promise<any> => {
  const url = isSeen
    ? notificationEndpoints.seenOne(notificationId)
    : notificationEndpoints.unseenOne(notificationId);

  const { data } = await request.post(url);
  return data;
};

export const seenAllNotification = async (): Promise<any> => {
  const url = notificationEndpoints.seenAll;
  const { data } = await request.post(url);
  return data;
};

export const setNotificationsOpened = async (): Promise<any> => {
  const url = notificationEndpoints.opened;
  const { data } = await request.post(url);
  return data;
};

export const deleteNotification = async ({
  notificationId,
}: {
  notificationId: string;
}): Promise<any> => {
  const url = notificationEndpoints.delete(notificationId);
  const { data } = await request.delete(url, {
    params: {
      id: notificationId,
    },
  });
  return data;
};

export default {
  seenNotification,
  deleteNotification,
  seenAllNotification,
  getNotifications,
  getUnSeenNotificationsCount,
  getNotificationSetting,
  setNotificationSetting,
  getMyPagesNotifications,
};
