import type { PaginateResponse } from '../../types';
import type {
  ISearchHashtag,
  ISearchHashtagApi,
  ISearchPage,
  ISearchPageApi,
  ISearchPeople,
  ISearchPeopleApi,
  ISearchPostApi,
  ISuggestHashtag,
  ISuggestHashtagApi,
  ISuggestObject,
  ISuggestObjectApi,
  ISearchPost,
} from '../../types/search';
import { APP_ENTITIES } from '../../constants';
import { addPrefix } from '../addPrefix';

const itemNormalizer = (curr: any) => {
  if (!curr) return null;
  return {
    ...curr,
    ...curr.networkModel,
    type: curr.userType,
    fullName:
      curr.userType === 'PAGE'
        ? (curr.title as string)
        : `${curr.name} ${curr.surname}`,
    subTitle:
      curr.userType === 'PAGE' ? curr.industryName : curr.occupationName,
  };
};
const searchPersonNormalizer = (
  data: PaginateResponse<ISearchPeopleApi>
): PaginateResponse<ISearchPeople> => ({
  ...data,
  content: data.content.reduce(
    (prev: Array<ISearchPeople>, curr: ISearchPeopleApi) => {
      if (curr.hideIt) {
        return prev;
      }
      return [...prev, itemNormalizer(curr)];
    },
    []
  ),
  currentEntity: itemNormalizer(data?.currentEntity),
});

const searchObjectNormalizer = (
  data: PaginateResponse<ISuggestObjectApi>
): PaginateResponse<ISuggestObject> => ({
  ...data,
  content: data.content.reduce(
    (prev: Array<ISuggestObject>, curr: ISuggestObjectApi) => {
      if (curr.hideIt) {
        return prev;
      }
      return [
        ...prev,
        {
          ...curr,
          type: curr.userType,
          avatar: curr.croppedImageUrl,
          fullName:
            curr.userType === 'PAGE'
              ? (curr.title as string)
              : `${curr.name} ${curr.surname}`,
          subTitle:
            curr.userType === 'PAGE'
              ? curr.industryName
              : (curr.occupationName as any),
          usernameAtSign: `@${curr.username}`,
          isPage: curr.userType === 'PAGE',
        },
      ];
    },
    []
  ),
});

const suggestHashtagsNormalizer = (
  data: PaginateResponse<ISuggestHashtagApi>
): PaginateResponse<ISuggestHashtag> => ({
  ...data,
  content: data.content.map((item) => ({ title: item, type: 'HASHTAG' })),
});

const singlePageNormalizer = (curr: any) => {
  if (!curr) return null;
  return { ...curr, ...curr.networkModel, type: 'PAGE' };
};

const searchPageNormalizer = (
  data: PaginateResponse<ISearchPageApi>
): PaginateResponse<ISearchPage> => ({
  ...data,
  content: data.content.reduce((prev: Array<ISearchPage>, curr: any) => {
    if (curr.hideIt) {
      return prev;
    }
    return [...prev, singlePageNormalizer(curr)];
  }, []),
  currentEntity: singlePageNormalizer(data?.currentEntity),
});

const searchHashtagsNormalizer = (
  data: PaginateResponse<ISearchHashtagApi>
): PaginateResponse<ISearchHashtag> => ({
  ...data,
  content: data.content.map((item) => ({
    ...item,
    title: item.id,
    type: 'HASHTAG',
  })),
});

const searchPostsNormalizer = (
  data: PaginateResponse<ISearchPostApi>
): PaginateResponse<ISearchPost> => ({
  ...data,
  content: data.content.map((item) => ({
    ...item,
    type: 'POST',
  })),
});

const objectDetailByIdNormalizer = (
  data: Array<ISuggestObjectApi>
): { [key: string]: ISuggestObject } =>
  data?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: {
        ...curr,
        type: curr.userType,
        fullName:
          curr.userType === 'PAGE'
            ? (curr.title as string)
            : `${curr.name} ${curr.surname}`,
        subTitle:
          curr.userType === 'PAGE' ? curr.industryName : curr.occupationName,
      },
    }),
    {}
  );

const personFilters = (data: any): any => ({
  pages: data?.companies?.map((page: any) => ({
    value: page.id,
    label: page.title,
    image: page.croppedImageUrl,
    helperText: `@${page?.username}`,
  })),
  relatedPages: data?.pages?.map((page: any) => ({
    value: page.id,
    label: page.name,
    helperText: `@${page?.username}`,
  })),
  industries: data?.industries?.map((category: any) => ({
    value: category.id,
    label: category.title,
    image: category.imageUrl,
  })),
  languages: data?.languages
    ?.map((skill: any) => ({
      value: skill.languageId,
      label: skill.title,
    }))
    ?.filter((item: any) => !!item?.value && !!item?.label),
  cities: data.cities?.map((city: any) => ({
    label: city?.name,
    value: city?.code,
  })),
  schools: data.schools?.map((school: any) => ({
    label: school?.title,
    value: school?.id,
  })),
  titles: data?.occupationNames?.map?.((title: string) => ({
    value: title,
    label: title,
  })),
  educationDegrees: data?.educationDegrees?.map?.((title: string) => ({
    value: title,
    label: title,
  })),
  creators: data?.owners?.map((creator: any) => ({
    value: creator.id,
    label: creator.title,
    isPage: creator.type === APP_ENTITIES.page,
    helperText: `@${creator?.username}`,
  })),
  companySizes: data?.companySizes?.sort()?.map((companySize: any) => ({
    value: companySize,
    label: `COMPANY_SIZE_${companySize}`,
  })),
  pageTypes: data?.categories?.map((category: string) => ({
    value: category,
    label: `COMPANY_TYPE_${category}`,
  })),
  hashtags: data?.hashtags?.map?.((title: string) => ({
    value: title,
    label: `#${title}`,
  })),
  postedBy: data?.postedBy?.map?.((title: string) => ({
    value: title,
    label: addPrefix(title),
  })),
  postTypes: data?.types?.map?.((title: string) => ({
    value: title,
    label: addPrefix(title),
  })),
  datePosted: data?.datePosted?.map?.((title: string) => ({
    value: title,
    label: addPrefix(title),
  })),
  sortBy: data?.sortBy?.map?.((title: string) => ({
    value: title,
    label: addPrefix(title),
  })),
  memberSince: data?.memberSince?.map?.((title: string) => ({
    value: title,
    label: addPrefix(title),
  })),
  establishmentDate: data?.establishmentDate?.map?.((title: string) => ({
    value: title,
    label: addPrefix(title),
  })),
  mentions: data?.mentions?.map((creator: any) => ({
    value: creator.id,
    label: creator.title,
    isPage: creator.type === APP_ENTITIES.page,
    helperText: `@${creator?.username}`,
  })),
});

const searchNormalizer = {
  person: searchPersonNormalizer,
  object: searchObjectNormalizer,
  suggestHashtags: suggestHashtagsNormalizer,
  page: searchPageNormalizer,
  hashtag: searchHashtagsNormalizer,
  post: searchPostsNormalizer,
  objectDetailById: objectDetailByIdNormalizer,
  personFilters,
};

export default searchNormalizer;
