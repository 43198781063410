import request from '../utils/request';
import Endpoints from '../constants/endpoints';
import { postEndpoints } from '../constants/servicesEndpoints';
import factorFeeds from '../utils/normalizers/factorFeeds';

import type {
  CreatePostProps,
  Response,
  PaginateResponse,
  IFeedElement,
  CollectionType,
  CreateCollectionProps,
} from '../types';

export const getFeedList = async (
  params: any
): Promise<PaginateResponse<IFeedElement>> => {
  const { data } = await request.get<PaginateResponse<IFeedElement>>(
    Endpoints.App.Post.feedList,
    {
      params,
    }
  );
  return factorFeeds(data);
};

export const getFeedListByHashtag = async (
  params: any
): Promise<PaginateResponse<IFeedElement>> => {
  const { hashtag, ...rest } = params || {};
  const { data } = await request.get<PaginateResponse<IFeedElement>>(
    Endpoints.App.Post.feedListByHashtag,
    {
      params: {
        hashtag: hashtag?.toLowerCase(),
        ...rest,
      },
    }
  );
  return factorFeeds(data);
};

export const getUserAllPosts = async ({
  page = 0,
  userId,
  size,
}: any): Promise<PaginateResponse<IFeedElement>> => {
  const { data } = await request.get<PaginateResponse<IFeedElement>>(
    postEndpoints.userAllPosts,
    {
      params: {
        userId,
        size,
        page,
      },
    }
  );
  return factorFeeds(data);
};
export const getUserFeedList = async ({
  page = 0,
  userId,
  size,
}: any): Promise<PaginateResponse<IFeedElement>> => {
  const { data } = await request.get<PaginateResponse<IFeedElement>>(
    postEndpoints.userPosts,
    {
      params: {
        userId,
        size,
        page,
      },
    }
  );
  return factorFeeds(data);
};
export const getUserHighlights = async ({
  page = 0,
  userId,
  size,
}: any): Promise<PaginateResponse<IFeedElement>> => {
  const { data } = await request.get<PaginateResponse<IFeedElement>>(
    postEndpoints.userHighlights,
    {
      params: {
        userId,
        size,
        page,
      },
    }
  );
  return factorFeeds(data);
};
export const createPost = async ({
  postData,
}: {
  postData: CreatePostProps;
}): Promise<any> => {
  const { data } = await request.post(postEndpoints.create, postData);
  return data;
};
export const editPost = async ({
  id,
  postData,
}: {
  id: string;
  postData: CreatePostProps;
}): Promise<any> => {
  const url = postEndpoints.edit(id);
  const { data } = await request.put(url, postData);
  return data;
};

export const deletePost = async ({ id }: { id: string }): Promise<any> => {
  const url = postEndpoints.deletePost(id);
  const { data } = await request.delete(url);
  return data;
};
export const savePostToCollection = async ({
  collectionId,
  postId,
}: {
  postId: string;
  collectionId: string;
}): Promise<any> => {
  const url = postEndpoints.collection.savePostTo(collectionId);
  const { data } = await request.post(url, {
    postId,
  });
  return data;
};

export const addReaction = async ({
  commentId,
  postId,
  type,
}: {
  commentId: string;
  postId: string;
  type: string;
}): Promise<any> => {
  const url = postEndpoints.reaction;
  const { data } = await request.post(url, {
    postId,
    commentId,
    type,
  });
  return data;
};
export const togglePostNotifications = async ({
  postId,
  off,
}: {
  postId: string;
  off: boolean;
}): Promise<any> => {
  const url = postEndpoints.toggleNotifications;
  const { data } = await request.post(url, {
    postId,
    off,
  });
  return data;
};
export const hidePost = async ({
  postId,
}: {
  postId: string;
}): Promise<any> => {
  const url = postEndpoints.hide(postId);
  const { data } = await request.post(url);
  return data;
};
export const unHidePost = async ({
  postId,
}: {
  postId: string;
}): Promise<any> => {
  const url = postEndpoints.unHide(postId);
  const { data } = await request.post(url);
  return data;
};
export const removeReaction = async ({
  commentId,
  postId,
}: {
  commentId: string;
  postId: string;
}): Promise<any> => {
  const url = postEndpoints.reaction;
  const { data } = await request.delete(url, {
    postId,
    commentId,
  });
  return data;
};

export const getPostActionsStatus = async ({
  postId,
}: {
  postId: string;
}): Promise<Response<any>> => {
  const { data } = await request.get<Response<any>>(
    postEndpoints.menuItemsStatus(postId)
  );
  return data;
};

export const deletePostFromCollection = async ({
  collectionId,
  postId,
}: any): Promise<any> => {
  const url = postEndpoints.collection.deletePostFrom(collectionId);
  const { data } = await request.delete(url, postId);
  return data;
};

interface ReactionsProps {
  commentId: 0;
  id: 0;
  ownerId: 0;
  ownerPageInfo: {
    category: 'BASIC';
    croppedHeaderImageUrl: 'string';
    croppedImageUrl: 'string';
    headerImageUrl: 'string';
    id: 0;
    imageUrl: 'string';
    industryLookupId: 0;
    industryName: 'string';
    status: 'UNPUBLISHED';
    title: 'string';
    username: 'string';
  };
  ownerProfileInfo: {
    croppedImageUrl: 'string';
    imageUrl: 'string';
    name: 'string';
    occupationId: 0;
    occupationName: 'string';
    privateProfile: true;
    surname: 'string';
    userId: 0;
    username: 'string';
  };
  ownerUserType: 'PERSON';
  postId: 0;
  type: 'LIKE';
}

export const getPostOrCommentReactions = async (
  params: any
): Promise<PaginateResponse<ReactionsProps>> => {
  const { data } = await request.get<PaginateResponse<ReactionsProps>>(
    postEndpoints.getReactions,
    {
      params,
    }
  );
  return data;
};

export const updateCollection = async ({
  collectionId,
  ...body
}: CreateCollectionProps & {
  collectionId: string;
}): Promise<CollectionType> => {
  const { data } = await request.put(
    postEndpoints.collection.item(collectionId),
    body
  );

  return data;
};

export const deleteCollection = async ({
  collectionId,
}: {
  collectionId: string;
}): Promise<void> => {
  const { data } = await request.delete(
    postEndpoints.collection.item(collectionId)
  );

  return data;
};

export default {
  editPost,
  getUserAllPosts,
  getUserFeedList,
  getUserHighlights,
  deletePostFromCollection,
  getPostActionsStatus,
  getPostOrCommentReactions,
  createPost,
  getFeedList,
  deletePost,
  savePostToCollection,
  addReaction,
  removeReaction,
  hidePost,
  unHidePost,
  updateCollection,
  deleteCollection,
  togglePostNotifications,
};
