import request from '../utils/request';
import { statisticsEndpoints } from '../constants';
import type {
  CountyType,
  DiagramType,
  PaginateResponse,
  TopPost,
  AppEntitiesType,
  IFeedElement,
} from '../types';
import factorFeeds from '../utils/normalizers/factorFeeds';

export const getViewCount = async ({
  params,
}: {
  params?: {
    from?: string;
  };
} = {}): Promise<CountyType> => {
  const { data } = await request.get(statisticsEndpoints.counter, {
    params,
  });

  return data;
};

export const getPostInteractionsCount = async ({
  params,
}: {
  params?: {
    from?: string;
  };
} = {}): Promise<CountyType> => {
  const { data } = await request.get(statisticsEndpoints.counter, {
    params,
  });

  return data;
};

export const getFollowDiagram = async ({
  params,
}: {
  params?: {
    from: string;
    compareFrom: string;
    interval: string;
    userType?: AppEntitiesType;
  };
} = {}): Promise<DiagramType> => {
  const { data } = await request.get(statisticsEndpoints.diagram.follow, {
    params,
  });

  return data;
};

export const getViewDiagram = async ({
  params,
}: {
  params?: {
    from: string;
    compareFrom: string;
    interval: string;
    userType?: AppEntitiesType;
  };
} = {}): Promise<DiagramType> => {
  const { data } = await request.get(statisticsEndpoints.diagram.view, {
    params,
  });

  return data;
};

export const getTopPosts = async ({
  params,
}: {
  params?: {
    offset?: string;
    page?: number;
    size?: number;
    paged?: boolean;
    sort?: {
      sorted?: boolean;
      unsorted?: boolean;
    };
    unpaged?: boolean;
  };
} = {}): Promise<PaginateResponse<IFeedElement>> => {
  const { data } = await request.get(statisticsEndpoints.topPosts, {
    params,
  });
  return factorFeeds(
    {
      ...data,
      content: data?.content?.map((item: any) => ({
        post: item,
        postId: item?.id,
        activities: [],
      })),
    },
    true
  );
};

export default {
  getViewCount,
  getPostInteractionsCount,
  getFollowDiagram,
  getViewDiagram,
  getTopPosts,
};
