const translateReplacer = (
  string: string,
  words: string | string[]
): string => {
  const regex = /{\w+}/g;
  let index = 0;

  return string.replace(regex, () => {
    const replacement = Array.isArray(words) ? words[index] || '' : words; // Use the word at the corresponding index or an empty string
    // eslint-disable-next-line no-plusplus
    index++;
    return replacement;
  });
};

export default translateReplacer;
