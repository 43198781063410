import request from '../utils/request';
import { postEndpoints } from '../constants/servicesEndpoints';
import type { CreateCommentProps, Comment, PaginateResponse } from '../types';

// get comments of a post
export const getPostComments = async ({
  page = 0,
  postId,
  size,
}: any): Promise<PaginateResponse<Comment>> => {
  const { data } = await request.get<PaginateResponse<Comment>>(
    postEndpoints.comment.list,
    {
      params: {
        postId,
        size,
        page,
      },
    }
  );
  return data;
};

// add new comment for a post
export const addComment = async ({
  commentData,
}: {
  commentData: CreateCommentProps;
}): Promise<any> => {
  const URL = commentData.commentId
    ? postEndpoints.comment.reply
    : postEndpoints.comment.add;
  const { data } = await request.post(URL, commentData);
  return data;
};

// delete comment from a post by commentID
export const deleteComment = async ({ id }: { id: string }): Promise<any> => {
  const url = postEndpoints.comment.delete(id);
  const { data } = await request.delete(url);
  return data;
};

// add new comment for a post
export const editComment = async ({ id, req }: any): Promise<any> => {
  const URL = postEndpoints.comment.edit(id);
  const { data } = await request.put(URL, { ...req });
  return { ...data, edited: true };
};

// get replies of a comment
export const getCommentReplies = async ({
  commentId,
  page = 0,
  size,
}: any): Promise<PaginateResponse<Comment>> => {
  const { data } = await request.get<PaginateResponse<Comment>>(
    postEndpoints.comment.replies,
    {
      params: {
        commentId,
        size,
        page,
      },
    }
  );
  return data;
};

export const hideComment = async ({
  commentId,
}: {
  commentId: string;
}): Promise<any> => {
  const url = postEndpoints.comment.hide(commentId);
  const { data } = await request.post(url);
  return data;
};

export const unHideComment = async ({
  commentId,
}: {
  commentId: string;
}): Promise<any> => {
  const url = postEndpoints.comment.unHide(commentId);
  const { data } = await request.post(url);
  return data;
};

export default {
  hideComment,
  getPostComments,
  addComment,
  editComment,
  deleteComment,
  getCommentReplies,
};
