import type { IFeedElement, PaginateResponse } from '../../types';

type FactorFeedType = PaginateResponse<IFeedElement>;

const createFeedContent = (feed: IFeedElement) => {
  if (!feed) return null;
  return {
    ...feed,
    id: feed.post.id,
  };
};

const factorFeed = (
  data: FactorFeedType,
  hideShared?: boolean
): FactorFeedType => ({
  ...data,
  //@ts-ignore
  content: data.content
    .map((x) =>
      x.post.hideIt || (hideShared && !!x.post?.sharedOf)
        ? undefined
        : createFeedContent(x)
    )
    .filter(Boolean),
  currentEntity: createFeedContent(data?.currentEntity),
});

export default factorFeed;
