import type { IAttachment } from '../../types/attachment';
import type { ShareEntityTab } from '../../types/share';
import makePostMediaFromFiles from '../../utils/makePostMediaFromFiles';
// @ts-nocheck
type MediasType = Array<{ type: 'image' | 'video'; path: string; id: string }>;

export const initialState = {
  isOpenChatPanel: false,
  isOpenLeftPanel: false,
  isOpenRightPanel: false,
  isOpenProfilePanel: false,
  isOpenNotificationModal: false,
  activeRoom: undefined,
  selectedHashtag: undefined,
  isGlobalEntityModal: false,
  isDrawerOpen: false,
  visibleLeavePageConfirm: true,
  isSettingModalOpen: false,
  isFeedbackModalOpen: false,
  isTakeABreakModalOpen: false,
  defaultSettingsExpanded: 0,
  isRecommendationOpenedFromMessage: false,
  parentPathname: '',
  uploadingPostFiles: false,
  createPostProgress: [] as number[],
  createPostModal: {
    isOpenModal: false,
    currentTab: 'main',
    files: null,
    attachment: null,
    defaultActiveH: undefined,
    form: undefined,
    isPrevStepMainTab: undefined,
  },
  createdPostData: {
    data: {},
    sender: '',
  },
  objectNetworkModal: {
    isOpen: false,
    currentTab: null,
    objectId: null,
    objectTitle: null,
    network: null,
    isPage: null,
  },
  reportModal: {
    isOpen: false,
    data: undefined,
  },
  shareEntityModal: {
    isOpen: false,
    data: undefined,
  },
  viewMedia: {
    isOpen: false,
    medias: [],
    selectedItem: 0,
  },
  mutualModal: {
    isOpen: undefined,
    data: undefined,
  },
  shareEntityTabbedModal: {
    isOpen: false,
    entityData: { attachment: {} as IAttachment },
    tabs: [] as ShareEntityTab[],
  },
  isOpenCreateJobModalInUserProject: false,
  profileHistoryCount: 0,
  scheduleHistoryCount: 0,
  scheduleEventHistoryCount: 0,
  searchHistoryCount: 0,
  isScheduleSubPage: false,
  isSearchSubPage: false,
  isSearchStartedFromMain: false,
  profilePanelState: {},
};
export type ReportEntityType =
  | 'user'
  | 'page'
  | 'post'
  | 'comment'
  | 'meeting'
  | 'job'
  | 'message';

export type ActionType =
  | { type: 'OPEN_MESSAGE_PANEL' }
  | { type: 'CLOSE_MESSAGE_PANEL' }
  | { type: 'OPEN_LEFT_PANEL' }
  | { type: 'CLOSE_LEFT_PANEL' }
  | { type: 'SET_DEFAULT_ACTIVE_ROOM'; payload: { room: any } }
  | { type: 'OPEN_OBJECT_NETWORK_MODAL'; payload: any }
  | { type: 'PARENT_PATHNAME'; payload: string }
  | { type: 'CLOSE_OBJECT_NETWORK_MODAL' }
  | {
      type: 'TOGGLE_REPORT_MODAL';
      payload: {
        isOpen: boolean;
        data?: {
          entityType: ReportEntityType;
          entityId: string;
        };
      };
    }
  | {
      type: 'TOGGLE_SHARE_ENTITY_MODAL';
      payload: {
        isOpen: boolean;
        data?: {
          onClose: () => void;
          entity: 'post' | 'profile' | 'resume';
          shareObject: any;
        };
      };
    }
  | {
      type: 'TOGGLE_IS_OPENED_RECOMMENDATION_FROM_MESSAGE';
      payload: {
        isOpened: boolean;
      };
    }
  | { type: 'TOGGLE_GLOBAL_ENTITY_MODAL' }
  | { type: 'CLOSE_GLOBAL_ENTITY_MODAL' }
  | { type: 'TOGGLE_SETTINGS_MODAL' }
  | { type: 'TOGGLE_FEEDBACK_MODAL' }
  | { type: 'TOGGLE_TAKE_A_BREAK_MODAL' }
  | { type: 'SHOW_LEAVE_CREATE_PAGE_CONFIRM' }
  | { type: 'HIDE_LEAVE_CREATE_PAGE_CONFIRM' }
  | { type: 'CLOSE_VIEW_MEDIA' }
  | {
      type: 'OPEN_VIEW_MEDIA';
      payload: { medias: MediasType; selectedItem: number };
    }
  | {
      type: 'SET_SETTINGS_DEFAULT_EXPANDED';
      payload: { defaultSettingsExpanded: number };
    }
  | {
      type: 'SET_SHOW_RIGHT_PANEL';
      payload: boolean;
    }
  | {
      type: 'SET_IS_OPEN_NOTIFICATION_PANEL';
      payload: boolean;
    }
  | {
      type: 'SET_SHOW_PROFILE_PANEL';
      payload: boolean | ProfilePanelStateType;
    }
  | { type: 'TOGGLE_MUTUAL_MODAL'; payload: any }
  | { type: 'SET_UPLOADING_POST_FILES'; payload: boolean }
  | {
      type: 'SET_CREATE_POST_PROGRESS';
      payload: { index: number; value: number };
    }
  | { type: 'RESET_CREATE_POST_PROGRESS' }
  | { type: 'SET_CREATE_POST_MODAL'; payload: any }
  | { type: 'SET_CREATED_POST_DATA'; payload: { sender: string; data: any } }
  | { type: 'RESET_CREATED_POST_DATA' }
  | {
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA';
      payload: Partial<StateType['shareEntityTabbedModal']>;
    }
  | {
      type: 'TOGGLE_CREATE_JOB_MODAL_IN_USER_PROJECT';
      payload: boolean;
    }
  | {
      type: 'ADD_PROFILE_HISTORY_COUNT';
    }
  | {
      type: 'RESET_PROFILE_HISTORY_COUNT';
    }
  | {
      type: 'RESET_CREATE_POST_MODAL';
    }
  | {
      type: 'ADD_SCHEDULE_HISTORY_COUNT';
    }
  | {
      type: 'SET_SCHEDULE_HISTORY_COUNT';
      payload: number;
    }
  | {
      type: 'RESET_SCHEDULE_HISTORY_COUNT';
    }
  | {
      type: 'ADD_SCHEDULE_EVENT_HISTORY_COUNT';
    }
  | {
      type: 'RESET_SCHEDULE_EVENT_HISTORY_COUNT';
    }
  | {
      type: 'SET_SCHEDULE_SUBPAGE';
    }
  | {
      type: 'RESET_SCHEDULE_SUBPAGE';
    }
  | {
      type: 'ADD_SEARCH_HISTORY_COUNT';
    }
  | {
      type: 'SET_SEARCH_HISTORY_COUNT';
      payload: number;
    }
  | {
      type: 'RESET_SEARCH_HISTORY_COUNT';
    }
  | {
      type: 'SET_SEARCH_SUBPAGE';
    }
  | {
      type: 'RESET_SEARCH_SUBPAGE';
    }
  | {
      type: 'SET_SEARCH_STARTED_FROM_MAIN';
    }
  | {
      type: 'RESET_SEARCH_STARTED_FROM_MAIN';
    };

export interface ProfilePanelStateType {
  settings?: {
    preferences?: {
      schedule?: {
        availability?: boolean;
        calendar?: boolean;
      };
    };
  };
}

export type StateType = typeof initialState & {
  profilePanelState: ProfilePanelStateType;
};

export function globalReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'OPEN_MESSAGE_PANEL':
      return {
        ...state,
        isOpenChatPanel: true,
      };
    case 'CLOSE_MESSAGE_PANEL':
      return {
        ...state,
        isOpenChatPanel: false,
      };
    case 'OPEN_LEFT_PANEL':
      return {
        ...state,
        isOpenLeftPanel: true,
      };
    case 'CLOSE_LEFT_PANEL':
      return {
        ...state,
        isOpenLeftPanel: false,
      };
    case 'TOGGLE_GLOBAL_ENTITY_MODAL':
      return {
        ...state,
        isGlobalEntityModal: !state.isGlobalEntityModal,
      };
    case 'CLOSE_GLOBAL_ENTITY_MODAL':
      return {
        ...state,
        isGlobalEntityModal: false,
      };
    case 'TOGGLE_SETTINGS_MODAL':
      return {
        ...state,
        isSettingModalOpen: !state.isSettingModalOpen,
      };
    case 'TOGGLE_FEEDBACK_MODAL':
      return {
        ...state,
        isFeedbackModalOpen: !state.isFeedbackModalOpen,
      };
    case 'TOGGLE_TAKE_A_BREAK_MODAL':
      return {
        ...state,
        isTakeABreakModalOpen: !state.isTakeABreakModalOpen,
      };
    case 'OPEN_OBJECT_NETWORK_MODAL':
      return {
        ...state,
        objectNetworkModal: {
          isOpen: true,
          ...(action.payload || {}),
        },
      };
    case 'PARENT_PATHNAME':
      return {
        ...state,
        parentPathname: action.payload,
      };
    case 'CLOSE_OBJECT_NETWORK_MODAL':
      return {
        ...state,
        objectNetworkModal: {
          isOpen: false,
          currentTab: null,
          objectId: null,
          objectTitle: null,
          network: null,
          isPage: null,
        },
      };
    case 'SHOW_LEAVE_CREATE_PAGE_CONFIRM':
      return {
        ...state,
        visibleLeavePageConfirm: true,
      };
    case 'SET_DEFAULT_ACTIVE_ROOM':
      return {
        ...state,
        activeRoom: action.payload.room,
      };
    case 'SET_SHOW_RIGHT_PANEL':
      return {
        ...state,
        isOpenRightPanel: action.payload,
      };
    case 'SET_IS_OPEN_NOTIFICATION_PANEL':
      return {
        ...state,
        isOpenNotificationModal: action.payload,
      };
    case 'SET_SHOW_PROFILE_PANEL':
      return {
        ...state,
        ...(typeof action.payload === 'boolean'
          ? { isOpenProfilePanel: action.payload, profilePanelState: {} }
          : { isOpenProfilePanel: true, profilePanelState: action.payload }),
      };
    case 'HIDE_LEAVE_CREATE_PAGE_CONFIRM':
      return {
        ...state,
        visibleLeavePageConfirm: false,
      };
    case 'SET_SETTINGS_DEFAULT_EXPANDED':
      return {
        ...state,
        defaultSettingsExpanded: action.payload.defaultSettingsExpanded,
      };
    case 'TOGGLE_REPORT_MODAL':
      return {
        ...state,
        reportModal: {
          isOpen: action.payload.isOpen,
          data: action.payload.data as any,
        },
      };
    case 'TOGGLE_MUTUAL_MODAL':
      return {
        ...state,
        mutualModal: {
          isOpen: action.payload.isOpen,
          data: action.payload.data as any,
        },
      };
    case 'TOGGLE_SHARE_ENTITY_MODAL':
      return {
        ...state,
        shareEntityModal: {
          isOpen: action.payload.isOpen,
          data: action.payload.data as any,
        },
      };
    case 'SET_UPLOADING_POST_FILES': {
      return {
        ...state,
        uploadingPostFiles: action.payload,
      };
    }
    case 'RESET_CREATE_POST_PROGRESS': {
      return {
        ...state,
        createPostProgress: [],
      };
    }
    case 'SET_CREATE_POST_PROGRESS': {
      const createPostProgress = [...state.createPostProgress];
      createPostProgress[action.payload.index] = action.payload.value;
      return {
        ...state,
        createPostProgress,
      };
    }
    case 'RESET_CREATE_POST_MODAL': {
      return {
        ...state,
        createPostModal: initialState.createPostModal,
      };
    }
    case 'SET_CREATE_POST_MODAL': {
      const selectedMedia = action.payload.selectedMedia
        ? makePostMediaFromFiles(action.payload.selectedMedia)
        : undefined;
      return {
        ...state,
        createPostModal: {
          ...state.createPostModal,
          ...action.payload,
          selectedMedia,
        },
      };
    }
    case 'SET_CREATED_POST_DATA': {
      return {
        ...state,
        createdPostData: {
          ...action.payload,
        },
      };
    }
    case 'RESET_CREATED_POST_DATA': {
      return {
        ...state,
        createdPostData: {
          sender: '',
          data: {},
        },
      };
    }
    case 'OPEN_VIEW_MEDIA':
      return {
        ...state,
        viewMedia: {
          selectedItem: action.payload.selectedItem,
          isOpen: true,
          // @ts-ignore
          medias: action.payload.medias,
        },
      };
    case 'CLOSE_VIEW_MEDIA':
      return {
        ...state,
        viewMedia: {
          isOpen: false,
          medias: [],
          selectedItem: 0,
        },
      };
    case 'TOGGLE_IS_OPENED_RECOMMENDATION_FROM_MESSAGE':
      return {
        ...state,
        isRecommendationOpenedFromMessage: action.payload.isOpened,
      };
    case 'SET_SHARE_ENTITY_TABBED_MODAL_DATA':
      return {
        ...state,
        shareEntityTabbedModal: {
          ...state?.shareEntityTabbedModal,
          ...action?.payload,
        },
      };
    case 'TOGGLE_CREATE_JOB_MODAL_IN_USER_PROJECT':
      return {
        ...state,
        isOpenCreateJobModalInUserProject: action.payload,
      };
    case 'ADD_PROFILE_HISTORY_COUNT':
      return {
        ...state,
        profileHistoryCount: state.profileHistoryCount + 1,
      };
    case 'RESET_PROFILE_HISTORY_COUNT':
      return {
        ...state,
        profileHistoryCount: 0,
      };
    case 'ADD_SCHEDULE_HISTORY_COUNT':
      return {
        ...state,
        scheduleHistoryCount: state.scheduleHistoryCount + 1,
      };
    case 'SET_SCHEDULE_HISTORY_COUNT':
      return {
        ...state,
        scheduleHistoryCount: action.payload,
      };
    case 'RESET_SCHEDULE_HISTORY_COUNT':
      return {
        ...state,
        scheduleHistoryCount: 0,
      };
    case 'SET_SCHEDULE_SUBPAGE':
      return {
        ...state,
        isScheduleSubPage: true,
      };
    case 'RESET_SCHEDULE_SUBPAGE':
      return {
        ...state,
        isScheduleSubPage: false,
      };
    case 'ADD_SEARCH_HISTORY_COUNT':
      return {
        ...state,
        searchHistoryCount: state.searchHistoryCount + 1,
      };
    case 'SET_SEARCH_HISTORY_COUNT':
      return {
        ...state,
        searchHistoryCount: action.payload,
      };
    case 'RESET_SEARCH_HISTORY_COUNT':
      return {
        ...state,
        searchHistoryCount: 0,
      };
    case 'SET_SEARCH_SUBPAGE':
      return {
        ...state,
        isSearchSubPage: true,
      };
    case 'RESET_SEARCH_SUBPAGE':
      return {
        ...state,
        isSearchSubPage: false,
      };
    case 'SET_SEARCH_STARTED_FROM_MAIN':
      return {
        ...state,
        isSearchStartedFromMain: true,
      };
    case 'RESET_SEARCH_STARTED_FROM_MAIN':
      return {
        ...state,
        isSearchStartedFromMain: false,
      };

    default: {
      throw new Error(`Unsupported action type at APP Reducer`);
    }
  }
}
