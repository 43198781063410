import { useEffect, useState } from 'react';
import getLocalforageMethods from '../utils/getLocalforageMethods';

type UseRecentSearchesType = {
  removeItem: (arg: any) => void;
  addItem: (arg: any) => void;
  data: Array<any>;
  deleteAll: () => void;
};
const useRecentSearches = (): UseRecentSearchesType => {
  const localforage = getLocalforageMethods('recentSearch');
  const [recentSearches, setRecentSearch] = useState<Array<any>>([]);

  const getSearchHistory = async () => {
    const value = await localforage.getKey();
    if (value) {
      setRecentSearch(value);
    }
  };

  useEffect(() => {
    getSearchHistory();
  }, []);

  const removeItem = (newItem: any) => () => {
    const filtered = recentSearches?.filter((item) => {
      if (newItem.type === 'HASHTAG' && item.title === newItem.title) {
        return false;
      }
      if (newItem.type === 'KEYWORD' && item.title === newItem.title) {
        return false;
      }
      if (item.type === 'PAGE' && item.username === newItem.username) {
        return false;
      }
      if (item.type === 'PERSON' && item.username === newItem.username) {
        return false;
      }
      return true;
    });
    setRecentSearch(filtered);
    localforage.setKey(filtered);
  };

  const deleteAll = () => {
    setRecentSearch([]);
    localforage.setKey([]);
  };

  const addItem = (newItem: any) => {
    const exist = recentSearches?.some((item) => {
      if (newItem.type === 'HASHTAG' && item.title === newItem.title) {
        return true;
      }
      if (newItem.type === 'KEYWORD' && item.title === newItem.title) {
        return true;
      }
      if (item.type === 'PAGE' && item.username === newItem.username) {
        return true;
      }
      if (item.type === 'PERSON' && item.username === newItem.username) {
        return true;
      }
      return false;
    });
    if (!exist) {
      setRecentSearch((prev) => [...prev, newItem]);
      localforage.setKey([...recentSearches, newItem]);
    }
  };
  return { removeItem, addItem, data: recentSearches, deleteAll };
};

export default useRecentSearches;
