import request from '../utils/request';
import { Endpoints, geoEndpoints } from '../constants';
import type { ICountry, ICity, ILocation, ValueLabelType } from '../types';
import geoNormalizer from '../utils/normalizers/geo';

export const getDiscoverPlace = async ({
  params: { text } = {},
}: {
  params?: {
    text?: string;
  };
}): Promise<any> => {
  if (!text) {
    throw new Error('To get places text is required. getDiscoverPlace');
  }
  const { data } = await request.get(geoEndpoints.discover.place, {
    params: {
      text,
    },
  });
  // return data?.results?.sort(
  //   (a: any, b: any) => parseInt(b.distance, 10) - parseInt(a.distance, 10)
  // );
  return data?.results?.map((x: any) => ({
    ...x,
    position: {
      lat: x.position[0],
      lon: x.position[1],
    },
  }));
};

export const getCountries = async ({
  params,
}: {
  params: any;
}): Promise<any> => {
  const { data } = await request.get(geoEndpoints.location.country, {
    params,
  });

  return data;
};

export const getCities = async ({
  params,
}: {
  params: {
    countryId?: string;
  };
}): Promise<any> => {
  const { data } = await request.get(geoEndpoints.location.city, {
    params,
  });

  return data;
};

export const getCountryById = async ({
  params,
}: {
  params: {
    countryIds: Array<string>;
  };
}): Promise<Array<ICountry>> => {
  const { data } = await request.get(geoEndpoints.location.getCountryById, {
    params,
  });
  return data;
};

export const getCountryByCode = async ({
  params,
}: {
  params: {
    countryCode: string;
  };
}): Promise<Array<ICountry>> => {
  const { data } = await request.get(geoEndpoints.location.getCountryByCode, {
    params,
  });
  return data;
};

export const getCityById = async ({
  params,
}: {
  params: {
    cityIds: Array<string>;
  };
}): Promise<Array<ICity>> => {
  const { data } = await request.get(geoEndpoints.location.getCityById, {
    params,
  });
  return data?.map((item: ICity) => {
    const { name, stateName, countryName } = item;
    return {
      ...item,
      label: [name, stateName, countryName]
        .reduce((prev: Array<string>, curr: string) => {
          if (!!prev && prev.indexOf(curr)) {
            return [...prev, curr];
          }
          return prev;
        }, [])
        .join(', '),
    };
  });
};

export const deleteLanguage = (item: any) =>
  request.delete(`${Endpoints.App.User.Language.get}/${item.id}`);

const searchPlace = async (params: {
  text?: string;
}): Promise<Array<ILocation>> => {
  const { text, ...rest } = params || {};
  if (!text) {
    throw new Error('To get places text is required. searchPlace');
  }

  const { data } = await request.get(geoEndpoints.location.searchPlace, {
    params: { text, ...rest },
  });

  return geoNormalizer.searchLocation(data);
};

const searchCountry = async (params: {
  text?: string;
}): Promise<Array<ValueLabelType>> => {
  if (!params.text) {
    throw new Error('To get places text is required. searchCountry');
  }
  const url = geoEndpoints.location.searchCountry;

  const { data } = await request.get(url, {
    params,
  });

  return geoNormalizer.searchCountry(data);
};

const searchCity = async (params: {
  text?: string;
  countryCode?: string;
}): Promise<Array<ValueLabelType>> => {
  if (!params.text) {
    throw new Error('To get places text is required. searchCity');
  }
  const url = geoEndpoints.location.searchCity;

  const { data } = await request.get(url, {
    params,
  });

  return geoNormalizer.searchCity(data);
};

const suggestCity = async (params: {
  text?: string;
}): Promise<Array<ILocation>> => {
  if (!params.text) {
    throw new Error('To get places text is required. suggestCity');
  }
  const url = geoEndpoints.location.suggestCity;

  const { data } = await request.get(url, {
    params,
  });

  return geoNormalizer.searchLocation(data);
};

const suggestPlace = async (params: {
  text?: string;
}): Promise<Array<ILocation>> => {
  if (!params.text) {
    throw new Error('To get places text is required. suggestPlace');
  }

  const url = geoEndpoints.location.suggestPlace;

  const { data } = await request.get(url, {
    params,
  });

  return geoNormalizer.searchLocation(data);
};

const searchTimezone = async (params: {
  text?: string;
}): Promise<Array<any>> => {
  if (!params.text) {
    throw new Error('To get places text is required. searchTimezone');
  }
  const { data } = await request.get(geoEndpoints.searchTimezone, {
    params,
  });

  return geoNormalizer.searchTimezone(data);
};

export const getTimeZone = async ({
  params,
}: {
  params: {
    code: string;
  };
}): Promise<any> => {
  const { data } = await request.get(geoEndpoints.getTimeZone, {
    params,
  });
  return data;
};

const getLocationDetails = async (id: string, accessToken?: string) => {
  const { data } = await request.get(geoEndpoints.location.placeDetail, {
    accessToken,
    params: {
      id,
    },
  });

  return {
    cityCode: data?.cityCode,
    stateCode: data?.stateCode,
    countryCode: data?.countryCode,
    externalId: data?.id,
    lat: data?.lat,
    lon: data?.lon,
    position: { lat: data?.lat, lon: data?.lon },
    title: data?.title,
    category: data?.category,
    value: data?.id,
    label: data?.title,
  };
};

export default {
  getDiscoverPlace,
  getCountries,
  getCities,
  getCountryById,
  getCityById,
  deleteLanguage,
  searchPlace,
  suggestCity,
  searchTimezone,
  getTimeZone,
  getLocationDetails,
  searchCity,
  searchCountry,
  getCountryByCode,
  suggestPlace,
};
