export async function asyncLoop(
  callback: Function,
  condition: () => boolean,
  maxIterations: number,
  finallyCallback: Function
) {
  for (let i = 0; i < maxIterations; i++) {
    if (await condition()) {
      await finallyCallback?.();
      break;
    }
    if (i === maxIterations - 1) {
      await finallyCallback?.();
    }
    await callback();
  }
}
