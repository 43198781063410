import React from 'react';
import isNumber from 'lodash/isNumber';
import useVisualViewportResize from './useVisualViewportResize';

export type HeightType = number | null;

export interface ReturnObject {
  height: HeightType;
  initialHeight: HeightType;
}

const useMobileWindowHeightChanges = (): ReturnObject => {
  const [height, setHeight] = React.useState<HeightType>(null);
  const initialHeightRef = React.useRef<HeightType>(null);

  const updateWindowHeight = React.useCallback(() => {
    const { innerHeight } = window;
    setHeight(() => innerHeight);
    if (!isNumber(initialHeightRef.current)) {
      initialHeightRef.current = innerHeight;
    }
  }, []);

  useVisualViewportResize(updateWindowHeight);

  return {
    height,
    initialHeight: initialHeightRef.current,
  };
};

export default useMobileWindowHeightChanges;
