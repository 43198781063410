export const SALARY_PERIOD = [
  {
    value: 'HOURLY',
    label: 'hourly',
  },
  {
    value: 'DAILY',
    label: 'daily',
  },
  {
    value: 'WEEKLY',
    label: 'weekly',
  },
  {
    value: 'BIWEEKLY',
    label: 'bi_weekly',
  },
  {
    value: 'MONTHLY',
    label: 'monthly',
  },
  {
    value: 'YEARLY',
    label: 'yearly',
  },
];

export const LANGUAGES = [
  { label: 'English', value: 'en', id: '1' },
  { label: 'Spanish', value: 'es', id: '2' },
  { label: 'Turkish', value: 'tr', id: '3' },
];

export const BUSINESS_SORT_OPTIONS = [
  { label: 'Job title', value: 'job_title' },
  { label: 'Date posted', value: 'date_posted' },
  { label: 'Owned by', value: 'owned_by' },
];

export const WORK_SPACE_MODEL = [
  {
    label: 'ON_SITE',
    value: 'ON_SITE',
    tooltip: 'employ_work_o_s',
    icon: 'building',
  },
  {
    label: 'HYBRID',
    value: 'HYBRID',
    tooltip: 'employ_work_o_s_off',
    icon: 'house-person-leave',
  },
  {
    label: 'REMOTE',
    value: 'REMOTE',
    tooltip: 'employ_work_off',
    icon: 'house-light',
  },
];

export const QUESTION_TYPES = [
  { label: 'Text input', value: 'TEXT_INPUT' },
  { label: 'Multiple select', value: 'MULTI_SELECT' },
  { label: 'Single select', value: 'SINGLE_SELECT' },
];

const searchGroupTypeOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Top suggestions',
    value: 'TOP_SUGGESTION',
  },

  {
    label: 'Popular',
    value: 'POPULAR',
  },
  {
    label: 'Applied',
    value: 'APPLIED',
  },
  {
    label: 'Saved',
    value: 'SAVED',
  },
];

const searchGroupTypeBusinessOptions = [
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    label: 'Archived',
    value: 'ARCHIVE',
  },
];

export const datePostedOptions = [
  {
    label: 'ANY_TIME',
    value: 'ANY_TIME',
  },
  {
    label: 'PAST_MONTH',
    value: 'PAST_MONTH',
  },
  {
    label: 'PAST_WEEK',
    value: 'PAST_WEEK',
  },
  {
    label: 'PAST_24_HOURS',
    value: 'PAST_24_HOURS',
  },
];

const workPlaceTypes = [
  {
    label: 'Remote',
    value: 'REMOTE',
  },
  {
    label: 'Hybrid',
    value: 'HYBRID',
  },
  {
    label: 'On-site',
    value: 'ON_SITE',
  },
];

export const experienceLevels = [
  {
    label: 'INTERNSHIP',
    value: 'INTERNSHIP',
  },
  {
    label: 'ENTRY_LEVEL',
    value: 'ENTRY_LEVEL',
  },
  {
    label: 'MIDDLE_LEVEL',
    value: 'MIDDLE_LEVEL',
  },
  {
    label: 'SENIOR_LEVEL',
    value: 'SENIOR_LEVEL',
  },
  {
    label: 'PRINCIPAL_LEVEL',
    value: 'PRINCIPAL_LEVEL',
  },
  {
    label: 'ASSOCIATE',
    value: 'ASSOCIATE',
  },
  {
    label: 'DIRECTOR',
    value: 'DIRECTOR',
  },
  {
    label: 'EXECUTIVE',
    value: 'EXECUTIVE',
  },
];

const searchEntities = [
  {
    label: 'Posts',
    value: 'posts',
    leftIcon: 'post',
  },
  {
    label: 'People',
    value: 'people',
    leftIcon: 'people',
  },
  {
    label: 'Pages',
    value: 'pages',
    leftIcon: 'flag',
  },
  {
    label: 'Jobs',
    value: 'jobs',
    leftIcon: 'briefcase-blank',
  },
];

export const sortBy = [
  { value: 'MOST_RECENT', label: 'most_recent' },
  { value: 'MOST_RELEVANT', label: 'most_relevant' },
];

const savedJobsGroupStatus = [
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Applied',
    value: 'APPLIED',
  },

  {
    label: 'Hired',
    value: 'HIRED',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
];

const appliedJobGroupStatus = [
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Hired',
    value: 'HIRED',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
];

const hostedBy = [
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'External',
    value: 'external',
  },
];

const jobApplicationStatus = [
  {
    label: 'applied',
    value: 'APPLIED',
  },
  {
    label: 'under_review',
    value: 'UNDER_REVIEW',
  },
  {
    label: 'selected_for_interview',
    value: 'SELECTED_FOR_INTERVIEW',
  },
  {
    label: 'interviewed',
    value: 'INTERVIEWED',
  },
  {
    label: 'reference_check',
    value: 'REFERENCE_CHECK',
  },
  {
    label: 'offer',
    value: 'OFFER',
  },
  {
    label: 'hired',
    value: 'HIRED',
  },
  {
    label: 'rejected',
    value: 'REJECTED',
  },
];

export const memberSince = [
  {
    label: 'ANY_TIME',
    value: 'ANY_TIME',
  },
  {
    label: 'PAST_YEAR',
    value: 'PAST_YEAR',
  },
  {
    label: 'PAST_6_MONTHS',
    value: 'PAST_6_MONTHS',
  },
  {
    label: 'PAST_MONTH',
    value: 'PAST_MONTH',
  },
  {
    label: 'PAST_WEEK',
    value: 'PAST_WEEK',
  },
  {
    label: 'PAST_24_HOURS',
    value: 'PAST_24_HOURS',
  },
];

export const jobSearchOptions = {
  hostedBy,
  searchGroupType: (
    isBusinessApp: boolean
  ): {
    label: string;
    value: string;
  }[] =>
    isBusinessApp ? searchGroupTypeBusinessOptions : searchGroupTypeOptions,
  dateRangeType: datePostedOptions,
  workPlaceTypes,
  experienceLevels,
  searchEntities,
  sortBy,
  savedJobsGroupStatus,
  appliedJobGroupStatus,
  jobApplicationStatus,
  memberSince,
};
