import dayjs from 'dayjs';

const relativeTime = require('dayjs/plugin/relativeTime');
const updateLocale = require('dayjs/plugin/updateLocale');

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

const dateFromNow = (value: Date, shortFormat = false, t: Function): string => {
  // @ts-ignore
  dayjs.updateLocale('en', {
    relativeTime: {
      future: t('in_x'),
      past: shortFormat ? '%s' : t('x_ago'),
      s: shortFormat ? 'few sec' : t('a_few_seconds'),
      m: shortFormat ? '1m' : t('a_min'),
      mm: shortFormat ? '%dm' : t('x_minutes'),
      h: shortFormat ? '1h' : t('an_hour'),
      hh: shortFormat ? '%dh' : t('x_hours'),
      d: shortFormat ? '1d' : t('a_day'),
      dd: shortFormat ? '%dd' : t('x_days'),
      M: shortFormat ? '1mon' : t('a_month'),
      MM: shortFormat ? '%dmos' : t('x_months'),
      y: shortFormat ? '1y' : t('a_year'),
      yy: shortFormat ? '%dy' : t('x_years'),
    },
  });
  // @ts-ignore
  const valueWithZero = value?.endsWith('Z') ? value : `${value}Z`;
  // @ts-ignore
  return dayjs(valueWithZero).fromNow();
};

export default dateFromNow;
