const translateUrl = {
  en: 'en_us.json',
  tr: 'tr_tr.json',
  fr: 'fr_fr.json',
  de: 'de_ch.json',
  es: 'es_es.json',
} as any;

const getLangData = async (
  isDev: boolean,
  languagesAddress: string,
  currentLang: string,
  backUpLang: string
) => {
  if (isDev) {
    return backUpLang;
  }

  const url = `${languagesAddress}/${translateUrl[currentLang as any]}`;

  try {
    const response = await fetch(url);
    return response.json();
  } catch (e) {
    return backUpLang;
  }
};

export default getLangData;
