import useStateCallback from './useStateCallback';

type CallbackType = Function | any;
type SetterFunctionType = (callback?: CallbackType) => void;

export type UseDisclosureType = {
  isOpen?: boolean;
  onToggle: SetterFunctionType;
  onOpen: SetterFunctionType;
  onClose: SetterFunctionType;
};

const useDisclosure = (initialState?: boolean): UseDisclosureType => {
  const [isOpen, toggleIsOpen] = useStateCallback(!!initialState);

  const onClose = (callback?: CallbackType) => {
    toggleIsOpen(false, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  const onOpen = (callback?: CallbackType) => {
    toggleIsOpen(true, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  const onToggle = (callback?: CallbackType) =>
    isOpen ? onClose(callback) : onOpen(callback);

  return { isOpen, onOpen, onClose, onToggle };
};
export default useDisclosure;
