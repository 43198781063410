import { QueryKeys, routeNames } from '../constants';

const useSignUpRedirections = () => {
  // const router = useRouter();
  const saveRedirectUrl = (url: string) => {
    localStorage.setItem(QueryKeys.redirectUrl, url);
  };
  const getRedirectUrl = () => {
    return localStorage.getItem(QueryKeys.redirectUrl);
  };

  const redirect = (url?: string) => {
    const _url = url || getRedirectUrl();
    localStorage.removeItem(QueryKeys.redirectUrl);
    // router.replace(_url || routeNames.home);
    window.location.assign(_url || routeNames.home);
  };

  return { saveRedirectUrl, getRedirectUrl, redirect };
};

export default useSignUpRedirections;
