import { useEffect } from 'react';

const useScrollMove = (callback: (...args: any[]) => any): void => {
  useEffect(() => {
    window.addEventListener('scroll', callback, true);

    return () => {
      window.removeEventListener('scroll', callback, true);
    };
  }, []);
};

export default useScrollMove;
