import localforage from 'localforage';

const getLocalforageMethods = (key?: string) => {
  const getKey = async () => {
    if (key) {
      const value = await localforage.getItem<any>(key);
      return value;
    }
    return undefined;
  };
  const setKey = (value: any) => {
    if (key) {
      localforage.setItem(key, value);
    }
  };

  const removeKey = () => {
    if (key) {
      localforage.removeItem(key);
    }
  };

  const clear = async () => {
    await localforage.clear();
  };

  return { getKey, removeKey, setKey, clear };
};

export default getLocalforageMethods;
