import { landingRouteNames, mainRoutes } from '../constants';

type UrlsBuilderProps = {
  baseUrl: string;
  storageBaseUrl: string;
  apiBaseUrl: string;
};

type UrlsBuilderType = {
  login: string;
  languagesAddress: string;
  signUp: string;
  getName: string;
  getCode: string;
  forgetPassword: string;
  helpAndSupport: string;
  twoFactorAuthGetCode: string;
  placeholder: {
    light: string;
    dark: string;
    page: string;
    user: string;
  };
  socialAuth: (args: {
    deviceId?: string;
    socialName: 'google' | 'linkedin';
    redirectUrl?: string;
  }) => string;
};

const urlsBuilder = ({
  baseUrl,
  storageBaseUrl,
  apiBaseUrl,
}: UrlsBuilderProps): UrlsBuilderType => {
  const GCS_LINK = `${storageBaseUrl}/assets/images/placeholder`;
  const OAUTH2_REDIRECT_URI = `${baseUrl}/login/oauth2`;

  return {
    login: `${baseUrl}${landingRouteNames.login}`,
    languagesAddress: `${storageBaseUrl}/assets/i18n`,
    signUp: `${baseUrl}${landingRouteNames.signup}`,
    getName: `${baseUrl}${landingRouteNames.getName}`,
    getCode: `${baseUrl}${landingRouteNames.getCode}`,
    twoFactorAuthGetCode: `${baseUrl}${landingRouteNames.twoFactorAuthGetCode}`,
    forgetPassword: `${baseUrl}${landingRouteNames.forgetPassword}`,
    helpAndSupport: `${baseUrl}${mainRoutes.helpCenter}`,
    placeholder: {
      light: `${GCS_LINK}/placeholderLight.png`,
      dark: `${GCS_LINK}/placeholderDark.png`,
      user: `${GCS_LINK}/default-user-bg.png`,
      page: `${GCS_LINK}/default-page-bg.png`,
    },
    socialAuth: ({ deviceId, socialName }): string =>
      `${apiBaseUrl}/auth-service/api/v1/oauth2/authorize/${socialName}?device_id=${deviceId}&redirect_uri=${OAUTH2_REDIRECT_URI}`,
  };
};

export default urlsBuilder;
