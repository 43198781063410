import max from 'lodash/max';
import round from 'lodash/round';
import resizer from 'react-image-file-resizer';

interface Props {
  file: File;
  cropWidth?: number;
  cropHeight?: number;
  trySize?: number;
  trySizeRatio?: number;
  outputType?: 'base64' | 'blob' | 'file';
  outputQuality?: number;
  outputRotate?: number;
}

const webifyImage = async ({
  file,
  cropWidth = 0,
  cropHeight = 0,
  trySize = 0,
  trySizeRatio = 2,
  outputType = 'file',
  outputQuality = 100,
  outputRotate = 0,
}: Props): Promise<any> => new Promise((resolve) => {
  const maxCropSize = max([cropWidth, cropHeight]) || 0;
  const tryToScale = trySize || (maxCropSize * trySizeRatio);

  const reader = new FileReader();

  reader.onload = (evt: ProgressEvent<FileReader>) => {
    const img = new Image();
    img.src = (evt?.target?.result || '') as string;
    img.onload = () => {
      let newWidth = img.width;
      let newHeight = img.height;

      if (newWidth > tryToScale && newWidth <= newHeight) {
        newHeight = round((tryToScale * newHeight) / newWidth) || newHeight;
        newWidth = tryToScale || newWidth;
      } else if (newHeight > tryToScale && newWidth > newHeight) {
        newWidth = round((tryToScale * newWidth) / newHeight) || newWidth;
        newHeight = tryToScale || newHeight;
      }

      resizer.imageFileResizer(
        file,
        newWidth,
        newHeight,
        file.type.substring(file.type.indexOf('/') + 1),
        outputQuality,
        outputRotate,
        (result) => { resolve(result); },
        outputType,
      )
    };
  };

  reader.readAsDataURL(file);
});

export default webifyImage;
