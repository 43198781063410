import { landingRouteNames, routeNames, urls } from '../constants';
// import useSignUpRedirections from './useSignUpRedirections';
import getCookieKey from '../utils/getCookieKey';
import Cookies from '../utils/cookies';
import { redirectUrl, replaceUrl } from '../utils/redirection';
// import getQueryParams from '../utils/getQueryParams';
import { useRouter } from 'next/navigation';

type OnSuccessType = (a: any, b?: any, c?: any) => any;

const useOnSuccessLogin = (): OnSuccessType => {
  const router = useRouter();

  return (data: any) => {
    const signUpCompleted = data?.signUpCompleted;

    if (!signUpCompleted) {
      const NOT_COMPLETED_SIGNUP_DATA = getCookieKey('notSignupCompletedData');
      Cookies.set(NOT_COMPLETED_SIGNUP_DATA, data);
      // redirectUrl(`${window?.location?.origin}${landingRouteNames.getName}`);
      router.push(landingRouteNames.getName);
    } else {
      const OBJ_TOKEN = getCookieKey('userObjToken');
      Cookies.set(OBJ_TOKEN, data);
      window.location.replace(routeNames.home);
      // router.refresh();

      // if (getRedirectUrl()) {
      //   redirectSignUp();
      // } else {
      //   const qs = getQueryParams(window.location.search); // @ts-ignore
      //   const redirect = qs?.redirect;
      //   const next = redirect?.includes('business.lobox')
      //     ? redirect
      //     : `${urls.base}${routeNames.home}`;
      //   replaceUrl(next);
      // }
    }
  };
};

export default useOnSuccessLogin;
