import * as formValidator from 'yup';

const URLREGEX =
  /((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s<]{2,}|www\.[a-zA-Z0-9]+\.[^\s<]{2,}))/gi;

const linkValidation = formValidator
  .string()
  .nullable()
  .matches(URLREGEX, 'enter_valid_url_exm');

export default linkValidation;
