type MyKey = 'title' | 'name' | 'id';
type DataItemType = Record<MyKey, string>;
type DataType = Array<DataItemType>;
type LookupResponseNormalizerType = Array<{
  value: string;
  label: string;
}>;

const lookupResponseNormalizer = (
  response: DataType
): LookupResponseNormalizerType =>
  response
    ?.map(({ id, title }) => ({
      value: id,
      label: title,
    }))
    .slice(0, 6);

export default lookupResponseNormalizer;
