import { usePathname, useRouter } from 'next/navigation';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { asyncLoop } from '../utils/asyncLoop';
import { sleep } from '../utils/sleep';
import { persistantSearchQuery } from '@shared/components/molecules/GlobalSearchInput/PersistantSearchQuery';
import useEffectOnlyChange from './useEffectOnlyChange';
import { isClient } from '..';
import { flushSync } from 'react-dom';

type Query = {
  get: (prop: string) => string | undefined;
  getAll: (prop: string) => string | string[] | undefined;
};
type Push = (
  options: { pathname?: string; search?: string },
  isReplace?: boolean
) => Promise<void>;

type Replace = (options: {
  pathname?: string;
  search?: string;
}) => Promise<void>;

export type Callback<T> = (x: T) => T;
export type SetState<T> = (func: Callback<T>) => void;
export type ReturnUseURLState<T> = {
  query: Query;
  push: Push;
  replace: Replace;
  isLoading: boolean;
};

export const CLIENT_ROUTER_KEY = 'CLIENT_ROUTER';

const isLoading = { value: false };

const hrefKey = { current: '' };

export default function useClientRouter<T = {}>(
  defaultState: T
): ReturnUseURLState<T> {
  const router = useRouter();

  const [state, setState] = useState<T>(() => {
    try {
      const newState = getQueryParams<T>();
      if (Object?.keys?.(newState as any)?.length !== 0) return newState;
      return defaultState;
    } catch {
      return defaultState;
    }
  });

  useEffect(() => {
    function updateStateFromQueryParams(e: any) {
      try {
        setState(e?.detail || getQueryParams<T>());
      } catch {
        // do nothing
      }
    }

    window.addEventListener(CLIENT_ROUTER_KEY, updateStateFromQueryParams);
    return () =>
      window.removeEventListener(CLIENT_ROUTER_KEY, updateStateFromQueryParams);
  }, []);

  useEffectOnlyChange(() => {
    if (isLoading.value) return;
    if (hrefKey.current === window?.location?.href) return;
    hrefKey.current = window?.location?.href;
    const event = new CustomEvent(CLIENT_ROUTER_KEY);
    window.dispatchEvent(event);
  }, [isClient() ? window?.location?.href : '']);

  const formatData = useCallback((newData: T) => {
    const newUrl = new URL(window.location.href);
    newUrl.search = '';

    for (const [key, value] of Object.entries(newData as any)) {
      if (Object.keys(value as any).length > 0) {
        newUrl.searchParams.set(key, String(value));
      } else {
        newUrl.searchParams.delete(key);
      }
    }

    return { newUrl, newStateData: newData };
  }, []);

  const updateState = useCallback(
    (
      callback: (prevState: T) => T,
      replace: boolean,
      disableHistoryPush?: boolean
    ) => {
      const newState = callback(state);
      if (Object.is(newState, state)) {
        return;
      }

      const { newUrl, newStateData } = formatData(newState);
      flushSync(() => {
        const event = new CustomEvent(CLIENT_ROUTER_KEY, {
          detail: newStateData,
        });
        window.dispatchEvent(event);
        hrefKey.current = newUrl.href;
      });

      if (!disableHistoryPush) {
        const historyFunction =
          window.history[replace ? 'replaceState' : 'pushState'];

        historyFunction.apply(window.history, [{}, '', newUrl?.href]);
      }
    },
    [state, formatData]
  );

  const replace = useCallback(
    (callback: Callback<T>, replace = false, disableHistoryPush = false) => {
      updateState(callback, replace, disableHistoryPush);
    },
    [updateState]
  );

  const _push: Push = useCallback(
    async (arg, isReplace) => {
      const method = isReplace ? 'replace' : 'push';
      if (typeof arg === 'string') {
        router[method](arg);
        return;
      }
      const { pathname: path = window.location.pathname, search } = arg;
      const isSame = window.location.pathname === path;

      if ((!path || isSame) && typeof search !== 'undefined') {
        // search can be '' and it is valid
        const params = getQueryParams<any>(search as any);
        persistantSearchQuery.query = decodeURIComponent(params?.query || '');
        replace(() => params, isReplace, isReplace);
        router.replace(`${path}?${search}`);
        return;
      }

      router[method](path);

      if (!search) return;
      isLoading.value = true;

      const params = getQueryParams<any>(search as any);
      persistantSearchQuery.query = decodeURIComponent(params?.query || '');
      replace(() => params, true, true);

      asyncLoop(
        async () => {
          await sleep(1000);
        },
        () => {
          return window.location.pathname.includes(path);
        },
        50,
        () => {
          replace(() => params, true);
          router.replace(`${path}?${search}`);
          isLoading.value = false;
        }
      );
    },
    [updateState]
  );

  const _replace: Replace = useCallback(
    (args) => _push(args, true),
    [updateState]
  );

  const query: Query = useMemo(
    () => ({
      get: (prop) => state?.[prop],
      getAll: (prop) => {
        if (!state?.[prop]) return [];

        return Array.isArray(state?.[prop]) ? state?.[prop] : [state?.[prop]];
      },
    }),
    [state]
  );

  return {
    query,
    push: _push,
    replace: _replace,
    isLoading: isLoading.value,
  };
}

function getQueryParams<T>(search: string = window.location.search): T {
  if (typeof window === 'undefined') return {} as T;

  const queryParams = new URLSearchParams(search);
  const result: Record<string, any> = {};

  queryParams.forEach((value, key) => {
    const getValue = (value: any) => {
      const parsedValue = value;
      if (!Object.keys(parsedValue)?.length) return '';
      return parsedValue;
    };
    if (result.hasOwnProperty(key)) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key]];
      }
      result[key].push(getValue(value));
    } else {
      result[key] = getValue(value);
    }
  });

  return result as T;
}
