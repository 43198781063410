const DEPOT_SERVICE = '/depot-service';

const API_VERSION = '/api/v1';

const base = `${DEPOT_SERVICE}${API_VERSION}`;

const depots = {
  getHistory: `${base}/history`,
  deleteSearchHistory: (key: string) => `${base}/search-history?key=${key}`,
  deleteAllSearchHistory: (
    type: 'GLOBAL' | 'JOBS' | 'PEOPLE' | 'POSTS' | 'PAGES' | 'LOCATIONS'
  ) => `${base}/search-history/all?type=${type}`,
  addSearchHistory: `${base}/search-history`,
  getJobHistory: `${base}/search-history/jobs`,
};

export default depots;
