import { useEffect, useRef } from 'react';

const useEffectOnlyChange = (callback: Function, dependencies: any[]) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!isInitialMount.current) {
      callback();
    } else {
      isInitialMount.current = false;
    }
  }, dependencies);
};

export default useEffectOnlyChange;
