import removeUserCookies from './removeUserCookies';
import removePageCookies from './removePageCookies';
import lookupResponseNormalizer from './normalizers/lookupResponseNormalizer';
import hex2rgba from './hex2rgba';
import i18n from './i18n';
import {
  emailValidation2,
  getEmailValidation,
  emailValidation,
  getCountryValidation,
} from './validationSchema';
import formatDate from './formatDate';
import datesDuration from './datesDuration';
import sortByKey from './date';
import checkLinkPrefix from './checkLinkPrefix';
import formValidator, { linkValidation } from './formValidator';
import hereApiResponseNormalizer from './normalizers/hereApiResponseNormalizer';
import geocodeResponseNormalizer from './normalizers/geocodeResponseNormalizer';
import skillsResponseNormalizer from './normalizers/skillsResponseNormalizer';
import getAutoHeightDuration from './getAutoHeightDuration';
import isTouchDevice from './isTouchDevice';
import isIos from './isIos';
import isClient from './isClient';
import deviceWidth from './deviceWidth';
import deviceHeight from './deviceHeight';
import { mailTo, callTo } from './linking';
import Cookies from './cookies';
import request from './request';
import makePostMediaFromFiles from './makePostMediaFromFiles';
import imageUrlToBlobAndBase64 from './imageUrlToBlobAndBase64';
import event from './event';
import preventClickHandler from './preventClickHandler';
import urlsBuilder from './urlsBuilder';
import { redirectUrl, replaceUrl } from './redirection';
import getCookieKey from './getCookieKey';
import convertBlobToFile from './convertBlobToFile';
import convertBlobToBase64 from './convertBlobToBase64';
import convertBase64ToBlob from './convertBase64ToBlob';
import isEmptyObjectValues from './isEmptyObjectValues';
import isElementVisible from './isElementVisible';
import removeRichTextBreakLines from './removeRichTextBreakLines';
import uuId from './uuIdGenerator';
import dateFromNow from './dateFromNow';
import deviceInfo from './deviceInfo';
import encoder from './encoder';
import removeEmptyFromObject from './removeEmptyFromObject';
import useInterval from './useInterval';
import isSameDay from './isSameDay';
import libphonenumber from './libphonenumber';
import isRichTextEmpty from './isRichTextEmpty';
import getCookieFromReqByKey from './getCookieFromReqByKey';
import isPwa from './isPwa';
import webifyImage from './webifyImage';
import getImageData from './getImageData';
import applyResolutionToImageSrc from './applyResolutionToImageSrc';
import removeQueryString from './removeQueryString';
import isInViewport from './isInViewport';
import formatSeconds from './formatSeconds';
import getVideoImage from './getVideoImage';
import reorderArray from './reorderArray';
import generateBadgeNumber from './generateBadgeNumber';
import mediaCanBeUploaded from './mediaCanBeUploaded';
import {
  getCountryValidation2,
  getXEmailValidation,
  resetPassWordValidation,
} from './validationSchema';
import getQueryParams from './getQueryParams';

export {
  getQueryParams,
  getCountryValidation2,
  getXEmailValidation,
  resetPassWordValidation,
  uuId,
  isEmptyObjectValues,
  isElementVisible,
  convertBase64ToBlob,
  convertBlobToBase64,
  convertBlobToFile,
  getCookieKey,
  request,
  Cookies,
  removeUserCookies,
  removePageCookies,
  hereApiResponseNormalizer,
  lookupResponseNormalizer,
  geocodeResponseNormalizer,
  skillsResponseNormalizer,
  i18n,
  emailValidation2,
  getEmailValidation,
  emailValidation,
  getCountryValidation,
  formatDate,
  datesDuration,
  sortByKey,
  checkLinkPrefix,
  formValidator,
  linkValidation,
  hex2rgba,
  getAutoHeightDuration,
  isTouchDevice,
  isIos,
  isClient,
  deviceWidth,
  deviceHeight,
  callTo,
  mailTo,
  makePostMediaFromFiles,
  imageUrlToBlobAndBase64,
  event,
  preventClickHandler,
  urlsBuilder,
  redirectUrl,
  replaceUrl,
  removeRichTextBreakLines,
  dateFromNow,
  deviceInfo,
  encoder,
  removeEmptyFromObject,
  useInterval,
  isSameDay,
  isRichTextEmpty,
  libphonenumber,
  getCookieFromReqByKey,
  isPwa,
  webifyImage,
  getImageData,
  applyResolutionToImageSrc,
  removeQueryString,
  isInViewport,
  formatSeconds,
  getVideoImage,
  reorderArray,
  generateBadgeNumber,
  mediaCanBeUploaded,
};
