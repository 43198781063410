import type { PeopleType, PaginateResponse } from '../../types';

const pendingNormalizer = (
  data: PaginateResponse<PeopleType>
): PaginateResponse<PeopleType> => ({
  ...data,
  content: data.content?.map((item) => ({
    ...item,
    follow: true,
    followStatus: 'PENDING',
  })),
});

export default pendingNormalizer;
