export default function removeBreaksAndSpaces(text: string): string {
  const startsWithLineBreak = /^<p><br><\/p>/.test(text);
  const startsWithSpaces = /^<p>\s*<\/p>/.test(text);
  const endsWithLineBreak = /<p><br><\/p>$/.test(text);
  const endsWithSpaces = /<p>\s*<\/p>$/.test(text);
  while (
    startsWithLineBreak ||
    startsWithSpaces ||
    endsWithLineBreak ||
    endsWithSpaces
  ) {
    if (startsWithLineBreak) {
      text = text.replace(/<p><br><\/p>/, '');
    } else if (startsWithSpaces) {
      text = text.replace(/<p>\s*<\/p>/, '');
    } else if (endsWithLineBreak) {
      text = replaceLastOccurrence(text, /<p><br><\/p>/, '');
    } else if (endsWithSpaces) {
      text = replaceLastOccurrence(text, /<p>\s*<\/p>/, '');
    }
    return removeBreaksAndSpaces(text);
  }
  return text;
}

function replaceLastOccurrence(
  text: string,
  pattern: RegExp,
  replacement: string
) {
  // Use a regular expression to find the last occurrence of the pattern in the text
  const regex = new RegExp(`(.*?)${pattern.source}`, 'g');
  // @ts-ignore
  const lastOccurrenceMatch = Array.from(text.matchAll(regex)).pop();
  // If the pattern is found, replace it with the given replacement
  if (lastOccurrenceMatch && typeof lastOccurrenceMatch?.index === 'number') {
    text =
      text.substring(0, lastOccurrenceMatch.index) +
      text.substring(lastOccurrenceMatch.index).replace(pattern, replacement);
  }

  return text;
}
