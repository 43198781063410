import QueryKeys from './queryKeys';
import * as db from './db';
import * as jobsDb from './jobsDb';
import * as schedulesDb from './schedulesDb';

import type {
  ResumePermitType,
  WorkStatusType,
  LanguageListType,
  TimeFormatType,
  DateFormatType,
  ValueLabelType,
  GenderType,
} from '../../types';


const TAB_PAGES_HEADER_SEARCH_ID = 'tabPagesHeaderSearch';
const MAIN_CENTER_WRAPPER_ID = 'mainCenterWrapper';
const APP_DESKTOP_FOOTER = 'appDesktopFooter';
const CREATE_POST_BOX = 'CREATE_POST_BOX';
export const privacyValues = {
  ONLY_ME: 'ONLY_ME',
  MY_FOLLOWERS: 'MY_FOLLOWERS',
  EVERYONE_AT_LOBOX: 'EVERYONE_AT_LOBOX',
} as const;

const INVITE_STATUS = {
  invite: 'INVITE',
  madeInvitationRequest: 'MADEINVITATIONREQUEST',
  follow: 'FOLLOW',
  madeFollowRequest: 'MADEFOLLOWREQUEST',
  pending: 'PENDING',
  madeInvitationByEmail: 'MADEINVITATIONBYEMAIL',
  accepted: 'ACCEPTED',
  blocked: 'BLOCKED',
} as const;

const WORK_STATUS_VALUES: {
  [key in WorkStatusType]: ValueLabelType<WorkStatusType>;
} = {
  CITIZEN: {
    value: 'CITIZEN',
    label: 'Citizen of the country',
  },
  PERMANENT: {
    value: 'PERMANENT',
    label: 'Permanent work permit',
  },
  TEMPORARY: {
    value: 'TEMPORARY',
    label: 'Temporary work permit',
  },
  CONTRACT: {
    value: 'CONTRACT',
    label: 'Contract-based work permit',
  },
  SEASONAL: {
    value: 'SEASONAL',
    label: 'Seasonal work permit',
  },
  NO: {
    value: 'NO',
    label: 'No work permit',
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other (Please specify)',
  },
} as const;

const RESUME_LINK_VALUES: {
  [key in ResumePermitType]: ValueLabelType<ResumePermitType>;
} = {
  RECRUITERS: {
    value: 'RECRUITERS',
    label: 'Recruiters',
  },
  FOLLOWERS: {
    value: 'FOLLOWERS',
    label: 'Followers',
  },
  EVERYONE: {
    value: 'EVERYONE',
    label: 'Everyone',
  },
  ONLY_SHARE: {
    value: 'ONLY_SHARE',
    label: 'Only people I share',
  },
} as const;

const LANGUAGES_LIST_VALUES: {
  [key in LanguageListType]: ValueLabelType<LanguageListType> & {
    isoKey: string;
  };
} = {
  english: {
    value: 'english',
    label: 'English',
    isoKey: 'en',
  },
  turkish: {
    value: 'turkish',
    label: 'Türkçe',
    isoKey: 'tr',
  },
} as const;

const TIME_FORMAT_VALUES: {
  [key in TimeFormatType]: ValueLabelType<TimeFormatType>;
} = {
  '24': {
    value: '24',
    label: '24 hours',
  },
  '12': {
    value: '12',
    label: '12 hours',
  },
} as const;

const DATE_FORMAT_VALUES: {
  [key in DateFormatType]: ValueLabelType<DateFormatType>;
} = {
  LONG: {
    value: 'LONG',
    label: 'Sep 16, 2021',
  },
  SHORT: {
    value: 'SHORT',
    label: '16 Sep 2021',
  },
  YMD: {
    value: 'YMD',
    label: '2021/09/16',
  },
  YDM: {
    value: 'YDM',
    label: '2021/16/09',
  },
  MDY: {
    value: 'MDY',
    label: '09/16/2021',
  },
  DMY: {
    value: 'DMY',
    label: '16/09/2021',
  },
};

const GENDER_VALUES: {
  [key in GenderType]: ValueLabelType<GenderType>;
} = {
  MALE: { label: 'Male', value: 'MALE' },
  FEMALE: { label: 'Female', value: 'FEMALE' },
  PREFER_NOT_TO_SAY: {
    label: 'Prefer not to say',
    value: 'PREFER_NOT_TO_SAY',
  },
  CUSTOM: { label: 'Custom', value: 'CUSTOM' },
};

const PAGE_MEMBER_STATUS = {
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
} as const;

const PAGE_ROLES = {
  OWNER: {
    value: 'OWNER',
    label: 'owner',
  },
  ADMIN: {
    value: 'ADMIN',
    label: 'admin',
  },
  EDITOR: {
    value: 'EDITOR',
    label: 'editor',
  },
  RECRUITER: {
    value: 'RECRUITER',
    label: 'recruiter',
  },
  EXPERT: {
    value: 'EXPERT',
    label: 'expert',
  },
} as const;

const jobStatusKeys = {
  all: 'ALL',
  open: 'OPEN',
  closed: 'CLOSED',
  archived: 'ARCHIVE',
  unpublished: 'UNPUBLISHED',
  hired: 'HIRED',
  applied: 'APPLIED',
};

const jobSelectedKeys = {
  open: 'OPEN',
  closed: 'CLOSED',
  applied: 'APPLIED',
  hired: 'HIRED',
};

const jobStatuses = [
  {
    value: jobStatusKeys.open,
    label: 'open',
  },
  {
    value: jobStatusKeys.closed,
    label: 'closed',
  },
  {
    value: jobStatusKeys.archived,
    label: 'archived',
  },
  {
    value: jobStatusKeys.unpublished,
    label: 'unpublished',
  },
];

const schedulesEventValues = {
  MEETING: 'MEETING',
  TASK: 'TASK',
  OFF_DAY: 'OFF_DAY',
  HOLIDAY: 'HOLIDAY',
  REMINDER: 'REMINDER',
  BIRTHDAY: 'BIRTHDAY',
} as const;

export {
  QueryKeys,
  db,
  jobStatusKeys,
  jobSelectedKeys,
  jobStatuses,
  jobsDb,
  schedulesDb,
  TAB_PAGES_HEADER_SEARCH_ID,
  MAIN_CENTER_WRAPPER_ID,
  INVITE_STATUS,
  WORK_STATUS_VALUES,
  RESUME_LINK_VALUES,
  LANGUAGES_LIST_VALUES,
  TIME_FORMAT_VALUES,
  DATE_FORMAT_VALUES,
  GENDER_VALUES,
  PAGE_MEMBER_STATUS,
  PAGE_ROLES,
  CREATE_POST_BOX,
  APP_DESKTOP_FOOTER,
  schedulesEventValues,
};
