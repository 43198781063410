import type { ValueLabelType } from '../../types';

const getOccupations = (data: any): Array<ValueLabelType<string>> =>
  data?.slice(0, 6)?.map(({ title, id }: any) => ({
    value: id,
    label: title,
  }));

const searchBenefits = (data: any): Array<any> =>
  data?.map(({ title, id }: any) => ({
    value: id,
    label: title,
  }));

const lookupNormalizer = {
  getOccupations,
  searchBenefits,
};

export default lookupNormalizer;
