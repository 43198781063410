import { intervalToDuration, isAfter } from 'date-fns';

const datesDuration = ({ endDate, startDate }: any) =>
  isAfter(new Date(endDate), new Date(startDate))
    ? intervalToDuration({
        start: new Date(startDate),
        end: new Date(endDate),
      })
    : undefined;

export default datesDuration;
