import request from '../utils/request';
import { authEndpoints } from '../constants/servicesEndpoints';
import Endpoints from '../constants/endpoints';
import beforeCacheUserInfo from '../utils/normalizers/beforeCacheUserInfo';
import type { UserType } from '../types';

const getUserNameByObjectId = async ({
  objectId,
  accessToken,
}: {
  objectId: string;
  accessToken?: string;
}): Promise<any> => {
  const { data } = await request.get(authEndpoints.usernameDetector, {
    params: {
      accessToken,
      userId: objectId,
    },
  });
  return data;
};

const getAuthUser = async ({
  accessToken,
}: {
  accessToken?: string;
}): Promise<UserType> => {
  const { data } = await request.get(Endpoints.Auth.getAuthUserInfo, {
    isUserSideApi: true,
    accessToken,
  });
  return beforeCacheUserInfo(data);
};

const getUser = async ({
  params,
  accessToken,
}: {
  params: any;
  accessToken?: string;
}): Promise<any> => {
  if (!params?.username && !params?.userId) {
    throw new Error('username or userId is required');
  }

  const { data } = await request.get(
    Endpoints.App.User.Profile.getUserProfileByUserPublicID,
    { params, accessToken }
  );

  return beforeCacheUserInfo(data);
};

export { getAuthUser, getUser, getUserNameByObjectId };
