import useParams from '../hooks/useParams';
import { QueryKeys } from '../constants/enums';
import { getObjectDetail } from '../api/object';
import useReactQuery from './useReactQuery';
import type { UserType } from '../types';
import type { UseQueryResult } from '@tanstack/react-query';

export type useGetObjectDetailProps = {
  objectId?: string;
  username?: string;
  enabled?: boolean;
};

export type useGetObjectDetailType = UseQueryResult<UserType> & {
  objectDetailDataKey: Array<string> | string;
};

const useGetObjectDetail = ({
  username: propUsername,
  objectId,
  enabled = true,
}: useGetObjectDetailProps = {}): useGetObjectDetailType => {
  const params = useParams<{ username: string }>();
  const username = propUsername || params?.username;

  const objectDetailDataKey: Array<string> = [
    QueryKeys.objectDetail,
    `${username || objectId}`,
  ];

  const result = useReactQuery<UserType>({
    action: {
      key: objectDetailDataKey,
      apiFunc: getObjectDetail,
      params: {
        username,
        objectId,
        containsCroppedHeaderImageLink: true,
      },
    },
    config: {
      enabled: (!!objectId || !!username) && enabled,
    },
  });

  return {
    ...result,
    objectDetailDataKey,
  };
};

export default useGetObjectDetail;
