const ADMIN_SERVICE = '/job-service/admin';

const API_VERSION = '/api/v1';

const base = `${ADMIN_SERVICE}${API_VERSION}`;

const admin = {
  getJobsCandidatesList: `${base}/job/candidate/generate`,
};

export default admin;
