import request from '../utils/request';
import { getUser } from './user';
import { getPageDetail } from './page';
import { pageEndpoints, profileEndpoints } from '../constants';
import type { PageType, UserType } from '../types';

type GetObjectDetailProps = {
  objectId?: string;
  username?: string;
};

type GetObjectDetailTypes = UserType | PageType;

export const getObjectDetail = async ({
  params,
  accessToken,
}: {
  params: GetObjectDetailProps;
  accessToken?: string;
}): Promise<GetObjectDetailTypes> => {
  const objectId = params?.objectId;
  const username = params?.username;

  if (!objectId && !username) {
    throw new Error('objectId or username is required');
  }

  try {
    if (!username && objectId?.startsWith('1')) {
      throw Error('its a page not a person');
    }
    const user = await getUser({
      accessToken,
      params: {
        username,
        userId: objectId,
        containsCroppedHeaderImageLink: true,
      },
    });

    return user;
  } catch (err) {
    try {
      const page = await getPageDetail({
        accessToken,
        params: {
          username,
          pageId: objectId,
        },
      });
      return page;
    } catch (error) {
      throw error;
    }
  }
};

export const setAllowOthersToShareYourPosts = async ({
  pageId,
  value,
}: {
  pageId?: string;
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(
    pageId
      ? pageEndpoints.allowOthersToShareYourPosts(pageId)
      : profileEndpoints.allowOthersToShareYourPosts,
    { value }
  );

  return data;
};

export const setAllowOthersToTagOrMentionYou = async ({
  pageId,
  value,
}: {
  pageId?: string;
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(
    pageId
      ? pageEndpoints.allowOthersToTagOrMentionYou(pageId)
      : profileEndpoints.allowOthersToTagOrMentionYou,
    { value }
  );

  return data;
};

export default {
  getObjectDetail,
  setAllowOthersToShareYourPosts,
  setAllowOthersToTagOrMentionYou,
};
