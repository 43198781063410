import { QueryKeys } from '../constants/enums';
import { getPageDetail } from '../api/page';
import Cookies from '../utils/cookies';
import useReactQuery from './useReactQuery';
import getCookieKey from '../utils/getCookieKey';
import type { UseQueryResult } from '@tanstack/react-query';
import type { BeforeCachePageDetailType } from '../types';
import isServer from '@lobox/utils/utils/isServer';

export type UseBusinessPageProps = {
  isEnabled?: boolean;
};

const useBusinessPage = ({
  isEnabled,
}: UseBusinessPageProps): UseQueryResult<BeforeCachePageDetailType> => {
  // @ts-ignore
  return useReactQuery<BeforeCachePageDetailType, any>({
    action: {
      key: [QueryKeys.businessPage],
      apiFunc: async () => {
        let hostname;

        if (isServer()) {
          const headersList = (await import('next/headers')).headers();
          const host = headersList.get('host');
          hostname = host.split(':')[0];
        } else {
          hostname = window.location?.hostname;
        }

        let businessId;
        const BUSINESS_ID = getCookieKey('businessId', hostname);
        if (isServer()) {
          const _server_cookies = (await import('next/headers')).cookies();
          // @ts-ignore
          businessId = _server_cookies.get(BUSINESS_ID)?.value;
        } else {
          businessId = Cookies.get(BUSINESS_ID);
        }

        return getPageDetail({ params: { username: businessId } });
      },
    },
    config: {
      enabled: isEnabled,
      refetchOnMount: false,
    },
  });
};

export default useBusinessPage;
