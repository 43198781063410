const LOOKUP_SERVICE = '/lookup-service';
const API_VERSION = '/api/v1';

const lookup = {
  page: {
    popularCategories: `${LOOKUP_SERVICE}${API_VERSION}/industry/popular-based-pages`,
    category: (id: string): string =>
      `${LOOKUP_SERVICE}${API_VERSION}/industry/${id}`,
  },
  people: {
    popularCategories: `${LOOKUP_SERVICE}${API_VERSION}/industry/popular-based-people`,
  },

  getOccupationById: `${LOOKUP_SERVICE}${API_VERSION}/occupation`,
  getIndustryById: `${LOOKUP_SERVICE}${API_VERSION}/industry`,
  getBenefit: `${LOOKUP_SERVICE}${API_VERSION}/benefit/search`,
};

export default lookup;
