import moment from 'moment';

const formatSeconds = (
  seconds: number,
): string => {
  const utc = moment.utc(seconds * 1000);
  const hours = Number(utc.format('H'));
  const minuteSeconds = utc.format('m:ss');
  return `${hours ? `${hours}:` : ''}${minuteSeconds}`;
};

export default formatSeconds;
