import { useEffect, useRef } from 'react';

type CallbackType<T = any> = (prevDeps: T, nextDeps: T) => void;

const useComponentDidUpdate = <T = any>(
  callback: CallbackType<T>,
  deps: T
): void => {
  const mounted =
    useRef<{
      deps: T;
    }>();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = { deps };
    } else {
      callback(mounted.current.deps, deps);
      mounted.current = { deps };
    }
  }, [deps]); // eslint-disable-line
};

export default useComponentDidUpdate;
