//@ts-nocheck

import useParams from '../hooks/useParams';
import { routeNames } from '../constants';

type UseMakeCollectionExport = (collectionId: string) => string;

const useMakeCollectionRoute = (): UseMakeCollectionExport => {
  const { username } = useParams<{ username: string }>();

  return (collectionId: string): string =>
    `/${username}${routeNames.collection.makeRoute(collectionId)}`;
};

export default useMakeCollectionRoute;
