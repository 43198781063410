export const makeId = () => Math.floor(Math.random() * 1000000) + 1;
export const makePostMediaFromFiles = (files: Array<any>) => {
  let a = [];
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const url = URL.createObjectURL(file);
    const isImage = file.type.includes('image');
    const isVideo = file.type.includes('video');
    a.push({
      type: isImage ? 'image' : isVideo ? 'video' : 'unknown',
      id: makeId().toString(),
      url,
      file,
      isUploading: true,
      isUploaded: false,
      isError: false,
    });
  }
  return a;
};

export default makePostMediaFromFiles;
