import type { ILocation } from '../../types/lookup';
import type { ValueLabelType } from '../../types';

const searchLocation = (data: any): Array<ILocation> =>
  data?.content
    ?.slice(0, 6)
    ?.map(({ title, id, lat, lon, city, ...rest }: any) => ({
      ...rest,
      title,
      value: id,
      label: title,
      lat,
      lon,
      position: {
        lat: lat,
        lon: lon,
      },
      externalId: id,
      city,
      cityName: city,
    }));

const searchCountry = (data: any): Array<ValueLabelType> =>
  data?.slice(0, 6)?.map(({ title, countryCode }: any) => ({
    value: countryCode,
    label: title,
  }));

const searchCity = (data: any): Array<ValueLabelType> =>
  data?.slice(0, 6)?.map(({ name, id }: any) => ({
    value: id,
    label: name,
  }));

const searchTimezone = (data: any): Array<ValueLabelType<string>> =>
  data?.slice(0, 6)?.map(({ label, id, offset, code }: any) => ({
    value: id,
    label,
    offset,
    code,
  }));

const geoNormalizer = {
  searchLocation,
  searchTimezone,
  searchCity,
  searchCountry,
};

export default geoNormalizer;
