import request from '../utils/request';
import { postEndpoints } from '../constants/servicesEndpoints';
import type {
  PaginateResponse,
  CollectionType,
  CreateCollectionProps,
  PaginateParamType,
} from '../types';

type CollectionsListType = PaginateResponse<CollectionType>;

export const getCollectionsList = async (
  params: PaginateParamType & {
    userId?: string;
    name?: string;
  } = {}
): Promise<CollectionsListType> => {
  const { data } = await request.get(postEndpoints.collection.list, {
    params,
  });

  return data;
};
export const getCollectionItem = async (
  id: string
): Promise<CollectionsListType> => {
  const { data } = await request.get<CollectionsListType>(
    postEndpoints.collection.item(id)
  );

  return data;
};

export const createCollection = async (
  collectionData: Partial<CreateCollectionProps>
): Promise<CollectionType> => {
  const { data } = await request.post(
    postEndpoints.collection.create,
    collectionData
  );

  return data;
};

export const removeCollection = (id: string): Promise<any> =>
  request.delete(postEndpoints.collection.item(id));

export default {
  createCollection,
  getCollectionsList,
  getCollectionItem,
  removeCollection,
};
