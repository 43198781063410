import { useQuery } from '@tanstack/react-query';
import request from '../utils/request';
import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query';

type SingleKey = string | undefined;

export type UseReactQueryType = {
  action: {
    url?: string;
    key: Array<SingleKey | SingleKey[]>;
    params?: object;
    auth?: boolean;
    beforeCache?: (data: any) => any;
    apiFunc?: any;
    spreadParams?: boolean;
  };
  config?: UseQueryOptions;
};

const useReactQuery = <T = unknown, E = unknown>({
  action,
  config = {},
}: UseReactQueryType): UseQueryResult<T, E> => {
  const { apiFunc, url, key, params = {}, beforeCache, spreadParams } = action;
  const isNotDisabled =
    !('enabled' in config) || ('enabled' in config && !!config.enabled);
  if (!key && isNotDisabled) {
    console.warn('Query key is required to fetch', action);
  }
  const fetch = () =>
    url
      ? request
          .get(url, {
            params,
          })
          .then((response: any) =>
            beforeCache ? beforeCache(response.data) : response.data
          )
      : undefined;

  const query = useQuery<T, E>({
    queryKey: key,
    queryFn: (): Promise<T> =>
      apiFunc?.(spreadParams ? params : { params }) || fetch(),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 10,
    ...(config as any),
  });
  return {
    ...query,
    isLoading:
      (query.isFetching && !query?.data) || (query.isLoading && isNotDisabled),
  };
};
export default useReactQuery;
