import type { ImageResolutionType } from '../types/image';

const applyResolutionToImageSrc = (
  src: string,
  resolution: ImageResolutionType = 'small'
): string => {
  if (typeof src !== 'string') {
    return src;
  }

  return src && resolution !== 'original'
    ? src?.replace('/original/', `/${resolution}/`)
    : src;
};

export default applyResolutionToImageSrc;
