// TODO

import { useRouter } from 'next/navigation';
import useLocation from './useLocation';
import { routeNames } from '../constants';
import useClientRouter from './useClientRouter';

const useHistory = () => {
  const routerDomHistory = useRouter();
  const { pathname } = useLocation();
  const { push, replace } = useClientRouter({});

  const goBack = (): void => {
    return routerDomHistory.back();

    if (routerDomHistory.length > 1) {
      routerDomHistory.goBack();
    } else {
      routerDomHistory.push(routeNames.home);
    }
  };

  return {
    ...routerDomHistory,
    goBack,
    replace,
    push,
  };
};

export default useHistory;
