const JOB_SERVICE = '/schedule-service';

const API_VERSION = '/api/v1';

const base = `${JOB_SERVICE}${API_VERSION}`;

const schedules = {
  createMeeting: `${base}/meeting`,
  getCalendarUpcoming: `${base}/calendar/upcoming`,
  getCalendarPast: `${base}/calendar/past`,
  createReminder: `${base}/reminder`,
  createTask: `${base}/task`,
  getCalendarEvents: `${base}/calendar/events`,
  getMeetingDetails: (id: string) => `${base}/meeting/${id}`,
  getReminderDetails: (id: string) => `${base}/reminder/${id}`,
  getTaskDetails: (id: string) => `${base}/task/${id}`,
  getHolidayDetails: (id: string) => `${base}/holiday/${id}`,
  getBirthdayDetails: (id: string) => `${base}/birthday/${id}`,
  getPreferences: `${base}/preferences`,
  postTimeSheet: `${base}/preferences/timesheet`,
  deleteTimesheet: (id: string) => `${base}/preferences/timesheet/${id}`,
  getUserTimesheet: (userId: string) =>
    `${base}/preferences/timesheet/user/${userId}`,
  getBookedMeetings: `${base}/meeting/booked`,
  calendarProvider: `${base}/external/calendar-provider`,
  calendarProviderUrls: `${base}/external/calendar-provider/urls`,
  connectProvider: `${base}/external/calendar-provider/connect`,
  disconnectProvider: `${base}/external/calendar-provider/disconnect`,
  celebrateholiday: `${base}/holiday/celebrate`,
  celebrateBirthday: `${base}/birthday/celebrate`,
  removeCalendarProvider: (id: string) =>
    `${base}/external/calendar-provider/${id}`,
  uploadFile: `${base}/file/upload`,
  getFile: (id: string) => `${base}/file/download/${id}`,
  deleteFile: (id: string) => `${base}/file/${id}`,
};

export default schedules;
