// @ts-ignore
const isDev = false; // global.__DEV__;

const MESSAGE_SERVICE = isDev ? '/api/v1' : '/message-service/api/v1';

const API_ROOM = `${MESSAGE_SERVICE}/room`;
const API_ARCHIVER = `${MESSAGE_SERVICE}/archiver`;
const API_MESSAGING = `${MESSAGE_SERVICE}/messaging`;
const API_CONVERSATION = `${MESSAGE_SERVICE}/messaging/conversation`;
const API_MESSANGER = `${MESSAGE_SERVICE}/messenger`;

const messageEndPoints = {
  getJoinedRoomsList: `${API_ROOM}/joined/list/-1/10`,
  createRoom: `${API_ROOM}/create/allinone`,
  updateRoom: `${API_ROOM}/profile/update`,
  markAsAdmin: `${API_ROOM}/admin/grant`,
  removeAdmin: `${API_ROOM}/admin/revoke`,
  removeMember: `${API_ROOM}/member/kick`,
  addMember: `${API_ROOM}/member/add`,
  roomMemberInfo: ({
    groupId,
    userId,
  }: {
    groupId: string;
    userId: string;
  }): string => `${API_ROOM}/member/role/${groupId}/${userId}`,
  deleteRoom: `${API_ROOM}/delete`,
  getRoomDetail: `${API_ROOM}/profile/details`,
  getRoomMembers: `${API_ROOM}/member/list`,
  blockUser: `${API_ROOM}/block`,
  unBlockUser: `${API_ROOM}/unblock`,
  leaveRoom: `${API_ROOM}/member/leave`,
  report: `${API_ROOM}/report`,
  pinMessage: `${API_ROOM}/pin`,
  unPinMessage: `${API_ROOM}/unpin`,
  starMessage: `${API_MESSAGING}/message/star`,
  unStarMessage: `${API_MESSAGING}/message/unstar`,
  archiveMessage: `${API_CONVERSATION}/archive`,
  unArchiveMessage: `${API_CONVERSATION}/unarchive`,
  getArchivedMessageList: ({ lastId, count }: any): string =>
    `${API_CONVERSATION}/archive/list/${lastId}/${count}`,
  getStarredMessageList: `${API_MESSAGING}/message/star/list`,
  searchGroupConversation: `${API_MESSAGING}/search/muc`,
  searchOneByOneConversation: `${API_MESSAGING}/search/chat`,
  getRoomLastMessages: ({
    groupId,
    count,
  }: {
    groupId: string;
    count: number;
  }): string => `${API_ARCHIVER}/room/last/${groupId}/${count}`,
  getChatDialog: ({ lastId, count }: any): string =>
    `${API_ARCHIVER}/chat/dialog/${lastId}/${count}`,
  getOneByOneLastMessages: ({
    username,
    count,
  }: {
    username: string;
    count: number;
  }): string => `${API_ARCHIVER}/chat/last/${username}/${count}`,
  getOneByOnePreviousMessages: ({
    username,
    count,
    id,
  }: {
    username: string;
    count: number;
    id: string;
  }): string => `${API_ARCHIVER}/chat/previous/${username}/${id}/${count}`,
  getRoomPreviousMessages: ({
    username,
    count,
    id,
  }: {
    username: string;
    count: number;
    id: string;
  }): string => `${API_ARCHIVER}/room/previous/${username}/${id}/${count}`,
  clearRoomHistory: (groupId: string): string =>
    `${API_ARCHIVER}/room/clearhistory/${groupId}`,
  clearOneByOneHistory: (username: string): string =>
    `${API_ARCHIVER}/chat/clearhistory/${username}`,
  resetUnseenMessageCount: `${API_ARCHIVER}/dialog/mark/read`,
  resetAllUnseenMessageCount: `${API_ARCHIVER}/dialog/mark/read/all`,
  getPageUnseenMessageCount: `${API_ARCHIVER}/chat/dialog/stat-page`,
  getAuthUserUnseenMessageCount: `${API_ARCHIVER}/chat/dialog/stat-chat`,
  getUsersLastActivity: `${API_MESSAGING}/last/activity`,
  muteRoom: `${API_MESSANGER}/chat/mute`,
  unMuteRoom: `${API_MESSANGER}/chat/unmute`,
  setChantStatus: `${API_MESSANGER}/chat/config`,
};

export default messageEndPoints;
