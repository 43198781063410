import type { PaginateResponse } from '../types';
import request from '../utils/request';
import { searchEndPoints } from '../constants/servicesEndpoints';
import beforeCacheUserInfo from '../utils/normalizers/beforeCacheUserInfo';

export const getCandidatesList = async ({
  params,
}: any): Promise<PaginateResponse<any>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    // adminEndpoints.getJobsCandidatesList,
    searchEndPoints.searchPerson,
    {
      params: {
        ...params,
        scope: 'CANDIDATES',
      },
    }
  );

  return {
    ...data,
    content: data.content.reduce((prev: Array<any>, curr: any) => {
      const normalizedItem = beforeCacheUserInfo(curr);
      return normalizedItem.hideIt ? prev : [...prev, normalizedItem];
    }, []),
  };
};

export default {
  getCandidatesList,
};
