import request from '../utils/request';
import type {
  GenderType,
  ListResponse,
  DeviceType,
  EmailType,
  PublicSettingType,
  PrivacyValueType,
  ILoginResponse,
} from '../types';
import Endpoints from '../constants/endpoints';
import lookupApi from './lookup';
import authEndpoints from '../constants/servicesEndpoints/services/auth';
import LandingEndpoints from '../constants/LandingEndpoints';
import geoApi from '@lobox/utils/api/geo';

export const setName = async (nameData: {
  name: string;
  surname: string;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.name, nameData);

  return data;
};

export const setUsername = async (usernameData: {
  username: string;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.username, usernameData);

  return data;
};

export const setPhone = async (phoneData: {
  phone: string;
  phonePublic: boolean;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.phone, phoneData);

  return data;
};

export const setLocation = async ({ location }: any): Promise<void> => {
  const { data } = await request.put(authEndpoints.location, location);

  return data;
};

export const setOccupation = async (occupationData: {
  occupationId: string;
  occupationName: string;
  occupationPublic?: boolean;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.occupation, occupationData);

  return data;
};

export const setBirthDate = async (birthDateData: {
  birthDate: Date;
  birthDatePublic?: boolean;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.birthDate, birthDateData);

  return data;
};

export const setGender = async (genderData: {
  gender: GenderType;
  genderDescription: string;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.gender, genderData);

  return data;
};

export const deactivateMe = async (deactivationData: {
  password: string;
}): Promise<void> => {
  const { data } = await request.put(
    authEndpoints.deactivate,
    deactivationData
  );

  return data;
};

export const checkPassword = async (passData: {
  password: string;
  userId: string;
}): Promise<{ value: boolean }> => {
  const { data } = await request.post(authEndpoints.password.check, passData);

  return data;
};

export const changePassword = async (passData: {
  oldPassword: string;
  newPassword: string;
}): Promise<void> => {
  const { data } = await request.put(authEndpoints.password.change, passData);

  return data;
};

export const logout = async (deviceData?: {
  deviceId?: string;
}): Promise<void> => {
  const { data } = await request.delete(authEndpoints.logout, deviceData);

  return data;
};

export const deleteMe = async (deletionData: {
  password: string;
}): Promise<void> => {
  const { data } = await request.delete(authEndpoints.deleteMe, deletionData);

  return data;
};
export const refreshAccessToken = async (props: {
  deviceId: string;
  refresh: string;
  userId: string;
  fromPageId?: string;
}): Promise<{ value: boolean }> => {
  const { data } = await request.post(authEndpoints.refreshToken, props);

  return data;
};

export const enterToPage = async (
  params: {
    deviceId: string;
    username: string;
  },
  options?: { isUserSideApi?: boolean }
): Promise<ILoginResponse> => {
  const { data } = await request.post(
    authEndpoints.enterToPage,
    params,
    options
  );
  return data;
};

export const registerDevice = async (deviceData: {
  browserName: string;
  id: string;
  location?: string;
  osName: string;
}): Promise<any> => {
  const { data } = await request.post(
    authEndpoints.device.register,
    deviceData
  );

  return data;
};

export const getAllDevices = async (): Promise<ListResponse<DeviceType>> => {
  const { data } = await request.get(authEndpoints.device.getAll);
  const content = data?.content?.map((item: DeviceType) => {
    const location = item?.location
      ?.split(',')
      ?.filter((title: string) => !title?.includes('null'))
      .join(',');
    return {
      ...item,
      location,
    };
  });

  return {
    ...data,
    content,
  };
};

export const logoutOtherDevices = async (currentDevice: {
  deviceId: string;
}): Promise<void> => {
  const { data } = await request.delete(
    authEndpoints.device.logoutOthers,
    currentDevice
  );

  return data;
};

export const getAllEmails = async (): Promise<ListResponse<EmailType>> => {
  const { data } = await request.get(authEndpoints.email.base);

  return data;
};

type EmailArgType = {
  email: string;
};

type EmailAccessType = {
  access: PrivacyValueType;
};

export const addEmail = async (emailData: EmailArgType): Promise<void> => {
  const { data } = await request.post(authEndpoints.email.base, emailData);

  return data;
};

export const setEmailPrimary = async ({
  email,
}: EmailArgType): Promise<void> => {
  const { data } = await request.put(authEndpoints.email.primary(email));

  return data;
};

export const removeEmail = async ({ email }: EmailArgType): Promise<void> => {
  const { data } = await request.delete(authEndpoints.email.remove(email));

  return data;
};

export const resendEmailVerification = async (
  emailData: EmailArgType
): Promise<void> => {
  const { data } = await request.post(authEndpoints.email.resend, emailData);

  return data;
};

export const updateEmailPrivacy = async (
  emailData: EmailAccessType
): Promise<void> => {
  const { data } = await request.put(
    authEndpoints.email.updatePrivacy,
    emailData
  );

  return data;
};

export const getPublicSetting = async (): Promise<PublicSettingType> => {
  const { data } = await request.get(authEndpoints.setting);

  return data;
};

export const setPublicSetting = async (
  publicSettingData: PublicSettingType
): Promise<PublicSettingType> => {
  const { data } = await request.post(authEndpoints.setting, publicSettingData);

  return data;
};

export const setAllowNonLoboxUsersToFindYou = async (allowData: {
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(
    authEndpoints.setAllowNonLoboxUsersToFindYou,
    allowData
  );

  return data;
};

export const setAllowPageRoleAssign = async (allowData: {
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(
    authEndpoints.setAllowPageRoleAssign,
    allowData
  );

  return data;
};

export const forgetPassword = async (forgetData: {
  email: string;
}): Promise<void> => {
  const { data } = await request.post(
    authEndpoints.password.forgetPassword,
    forgetData
  );

  return data;
};

export const signupCompletion = async (signupData: any) => {
  const { accessToken, ...rest } = signupData;

  const { data } = await request.put(
    Endpoints.Auth.postDetail,
    {
      ...rest,
    },
    {
      accessToken,
    }
  );

  return data;
};

export const checkIsTwoFactorAuthActive = async (): Promise<boolean> => {
  const { data } = await request.get(authEndpoints.checkIsTwoFactorAuthActive);

  return data?.value;
};

export const getBackupCode = async (): Promise<boolean> => {
  const { data } = await request.get(authEndpoints.getBackupCode);

  return data;
};
export const refreshBackupCode = async (): Promise<boolean> => {
  const { data } = await request.put(authEndpoints.getBackupCode);

  return data;
};

export const enableTwoFactorAuth = async (): Promise<boolean> => {
  const { data } = await request.post(authEndpoints.enableTwoFactorAuth);

  return data;
};

export const disableTwoFactorAuth = async (
  disableData: any
): Promise<boolean> => {
  const { data } = await request.post(
    authEndpoints.disableTwoFactorAuth,
    disableData
  );

  return data;
};

export const confirmTwoFactorAuth = async (tfData: any): Promise<any> => {
  const { data } = await request.post(
    authEndpoints.confirmTwoFactorAuth,
    tfData
  );

  return data;
};

export const loginWithTwoFactorAuth = async (forgetData: {
  backupCode: string;
  deviceId: string;
  totp: string;
  totpToken: string;
  userId: 0;
}): Promise<void> => {
  const { data } = await request.post(
    authEndpoints.loginWithTwoFactorAuth,
    forgetData
  );

  return data;
};

export const getClientAppSetting = async (): Promise<boolean> => {
  const { data } = await request.get(authEndpoints.clientAppSetting);

  return data;
};

export const putClientAppSetting = async (
  clientData: any
): Promise<boolean> => {
  const { data } = await request.post(
    authEndpoints.clientAppSetting,
    clientData
  );

  return data;
};

type VerifyEmailProp = {
  verificationCode: string;
  email: string;
  deviceId: string;
};

export const verifyEmail = async ({
  verificationCode,
  email,
  deviceId,
}: VerifyEmailProp): Promise<any> => {
  const { data } = await request.post(LandingEndpoints.Auth.checkToken, {
    verificationCode,
    email,
    deviceId,
  });
  return data;
};

export const postSignup = async ({
  xemail,
  xpassword,
}: {
  xemail: string;
  xpassword: string;
}): Promise<any> => {
  const { data } = await request.post(LandingEndpoints.Auth.postEmail, {
    email: xemail,
    password: xpassword,
  });
  return data;
};

export default {
  setName,
  setUsername,
  setPhone,
  setLocation,
  setOccupation,
  setBirthDate,
  setGender,
  deactivateMe,
  checkPassword,
  changePassword,
  logout,
  deleteMe,
  registerDevice,
  getAllDevices,
  logoutOtherDevices,
  getAllEmails,
  addEmail,
  setEmailPrimary,
  removeEmail,
  resendEmailVerification,
  getPublicSetting,
  setPublicSetting,
  setAllowNonLoboxUsersToFindYou,
  setAllowPageRoleAssign,
  forgetPassword,
  enterToPage,
  checkIsTwoFactorAuthActive,
  enableTwoFactorAuth,
  confirmTwoFactorAuth,
  getBackupCode,
  disableTwoFactorAuth,
  loginWithTwoFactorAuth,
  refreshBackupCode,
  getClientAppSetting,
  putClientAppSetting,
  verifyEmail,
  postSignup,
};
