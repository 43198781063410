export const mainRoutes = {
  home: '/',
  middle: '/lb',
  feedDetail: '/feed',
  profile: '/:username',
  page: '/page',
  pages: '/pages',
  people: '/people',
  invite: '/invite',
  create: '/create',
  messages: '/messages',
  settings: '/settings',
  notifications: '/notifications',
  search: '/search',
  searchCenter: '/filter',
  explore: '/explore',
  jobs: '/jobs',
  login: '/login',
  signup: '/signup',
  welcome: '/welcome',
  verify: '/signup/verify',
  aboutUs: '/about-us',
  meetTheTeam: '/meet-the-team',
  helpCenter: '/help-center',
  privacySecurity: '/privacy-security',
  businessSolutions: '/business-solutions',
  schedules: '/schedules',
  groups: '/groups',
};

export const routeNames = {
  home: mainRoutes.home,
  notifications: mainRoutes.notifications,
  middleScreen: {
    route: `${mainRoutes.middle}/:objectId`,
    unavailable: `${mainRoutes.middle}/unavailable`,
    makeRoute: (objectId: string): string => `${mainRoutes.middle}/${objectId}`,
  },
  feedDetail: {
    route: `${mainRoutes.feedDetail}/:feedId`,
    makeRoute: (feedId: string): string => `${mainRoutes.feedDetail}/${feedId}`,
  },
  profile: mainRoutes.profile,
  page: mainRoutes.page,
  dashboard: '/dashboard',
  about: '/about',
  pageCreation: '/creation',
  feed: '/feed',
  profilePages: '/pages',
  hashtags: '/hashtags',
  collections: '/collections',
  collection: {
    route: '/collections/:collectionId',
    makeRoute: (collectionId: string): string => `/collections/${collectionId}`,
  },
  editProfile: '/edit-profile',
  experience: '/experience',
  people: mainRoutes.people,
  peopleDiscover: `${mainRoutes.people}/discover`,
  peopleFollowers: `${mainRoutes.people}/followers`,
  peopleFollowing: `${mainRoutes.people}/following`,
  candidates: `${mainRoutes.people}/candidates`,
  pages: mainRoutes.pages,
  pagesDiscover: `${mainRoutes.pages}/discover`,
  pagesYouManage: `${mainRoutes.pages}/pages-you-manage`,
  pagesFollowers: `${mainRoutes.pages}/followers`,
  pagesFollowing: `${mainRoutes.pages}/following`,
  pagesCategory: {
    main: `${mainRoutes.pages}/category`,
    route: `${mainRoutes.pages}/category/:categoryId`,
    makeRoute: (categoryId: string): string =>
      `${mainRoutes.pages}/category/${categoryId}`,
  },
  peoplePendingRequests: `${mainRoutes.people}/pending-requests`,
  peopleFollowRequests: `${mainRoutes.people}/follow-requests`,
  peopleTopSuggestions: `${mainRoutes.people}/top-suggestions`,
  pagesTopSuggestions: `${mainRoutes.pages}/top-suggestions`,
  pagesFollowRequests: `${mainRoutes.pages}/follow-requests`,
  allPeople: `${mainRoutes.people}/all-people`,
  allPages: `${mainRoutes.pages}/all-pages`,
  popularPeople: `${mainRoutes.people}/popular-people`,
  popularPages: `${mainRoutes.pages}/popular-pages`,
  invite: mainRoutes.invite,
  inviteByEmail: `${mainRoutes.invite}/by-email`,
  inviteHome: mainRoutes.invite,
  inviteDiscover: `${mainRoutes.invite}/discover`,
  inviteConnect: `${mainRoutes.invite}/connect`,
  jobs: mainRoutes.jobs,
  openJobs: `${mainRoutes.jobs}/open`,
  closedJobs: `${mainRoutes.jobs}/closed`,
  archivedJobs: `${mainRoutes.jobs}/archived`,
  unpublishedJobs: `${mainRoutes.jobs}/unpublished`,
  jobCreation: `${mainRoutes.jobs}/creation`,
  jobDetails: {
    route: `${mainRoutes.jobs}/job-details/:jobId`,
    makeRoute: (jobId: string): string =>
      `${mainRoutes.jobs}/job-details/${jobId}`,
  },
  jobsComing: {
    route: `${mainRoutes.jobs}/coming/:jobId`,
    makeRoute: (jobId: string): string => `${mainRoutes.jobs}/coming/${jobId}`,
  },
  discoverJobs: `${mainRoutes.jobs}/discover`,
  appliedJobs: `${mainRoutes.jobs}/applied`,
  allAppliedJobs: `${mainRoutes.jobs}/all-applied`,
  allSavedJobs: `${mainRoutes.jobs}/all-saved`,
  savedJobs: `${mainRoutes.jobs}/saved`,
  topSuggestionJobs: `${mainRoutes.jobs}/top-suggestion`,
  popularCategories: `${mainRoutes.jobs}/popular-categories`,
  popularJobs: `${mainRoutes.jobs}/popular-jobs`,
  allJobs: `${mainRoutes.jobs}/all`,
  recentSearch: `${mainRoutes.jobs}/recent-search`,
  jobCategory: {
    main: `${mainRoutes.jobs}/category`,
    route: `${mainRoutes.jobs}/category/:categoryId`,
    makeRoute: (jobId: string): string =>
      `${mainRoutes.jobs}/category/${jobId}`,
  },
  jobsTopSuggestions: `${mainRoutes.jobs}/top-suggestions`,
  schedules: mainRoutes.schedules,
  schedulesCalendar: `${mainRoutes.schedules}/calendar`,
  schedulesCalendarYear: `${mainRoutes.schedules}/calendar/year`,
  schedulesCalendarMonth: `${mainRoutes.schedules}/calendar/month`,
  schedulesCalendarWeek: `${mainRoutes.schedules}/calendar/week`,
  schedulesCalendarDay: `${mainRoutes.schedules}/calendar/day`,
  schedulesMeetings: `${mainRoutes.schedules}/meetings`,
  schedulesEvents: `${mainRoutes.schedules}/events`,
  createSchedule: `${mainRoutes.schedules}/create`,
  schedulesReminders: `${mainRoutes.schedules}/reminders`,
  schedulesTasks: `${mainRoutes.schedules}/tasks`,
  schedulesAvailability: `${mainRoutes.schedules}/availability`,
  search: mainRoutes.search,
  searchAll: `${mainRoutes.search}/all`,
  searchPeople: `${mainRoutes.search}${mainRoutes.people}`,
  searchPages: `${mainRoutes.search}${mainRoutes.pages}`,
  searchJobs: `${mainRoutes.search}${mainRoutes.jobs}`,
  searchGroups: `${mainRoutes.searchCenter}${mainRoutes.groups}`,
  searchPosts: `${mainRoutes.search}/posts`,
  searchHashtags: `${mainRoutes.search}/hashtags`,
  messages: mainRoutes.messages,
  settings: mainRoutes.settings,
  settingsProfileDetail: `${mainRoutes.settings}/profile-details`,
  settingsAccount: `${mainRoutes.settings}/account`,
  settingsDeleteAccount: `${mainRoutes.settings}/account/deletion`,
  settingsSecurity: `${mainRoutes.settings}/security-login`,
  settingsContactInfo: `${mainRoutes.settings}/contact-information`,
  settingsCareerPref: `${mainRoutes.settings}/career-preferences`,
  settingsPreferences: `${mainRoutes.settings}/preferences`,
  settingsSchedulePref: `${mainRoutes.settings}/preferences/schedule`,
  settingsScheduleCalendar: `${mainRoutes.settings}/preferences/schedule/calendar`,
  settingsScheduleAvailability: `${mainRoutes.settings}/preferences/schedule/availability`,
  settingsPrivacy: `${mainRoutes.settings}/privacy`,
  settingsLocalization: `${mainRoutes.settings}/language-localization`,
  settingsFeedPref: `${mainRoutes.settings}/feed-preferences`,
  settingsHiddenPosts: `${mainRoutes.settings}/feed-preferences/hidden-posts`,
  settingsSync: `${mainRoutes.settings}/synchronization`,
  settingsNotification: {
    main: `${mainRoutes.settings}/notification-settings`,
    route: `${mainRoutes.settings}/notification-settings/:notification`,
    makeRoute: (subRouteName: string): string =>
      `${mainRoutes.settings}/notification-settings/${subRouteName}`,
  },
  settingsReviewContent: `${mainRoutes.settings}/privacy/review-content`,
  settingsEditProfileVisibility: `${mainRoutes.settings}/privacy/edit-profile-visibility`,
  settingsPageDetail: `${mainRoutes.settings}/page-details`,
  settingsPageAccount: `${mainRoutes.settings}/account`,
  settingsPageContactInfo: `${mainRoutes.settings}/contact-information`,
  settingsPagePrivacy: `${mainRoutes.settings}/privacy`,
  settingsPageFeedPref: `${mainRoutes.settings}/feed-preferences`,
  settingsPageRoles: `${mainRoutes.settings}/page-roles`,
  settingsSubscription: `${mainRoutes.settings}/subscription-pay`,
  // settingsPageNotification: `${mainRoutes.settings}/notification-settings`,
  // settingsPageNotificationStatus: `${mainRoutes.settings}/notification-settings/:notification`,
};

export const fullNavigationPagesList = [
  mainRoutes.home,
  mainRoutes.people,
  mainRoutes.messages,
  mainRoutes.pages,
  mainRoutes.search,
  mainRoutes.feedDetail,
  // mainRoutes.jobs,
];

export const objectPageNeedsAuthTabsList = [routeNames.dashboard];

export const sectionsIds = {
  whoWeAre: 'who-we-are',
  ourValues: 'our-values',
  career: 'career',
  products: 'products',
  pricing: 'pricing',
  support: 'support',
  popularTopics: 'popularTopics',
  faq: 'faq',
  feedback: 'feedback',
  termOfService: 'term-of-service',
  whoMayUseTheServices: 'who-may-use-the-services',
  contentOnTheServices: 'content-on-the-services',
  disclaimers: 'disclaimers-and-limitations-of-liability',
  general: 'general',
  basicAccountInformation: 'basic-account-information',
  publicInformation: 'public-information',
  contactInformation: 'contact-information-and-address-books',
  directMessages: 'direct-messages-and-non-public-communications',
  paymentInformation: 'payment-information',
  controlInformation: 'control-information',
  ourCookies: 'our-use-of-cookies',
  aboutSecurity: 'about-account-security',
};

export const landingRouteNames = {
  // landing routes
  prime: '/',
  findJob: '/find-job',
  followIndustry: '/follow-industry',
  careerManagement: '/career-management',
  unsubscribe: '/unsubscribe',
  explore: mainRoutes.explore,
  explorePeople: `${mainRoutes.explore}${mainRoutes.people}`,
  explorePages: `${mainRoutes.explore}${mainRoutes.pages}`,

  // login routes
  login: mainRoutes.login,
  forgetPassword: `${mainRoutes.login}/forgot-password`,
  twoFactorAuthGetCode: `${mainRoutes.login}/two-factor-authentication`,
  twoFactorAuthBackupCode: `${mainRoutes.login}/two-factor-backup-code`,
  resetPassword: `${mainRoutes.login}/reset-password/:token`,
  code: `${mainRoutes.login}/code`,

  // signup routes
  signup: mainRoutes.signup,
  getName: `${mainRoutes.signup}/get-name`,
  getCountry: `${mainRoutes.signup}/get-country`,
  getCode: `${mainRoutes.signup}/code`,
  resend: `${mainRoutes.signup}/resend`,
  review: `${mainRoutes.signup}/review`,
  secondVerify: `${mainRoutes.signup}/second-verify`,
  verify: `${mainRoutes.signup}/verify/:token`,
  welcome: mainRoutes.welcome,
  oauth2: `${mainRoutes.login}/oauth2`,

  // footer routes
  aboutUs: '/about-us',
  aboutUs_whoWeAre: `${mainRoutes.aboutUs}/#${sectionsIds.whoWeAre}`,
  aboutUs_ourValues: `${mainRoutes.aboutUs}/#${sectionsIds.ourValues}`,
  aboutUs_career: `${mainRoutes.aboutUs}/#${sectionsIds.career}`,
  meetTheTeam: '/meet-the-team',
  businessSolutions: '/business-solutions',
  businessSolutions_products: `${mainRoutes.businessSolutions}/#${sectionsIds.products}`,
  businessSolutions_pricing: `${mainRoutes.businessSolutions}/#${sectionsIds.pricing}`,
  businessSolutions_support: `${mainRoutes.businessSolutions}/#${sectionsIds.support}`,

  // help center
  helpCenter: mainRoutes.helpCenter,
  helpCenter_popularTopics: `${mainRoutes.helpCenter}/#${sectionsIds.popularTopics}`,
  helpCenter_faq: `${mainRoutes.helpCenter}/#${sectionsIds.faq}`,
  helpCenter_feedback: `${mainRoutes.helpCenter}/#${sectionsIds.feedback}`,
  accountSetting: `${mainRoutes.helpCenter}/account-setting`,
  loginPassword: `${mainRoutes.helpCenter}/login-password`,
  helpPrivacySecurity: `${mainRoutes.helpCenter}/privacy-security`,
  careerPreferences: `${mainRoutes.helpCenter}/career-preferences`,
  helpPages: `${mainRoutes.helpCenter}/pages`,
  helpPeople: `${mainRoutes.helpCenter}/people`,

  // privacy and security
  privacySecurity: mainRoutes.privacySecurity,
  userAgreement: `${mainRoutes.privacySecurity}/user-agreement`,
  userAgreement_termOfService: `${mainRoutes.privacySecurity}/user-agreement/#${sectionsIds.termOfService}`,
  userAgreement_whoMayUseTheServices: `${mainRoutes.privacySecurity}/user-agreement/#${sectionsIds.whoMayUseTheServices}`,
  userAgreement_contentOnTheServices: `${mainRoutes.privacySecurity}/user-agreement/#${sectionsIds.contentOnTheServices}`,
  userAgreement_disclaimers: `${mainRoutes.privacySecurity}/user-agreement/#${sectionsIds.disclaimers}`,
  userAgreement_general: `${mainRoutes.privacySecurity}/user-agreement/#${sectionsIds.general}`,
  privacy: `${mainRoutes.privacySecurity}/privacy`,
  privacy_basicAccountInformation: `${mainRoutes.privacySecurity}/privacy/#${sectionsIds.basicAccountInformation}`,
  privacy_publicInformation: `${mainRoutes.privacySecurity}/privacy/#${sectionsIds.publicInformation}`,
  privacy_contactInformation: `${mainRoutes.privacySecurity}/privacy/#${sectionsIds.contactInformation}`,
  privacy_directMessages: `${mainRoutes.privacySecurity}/privacy/#${sectionsIds.directMessages}`,
  privacy_paymentInformation: `${mainRoutes.privacySecurity}/privacy/#${sectionsIds.paymentInformation}`,
  privacy_controlInformation: `${mainRoutes.privacySecurity}/privacy/#${sectionsIds.controlInformation}`,
  aboutCookies: `${mainRoutes.privacySecurity}/about-cookies`,
  aboutCookies_ourCookies: `${mainRoutes.privacySecurity}/about-cookies/#${sectionsIds.ourCookies}`,
  security: `${mainRoutes.privacySecurity}/security`,
  security_aboutSecurity: `${mainRoutes.privacySecurity}/security/#${sectionsIds.aboutSecurity}`,
};

export const needsAuthPages = [
  mainRoutes.notifications,
  mainRoutes.people,
  mainRoutes.messages,
  routeNames.middleScreen.unavailable,
  mainRoutes.settings,
  mainRoutes.page + routeNames.pageCreation,
  mainRoutes.invite,
  mainRoutes.pages,
  mainRoutes.search,
  mainRoutes.feedDetail,
  mainRoutes.jobs,
  mainRoutes.searchCenter,
  mainRoutes.schedules,
  landingRouteNames.welcome,
];

export const forbiddenForAuthUsersPages = [
  landingRouteNames.findJob,
  landingRouteNames.followIndustry,
  landingRouteNames.careerManagement,
  landingRouteNames.unsubscribe,
  landingRouteNames.explore,
  landingRouteNames.explorePeople,
  landingRouteNames.explorePages,

  landingRouteNames.login,
  landingRouteNames.forgetPassword,
  landingRouteNames.twoFactorAuthGetCode,
  landingRouteNames.twoFactorAuthBackupCode,
  landingRouteNames.resetPassword,
  landingRouteNames.code,
  landingRouteNames.signup,
  landingRouteNames.getName,
  landingRouteNames.getCountry,
  landingRouteNames.getCode,
  landingRouteNames.resend,
  landingRouteNames.review,
  landingRouteNames.secondVerify,
  landingRouteNames.verify,
  landingRouteNames.welcome,
  landingRouteNames.oauth2,
];

export const doesNotNeedAuthPages = [
  routeNames.searchJobs,
  routeNames.schedulesAvailability,
];

export const defaultRoutesOfSections = {
  jobs: {
    user: routeNames.discoverJobs,
    business: routeNames.openJobs,
  },
  people: routeNames.peopleDiscover,
  pages: routeNames.pagesDiscover,
};
