import { __spreadArray } from 'tslib';

export const meetingTools = [
  {
    value: 'LOBOX',
    label: 'Lobox',
  },
  {
    value: 'GOOGLE',
    label: 'Google',
  },
  {
    value: 'ZOOM',
    label: 'Zoom',
  },
  {
    value: 'TEAMS',
    label: 'Teams',
  },
  { value: 'WEBEX', label: 'Webex' },
] as const;

export const meetingModel = [
  {
    value: 'REMOTE',
    label: 'Remote',
  },
  {
    value: 'ON_SITE',
    label: 'On-site',
  },
];

export const meetingDuration = [
  {
    value: '_15_MINUTES',
    label: '15 minutes',
    shortLabel: '15min.',
    amount: 30 * 60 * 1000,
  },
  {
    value: '_30_MINUTES',
    label: '30 minutes',
    shortLabel: '30min.',
    amount: 30 * 60 * 1000,
  },
  {
    value: '_60_MINUTES',
    label: '60 minutes',
    shortLabel: '60min.',
    amount: 60 * 60 * 1000,
  },
  {
    value: '_120_MINUTES',
    label: '120 minutes',
    shortLabel: '120min.',
    amount: 2 * 60 * 60 * 1000,
  },
];

export const meetingType = [
  { value: 'ONE_TO_ONE', label: 'One-on-one meeting' },
  { value: 'GROUP', label: 'Group meeting' },
];

export const meetingReminder = [
  { value: '_5_MIN_BEFORE', label: '5 min. before' },
  { value: '_10_MIN_BEFORE', label: '10 min. before' },
  { value: '_15_MIN_BEFORE', label: '15 min. before' },
  { value: '_30_MIN_BEFORE', label: '30 min. before' },
  { value: '_1_HOUR_BEFORE', label: '1 hour before' },
  { value: '_1_DAY_BEFORE', label: '1 day before' },
];

export const reminderRepeat = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY_ON_FRIDAY', label: 'Weekly on Friday' },
  {
    value: 'MONTHLY_ON_THE_THIRD_FRIDAY',
    label: 'Monthly on the third Friday',
  },
  { value: 'ANNUALLY_ON_JANUARY_19', label: 'Annually on January 19' },
  { value: 'EVERY_WEEKDAY', label: 'Every weekday (Monday to Friday)' },
  { value: 'CUSTOM', label: 'Custom' },
];

export const calendarTypes = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Yearly', value: 'YEARLY' },
];

export const weekDays = [
  { label: 'Sunday', value: 'SUNDAY' },
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
];

export const permissions = {
  MODIFY_MEETING: 'MODIFY_MEETING',
  MODIFY_TASK: 'MODIFY_TASK',
};

export const timeOptions = [
  { label: '00:00', value: '00:00' },
  { label: '00:15', value: '00:15' },
  { label: '00:30', value: '00:30' },
  { label: '00:45', value: '00:45' },
  { label: '01:00', value: '01:00' },
  { label: '01:15', value: '01:15' },
  { label: '01:30', value: '01:30' },
  { label: '01:45', value: '01:45' },
  { label: '02:00', value: '02:00' },
  { label: '02:15', value: '02:15' },
  { label: '02:30', value: '02:30' },
  { label: '02:45', value: '02:45' },
  { label: '03:00', value: '03:00' },
  { label: '03:15', value: '03:15' },
  { label: '03:30', value: '03:30' },
  { label: '03:45', value: '03:45' },
  { label: '04:00', value: '04:00' },
  { label: '04:15', value: '04:15' },
  { label: '04:30', value: '04:30' },
  { label: '04:45', value: '04:45' },
  { label: '05:00', value: '05:00' },
  { label: '05:15', value: '05:15' },
  { label: '05:30', value: '05:30' },
  { label: '05:45', value: '05:45' },
  { label: '06:00', value: '06:00' },
  { label: '06:15', value: '06:15' },
  { label: '06:30', value: '06:30' },
  { label: '06:45', value: '06:45' },
  { label: '07:00', value: '07:00' },
  { label: '07:15', value: '07:15' },
  { label: '07:30', value: '07:30' },
  { label: '07:45', value: '07:45' },
  { label: '08:00', value: '08:00' },
  { label: '08:15', value: '08:15' },
  { label: '08:30', value: '08:30' },
  { label: '08:45', value: '08:45' },
  { label: '09:00', value: '09:00' },
  { label: '09:15', value: '09:15' },
  { label: '09:30', value: '09:30' },
  { label: '09:45', value: '09:45' },
  { label: '10:00', value: '10:00' },
  { label: '10:15', value: '10:15' },
  { label: '10:30', value: '10:30' },
  { label: '10:45', value: '10:45' },
  { label: '11:00', value: '11:00' },
  { label: '11:15', value: '11:15' },
  { label: '11:30', value: '11:30' },
  { label: '11:45', value: '11:45' },
  { label: '12:00', value: '12:00' },
  { label: '12:15', value: '12:15' },
  { label: '12:30', value: '12:30' },
  { label: '12:45', value: '12:45' },
  { label: '13:00', value: '13:00' },
  { label: '13:15', value: '13:15' },
  { label: '13:30', value: '13:30' },
  { label: '13:45', value: '13:45' },
  { label: '14:00', value: '14:00' },
  { label: '14:15', value: '14:15' },
  { label: '14:30', value: '14:30' },
  { label: '14:45', value: '14:45' },
  { label: '15:00', value: '15:00' },
  { label: '15:15', value: '15:15' },
  { label: '15:30', value: '15:30' },
  { label: '15:45', value: '15:45' },
  { label: '16:00', value: '16:00' },
  { label: '16:15', value: '16:15' },
  { label: '16:30', value: '16:30' },
  { label: '16:45', value: '16:45' },
  { label: '17:00', value: '17:00' },
  { label: '17:15', value: '17:15' },
  { label: '17:30', value: '17:30' },
  { label: '17:45', value: '17:45' },
  { label: '18:00', value: '18:00' },
  { label: '18:15', value: '18:15' },
  { label: '18:30', value: '18:30' },
  { label: '18:45', value: '18:45' },
  { label: '19:00', value: '19:00' },
  { label: '19:15', value: '19:15' },
  { label: '19:30', value: '19:30' },
  { label: '19:45', value: '19:45' },
  { label: '20:00', value: '20:00' },
  { label: '20:15', value: '20:15' },
  { label: '20:30', value: '20:30' },
  { label: '20:45', value: '20:45' },
  { label: '21:00', value: '21:00' },
  { label: '21:15', value: '21:15' },
  { label: '21:30', value: '21:30' },
  { label: '21:45', value: '21:45' },
  { label: '22:00', value: '22:00' },
  { label: '22:15', value: '22:15' },
  { label: '22:30', value: '22:30' },
  { label: '22:45', value: '22:45' },
  { label: '23:00', value: '23:00' },
  { label: '23:15', value: '23:15' },
  { label: '23:30', value: '23:30' },
  { label: '23:45', value: '23:45' },
];

export const timeOptionsEnd = __spreadArray(timeOptions.slice(1), [
  {
    label: '00:00',
    value: '23:59',
  },
]);
