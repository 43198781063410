import { IJob, PaginateResponse } from '..';
import { depotsEndpoints } from '../constants';
import { request } from '../utils';

export const getHistory = async ({ params }: any): Promise<any> => {
  const { data } = await request.get<any>(depotsEndpoints.getHistory, {
    params,
  });
  return data;
};

export const deleteSearchHistory = async ({
  key,
}: {
  key: string;
}): Promise<any> => {
  const url = depotsEndpoints.deleteSearchHistory(key);
  const { data } = await request.delete(url);
  return data;
};

export const deleteAllSearchHistory = async ({
  type,
}: {
  type: Parameters<typeof depotsEndpoints.deleteAllSearchHistory>[0];
}): Promise<any> => {
  const url = depotsEndpoints.deleteAllSearchHistory(type);
  const { data } = await request.delete(url);
  return data;
};

type SearchedItem = {
  croppedImageUrl: string;
  entityType: 'PERSON';
  id: number | string;
  locationId: string;
  locationName: string;
  moduleType: 'GLOBAL';
  name: string;
  surname: string;
  title: string;
  username: string;
};
export const addSearchHistory = async (
  searchedItem: SearchedItem
): Promise<any> => {
  const { data } = await request.post(
    depotsEndpoints.addSearchHistory,
    searchedItem
  );
  return data;
};

export const getJobsRecentSearches = async ({
  params,
}: {
  params: {
    page: number;
    pageSize: number;
  };
}): Promise<PaginateResponse<IJob>> => {
  const { data } = await request.get<PaginateResponse<any>>(
    depotsEndpoints.getJobHistory,
    {
      params,
    }
  );
  return {
    ...data,
    content: data?.content?.map((item) => ({
      ...item,
      location: item?.locationName,
      placeId: item?.locationId,
      text: item?.title,
    })),
  };
};

export default {
  getHistory,
  deleteAllSearchHistory,
  deleteSearchHistory,
  addSearchHistory,
  getJobsRecentSearches,
};
