const NOTIFICATION_SERVICE = '/notification-service';
const API_VERSION = '/api/v1';

const base = `${NOTIFICATION_SERVICE}${API_VERSION}`;

const notification = {
  unSeenCount: `${base}/badge-helpers`,
  all: `${base}/notifications`,
  seenAll: `${base}/seen/all`,
  opened: `${base}/opened`,
  seenOne: (notifId: string): string => `${base}/seen/${notifId}`,
  unseenOne: (notifId: string): string => `${base}/unseen/${notifId}`,
  delete: (notifId: string): string => `${base}/notifications/${notifId}`,
  setting: `${NOTIFICATION_SERVICE}${API_VERSION}/setting`,
  getMyPagesNotifications: `${base}/pages-badge-helpers`,
};

export default notification;
