import type { PageCategoryType } from '../../types';
import type { PageCompanySizeType } from '../../types/page';
import translateReplacer from '../../utils/translateReplacer';

function upperFirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const COMPANY_SIZE_TYPES: Array<{
  value: PageCompanySizeType;
  label: string;
}> = [
  { value: 'A', label: 'COMPANY_SIZE_A' },
  { value: 'B', label: 'COMPANY_SIZE_B' },
  { value: 'C', label: 'COMPANY_SIZE_C' },
  { value: 'D', label: 'COMPANY_SIZE_D' },
  { value: 'E', label: 'COMPANY_SIZE_E' },
  { value: 'F', label: 'COMPANY_SIZE_F' },
  { value: 'G', label: 'COMPANY_SIZE_G' },
  { value: 'H', label: 'COMPANY_SIZE_H' },
];

export const CATEGORY_TYPES: Array<{
  value: PageCategoryType;
  label: string;
}> = [
  { value: 'ARTIST', label: 'COMPANY_TYPE_ARTIST' },
  { value: 'COMMUNITY', label: 'COMPANY_TYPE_COMMUNITY' },
  { value: 'COMPANY', label: 'COMPANY_TYPE_COMPANY' },
  {
    value: 'EDUCATION_INSTITUTION',
    label: 'COMPANY_TYPE_EDUCATION_INSTITUTION',
  },
  { value: 'INSTITUTION', label: 'COMPANY_TYPE_INSTITUTION' },
  {
    value: 'NON_PROFIT_ORGANIZATION',
    label: 'COMPANY_TYPE_NON_PROFIT_ORGANIZATION',
  },
  { value: 'PUBLIC_FIGURE', label: 'COMPANY_TYPE_PUBLIC_FIGURE' },
  { value: 'PUBLIC_SERVICE', label: 'COMPANY_TYPE_PUBLIC_SERVICE' },
];
export const EMPLOYMENT_TYPES = [
  { value: 'FULL_TIME', label: 'FULL_TIME' },
  { value: 'PART_TIME', label: 'PART_TIME' },
  { value: 'SELF_EMPLOYED', label: 'SELF_EMPLOYED' },
  { value: 'FREELANCE', label: 'FREELANCE' },
  { value: 'CONTRACT', label: 'CONTRACT' },
  { value: 'CONTRACT_TO_HIRE', label: 'CONTRACT_TO_HIRE' },
  { value: 'INTERNSHIP', label: 'INTERNSHIP' },
  { value: 'APPRENTICESHIP', label: 'APPRENTICESHIP' },
  { value: 'SEASONAL', label: 'SEASONAL' },
];
export const DEGREE_OPTIONS = [
  { value: 'ELEMENTARY', label: 'ELEMENTARY' },
  { value: 'DIPLOMA', label: 'DIPLOMA' },
  { value: 'MIDDLE_SCHOOL', label: 'MIDDLE_SCHOOL' },
  { value: 'HIGH_SCHOOL', label: 'HIGH_SCHOOL' },
  { value: 'ASSOCIATE', label: 'ASSOCIATE' },
  { value: 'BACHELOR', label: 'BACHELOR' },
  { value: 'MASTER', label: 'MASTER' },
  { value: 'DOCTORAL', label: 'DOCTORAL' },
  { value: 'POSTDOCTORAL', label: 'POSTDOCTORAL' },
  { value: 'PROFESSIONAL', label: 'PROFESSIONAL' },
  { value: 'HONORARY', label: 'HONORARY' },
  { value: 'NON', label: 'NON' },
];

export const CAUSE_OPTIONS = [
  {
    value: 'ENVIRONMENT_AND_SUSTAINABILITY',
    label: 'ENVIRONMENT_AND_SUSTAINABILITY',
  },
  { value: 'EDUCATION_AND_LITERACY', label: 'EDUCATION_AND_LITERACY' },
  { value: 'POVERTY_ALLEVIATION', label: 'POVERTY_ALLEVIATION' },
  { value: 'HEALTH_AND_WELLNESS', label: 'HEALTH_AND_WELLNESS' },
  { value: 'ANIMAL_WELFARE', label: 'ANIMAL_WELFARE' },
  { value: 'COMMUNITY_DEVELOPMENT', label: 'COMMUNITY_DEVELOPMENT' },
  {
    value: 'HUMAN_RIGHTS_AND_SOCIAL_JUSTICE',
    label: 'HUMAN_RIGHTS_AND_SOCIAL_JUSTICE',
  },
  {
    value: 'DISASTER_RELIEF_AND_EMERGENCY_RESPONSE',
    label: 'DISASTER_RELIEF_AND_EMERGENCY_RESPONSE',
  },
  { value: 'ARTS_AND_CULTURE', label: 'ARTS_AND_CULTURE' },
  {
    value: 'ELDERLY_CARE_AND_SUPPORT',
    label: 'ELDERLY_CARE_AND_SUPPORT',
  },
  {
    value: 'CHILDREN_AND_YOUTH_EMPOWERMENT',
    label: 'CHILDREN_AND_YOUTH_EMPOWERMENT',
  },
  {
    value: 'GENDER_EQUALITY_AND_WOMEN_RIGHTS',
    label: 'GENDER_EQUALITY_AND_WOMEN_RIGHTS',
  },
  {
    value: 'HOMELESSNESS_AND_HOUSING_SUPPORT',
    label: 'HOMELESSNESS_AND_HOUSING_SUPPORT',
  },
  {
    value: 'FOOD_SECURITY_AND_HUNGER_RELIEF',
    label: 'FOOD_SECURITY_AND_HUNGER_RELIEF',
  },
  {
    value: 'MENTAL_HEALTH_AND_WELLBEING',
    label: 'MENTAL_HEALTH_AND_WELLBEING',
  },
  {
    value: 'DISABILITY_RIGHTS_AND_INCLUSION',
    label: 'DISABILITY_RIGHTS_AND_INCLUSION',
  },
  {
    value: 'TECHNOLOGY_AND_DIGITAL_LITERACY',
    label: 'TECHNOLOGY_AND_DIGITAL_LITERACY',
  },
  {
    value: 'CONSERVATION_AND_WILDLIFE_PROTECTION',
    label: 'CONSERVATION_AND_WILDLIFE_PROTECTION',
  },
  {
    value: 'LGBTQ_RIGHTS_AND_ADVOCACY',
    label: 'LGBTQ_RIGHTS_AND_ADVOCACY',
  },
  {
    value: 'INDIGENOUS_RIGHTS_AND_CULTURAL_PRESERVATION',
    label: 'INDIGENOUS_RIGHTS_AND_CULTURAL_PRESERVATION',
  },
];

export const LANGUAGE_LEVELS = [
  { value: 'A1', label: 'a1' },
  { value: 'A2', label: 'a2' },
  { value: 'B1', label: 'b1' },
  { value: 'B2', label: 'b2' },
  { value: 'C1', label: 'c1' },
  { value: 'C2', label: 'c2' },
  { value: 'Native', label: 'native' },
];

export const SKILL_LEVELS = [
  { value: 'BEGINNER', label: 'beginner' },
  { value: 'INTERMEDIATE', label: 'intermediate' },
  { value: 'ADVANCED', label: 'advanced' },
  { value: 'EXPERT', label: 'expert' },
];

export const POST_TYPE = [
  { value: 'PHOTO', label: 'PHOTO' },
  { value: 'VIDEO', label: 'VIDEO' },
  { value: 'TEXT', label: 'TEXT' },
  { value: 'CHECK_IN', label: 'CHECK_IN' },
  { value: 'HIGHLIGHT', label: 'HIGHLIGHT' },
];

export const SCOPE = [
  { value: 'ALL', label: 'ALL' },
  { value: 'APPLICANTS', label: 'APPLICANTS' },
  { value: 'CANDIDATES', label: 'CANDIDATES' },
];

export const RELATIONSHIP_OPTIONS = (
  username: string,
  visitor = 'you',
  t: (K: string) => string
): Record<string, any>[] => [
  {
    value: 'Wendy_worked_with_Juanita_directly1',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly1'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly2',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly2'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly3',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly3'), [
      upperFirst(username),
      visitor,
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly4',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly4'), [
      upperFirst(visitor),
      username,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly5',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly5'), [
      upperFirst(visitor),
      username,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly6',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly6'), [
      upperFirst(visitor),
      username,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly7',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly7'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly8',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly8'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directly9',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directly9'), [
      upperFirst(visitor),
      username,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directlya',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directlya'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directlyb',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directlyb'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directlyc',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directlyc'), [
      upperFirst(username),
      visitor,
    ]),
  },
  {
    value: 'Wendy_worked_with_Juanita_directlye',
    label: translateReplacer(t('Wendy_worked_with_Juanita_directlye'), [
      upperFirst(visitor),
    ]),
  },
];
