import Cookies from './cookies';
import getCookieKey from './getCookieKey';

const removeUserCookies = (): void => {
  const OBJ_TOKEN = getCookieKey('userObjToken');
  const AUTH_USER = getCookieKey('authUser');
  const IS_DARK_KEY = getCookieKey('isDark');

  Cookies.remove(OBJ_TOKEN);
  Cookies.remove(IS_DARK_KEY);
  Cookies.remove(AUTH_USER);
};

export default removeUserCookies;
