const reorderArray = (list: any[], firstIndex: number, secondIndex: number) => {
  // @ts-ignore
  const newList = [...list];
  const secondItem = newList[secondIndex];
  newList[secondIndex] = newList[firstIndex];
  newList[firstIndex] = secondItem;
  return newList;
};

export default reorderArray;
