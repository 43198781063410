export type AppEnvType = {
  storageBaseUrl: string;
  baseUrl: string;
  apiBaseUrl: string;
  port: string;
  app: 'user' | 'business';
  prodEnv: 'dev' | 'production';
  businessAppBaseUrl: string;
  wsUrl: string;
  sentryDsn: string;
  reactLinkPreview: string;
  domain: string;
  cookieSuffix: string;
};

const appEnvironment: AppEnvType = {
  storageBaseUrl: `${process.env.NEXT_PUBLIC_STORAGE_BASE_URL}`,
  baseUrl: `${process.env.NEXT_PUBLIC_BASE_URL}`,
  apiBaseUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}`,
  port: `${process.env.NEXT_PUBLIC_PORT}`,
  app: `${process.env.NEXT_PUBLIC_APP}`,
  prodEnv: `${process.env.NEXT_PUBLIC_PROD_ENV}`,
  businessAppBaseUrl: `${process.env.NEXT_PUBLIC_BUSINESS_APP_BASE_URL}`,
  wsUrl: `${process.env.NEXT_PUBLIC_WS_URL}`,
  sentryDsn: `${process.env.NEXT_PUBLIC_SENTRY_DSN}`,
  reactLinkPreview: `${process.env.NEXT_PUBLIC_LINK_PREVIEW_URL}`,
  domain: `${process.env.NEXT_PUBLIC_DOMAIN}`,
  cookieSuffix: `${process.env.NEXT_PUBLIC_COOKIE_SUFFIX}`,
} as AppEnvType;

export default appEnvironment;
