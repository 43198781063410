import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import type { PaginateResponse } from '../types';

interface AddOptionProps {
  page?: 'first' | 'last';
  addToFirstOfList?: boolean;
}

type UseUpdateInfinityDataType<T> = {
  add: (newItem: T, options?: AddOptionProps) => void;
  replace: (
    replaceItem: T & { id: string },
    updaterFunction?: (data: T) => T
  ) => void;
  replaceAll: (changes: T & { id: string }) => void;
  remove: (id: string) => void;
  refetch: () => void;
  get: (id: string) => T | undefined;
  getTotalElements: () => number;
};

type InfiniteCacheDataType<T> = UseInfiniteQueryResult<
  PaginateResponse<T>
>['data'];

const useUpdateInfinityData = <T>(
  key: Array<string> | string
): UseUpdateInfinityDataType<T> => {
  const queryClient = useQueryClient();

  const _updateCache = React.useCallback(
    (data: any) => {
      queryClient.setQueriesData(key, data);
    },
    [queryClient, key]
  );

  const get = React.useCallback(
    (id: string): T | undefined => {
      const data = queryClient.getQueryData(key) as InfiniteCacheDataType<T>;

      if (isNil(data)) {
        return undefined;
      }
      const { pages } = data;
      let item: T;
      pages.some((page) => {
        const record = page.content.find((x: any) => x.id === id);
        if (record) {
          item = record;
          return true;
        }
        return false;
      });

      // @ts-ignore
      return item;
    },
    [queryClient, key]
  );

  const add = React.useCallback(
    (newItem: T, options?: AddOptionProps) => {
      let data;
      data = queryClient.getQueryData(key) as InfiniteCacheDataType<T>;
      if (isNil(data)) {
        data = { pages: [{ content: [] }] };
      }
      const { pages } = data;
      const { page, addToFirstOfList } = options || {};
      const i = !page
        ? pages.length - 1
        : page === 'first'
          ? 0
          : page === 'last'
            ? pages.length - 1
            : pages.length - 1;

      const newPage = pages[i as any];
      // @ts-ignore
      newPage.totalElements = `${parseInt(newPage.totalElements, 10) + 1}`;
      if (addToFirstOfList) newPage.content.unshift(newItem as never);
      else newPage.content.push(newItem as never);

      _updateCache(data);
    },
    [queryClient, key, _updateCache]
  );

  const replace = React.useCallback(
    (replaceItem: T & { id: string }, updaterFunction?: (data: T) => T) => {
      const data = queryClient.getQueryData(key) as InfiniteCacheDataType<T>;
      if (!isNil(data) && isPlainObject(data) && data.pages?.length) {
        const { pages } = data;
        pages.some((page) => {
          const index = page.content.findIndex(
            (x: any) => x?.id === replaceItem?.id
          );
          if (index > -1) {
            page.content[index] = updaterFunction
              ? updaterFunction(page.content[index])
              : replaceItem;
            return true;
          }
          return false;
        });

        _updateCache(data);
      }
    },
    [queryClient, key, _updateCache]
  );

  const replaceAll = React.useCallback(
    (changes: T & { id: string }) => {
      const data = queryClient.getQueryData(key) as InfiniteCacheDataType<T>;

      if (!isNil(data) && isPlainObject(data) && data.pages?.length) {
        const { pages } = data;
        pages.forEach((page) => {
          const newContent = page.content.map((x: any) => ({
            ...x,
            ...changes,
          }));
          page.content = newContent;
        });

        _updateCache(data);
      }
    },
    [queryClient, key, _updateCache]
  );

  const remove = React.useCallback(
    (id: string) => {
      const data = queryClient.getQueryData(key) as InfiniteCacheDataType<T>;

      if (!isNil(data) && isPlainObject(data) && data.pages?.length) {
        const { pages } = data;

        pages.some((page: any) => {
          const index = page.content.findIndex((x: any) => x?.id === id);
          if (index > -1) {
            page.content.splice(index, 1);
            page.totalElements -= 1;
            return true;
          }
          return false;
        });

        _updateCache(data);
      }
    },
    [queryClient, key, _updateCache]
  );

  const refetch = React.useCallback(() => {
    queryClient.refetchQueries(key, {
      active: true,
    });
  }, [queryClient, key]);

  const getTotalElements = React.useCallback((): number => {
    const data = queryClient.getQueryData(key) as InfiniteCacheDataType<T>;
    if (!isNil(data) && isPlainObject(data) && data.pages?.length) {
      const { pages } = data;
      return parseInt(pages[0]?.totalElements || '0', 10);
    }
    return 0;
  }, [queryClient, key]);

  return {
    get,
    add,
    replace,
    replaceAll,
    remove,
    refetch,
    getTotalElements,
  };
};

export default useUpdateInfinityData;
