import request from '../utils/request';
import Endpoints from '../constants/endpoints';
import { pageEndpoints } from '../constants';
import beforeCachePageDetail from '../utils/normalizers/beforeCachePageDetail';
import type {
  PageApiResponseType,
  PaginateResponse,
  PaginateParamType,
  BeforeCachePageDetailType,
  PageCategoryType,
  PageCompanySizeType,
  PageType,
} from '../types';

export type GetPageDetailType = {
  username?: string;
  accessToken?: string;
  pageId?: string;
};

export const getPageDetail = async ({
  params,
  accessToken,
}: {
  params: GetPageDetailType;
  accessToken?: string;
}): Promise<PageType> => {
  if (!params?.username && !params?.pageId) {
    throw new Error('pagename or pageId is required');
  }
  const { username, pageId } = params;
  const { data } = await request.get(Endpoints.App.Page.get, {
    accessToken,
    params: {
      pageId,
      username,
      containsCroppedHeaderImageLink: true,
    },
  });

  return beforeCachePageDetail(data);
};

const beforeCacheMyPages = (data: any) =>
  data?.reduce?.((prev: any, curr: any) => {
    const existItem = prev.find((i: any) => i.page?.id === curr.page?.id);
    return existItem
      ? prev.map((item: any) => ({
          ...item,
          roles: [...existItem.roles, curr.role],
        }))
      : [...prev, { ...curr, roles: [curr.role] }];
  }, []);

export const getMyPages = async (): Promise<any> => {
  const response = await request.get(Endpoints.App.Page.myPages);
  return beforeCacheMyPages(response?.data);
};

export const getMyOwnPages = async (): Promise<Array<PageApiResponseType>> => {
  const { data } = await request.get(pageEndpoints.myOwnPages);

  return data;
};

export const updatePage = async ({ id, page }: any): Promise<any> => {
  const url = `${Endpoints.App.Page.base}/${id}`;
  const { data } = await request.put(url, page);

  return data;
};

export const updatePageTitle = async ({ id, title }: any): Promise<any> => {
  const url = `${Endpoints.App.Page.base}/${id}/title`;
  const { data } = await request.put(url, {
    title,
  });

  return data;
};

export const updatePageLocations = async ({
  id,
  locations,
}: any): Promise<any> => {
  const url = `${Endpoints.App.Page.base}/${id}/location`;
  const { data } = await request.put(url, {
    locations,
  });

  return data;
};

export const getFilteredPages = async (
  params: PaginateParamType & {
    industryLookupId?: string;
    text?: string;
  }
): Promise<PaginateResponse<PageApiResponseType>> => {
  const { data } = await request.get(pageEndpoints.filterPages, {
    params,
  });

  return data;
};

export const givePageAccess = async (accessData: any): Promise<any> => {
  const { data } = await request.put(Endpoints.App.Page.pageMember, accessData);

  return data;
};

export const getPageMembers = async ({ params }: any): Promise<any> => {
  const pageId = params?.pageId;
  const { data } = await request.get(Endpoints.App.Page.getMembers, {
    params: { pageId },
  });

  return data?.map(({ id, role, profileInfo, page, status }: any) => ({
    id,
    role,
    user: {
      fullName: `${profileInfo?.name} ${profileInfo?.surname}`,
      username: `@${profileInfo?.username}`,
      id: profileInfo?.userId,
      image: profileInfo?.croppedImageUrl,
      job: profileInfo?.occupationName,
    },
    pageId: page.id,
    status,
  }));
};

export const deletePageMember = async (deleteData: any): Promise<void> => {
  const { data: resData } = await request.delete(
    Endpoints.App.Page.pageMember,
    deleteData
  );

  return resData;
};

export const setTitle = async ({
  id,
  title,
}: {
  id: string;
  title: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.title(id), { title });

  return data;
};

export const setPageName = async ({
  id,
  username,
}: {
  id: string;
  username: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.pageName(id), {
    username,
  });

  return data;
};

export const setCategory = async ({
  id,
  category,
}: {
  id: string;
  category: PageCategoryType;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.category(id), { category });

  return data;
};

export const setIndustry = async ({
  id,
  industryLookupId,
  industryName,
}: {
  id: string;
  industryLookupId: string;
  industryName: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.industry(id), {
    industryLookupId,
    industryName,
  });

  return data;
};

export const setDescription = async ({
  id,
  description,
}: {
  id: string;
  description: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.description(id), {
    description,
  });

  return data;
};

export const setEstablishmentDate = async ({
  id,
  establishmentDate,
}: {
  id: string;
  establishmentDate: Date;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.establishmentDate(id), {
    establishmentDate,
  });

  return data;
};

export const setCompanySize = async ({
  id,
  companySize,
}: {
  id: string;
  companySize: PageCompanySizeType;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.companySize(id), {
    companySize,
  });

  return data;
};

export const setPublish = async ({ id }: { id: string }): Promise<void> => {
  const { data } = await request.put(pageEndpoints.publish(id));

  return data;
};

export const setUnpublish = async ({ id }: { id: string }): Promise<void> => {
  const { data } = await request.put(pageEndpoints.unpublish(id));

  return data;
};

export const setAdultContent = async ({
  id,
  value,
}: {
  id: string;
  value: boolean;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.adultContent(id), { value });

  return data;
};

export const deletePage = async ({
  id,
  ...rest
}: {
  id: string;
}): Promise<void> => {
  const { data } = await request.delete(pageEndpoints.deleteEp(id), {
    data: { ...rest },
  });

  return data;
};

export const setPageEmail = async ({
  id,
  email,
}: {
  id: string;
  email: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.email(id), { email });

  return data;
};

export const setPagePhone = async ({
  id,
  phone,
}: {
  id: string;
  phone: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.phone(id), { phone });

  return data;
};

export const setPageLink = async ({
  id,
  link,
}: {
  id: string;
  link: string;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.link(id), { link });

  return data;
};

export const setPageLocations = async ({
  id,
  locations,
}: {
  id: string;
  locations: Array<any>;
}): Promise<void> => {
  const { data } = await request.put(pageEndpoints.locations(id), {
    locations,
  });

  return data;
};

export const acceptMemberShip = async (memberData: any): Promise<void> => {
  const { data } = await request.put(
    pageEndpoints.acceptMemberShip,
    memberData
  );

  return data;
};

export const declineMemberShip = async (putData: any): Promise<void> => {
  const { data } = await request.put(pageEndpoints.declineMemberShip, putData);

  return data;
};

export default {
  acceptMemberShip,
  declineMemberShip,
  updatePage,
  getPageDetail,
  getMyPages,
  getMyOwnPages,
  updatePageTitle,
  updatePageLocations,
  getFilteredPages,
  givePageAccess,
  getPageMembers,
  deletePageMember,
  setTitle,
  setPageName,
  setIndustry,
  setDescription,
  setEstablishmentDate,
  setCompanySize,
  setPublish,
  setUnpublish,
  setAdultContent,
  deletePage,
  setPageEmail,
  setPagePhone,
  setPageLink,
  setPageLocations,
};
