import { useAuthState, useAuthDispatch } from './Auth/auth.provider';
import { authReducer } from './Auth/auth.reducer';
import { useGlobalState, useGlobalDispatch } from './Global/global.provider';
import { globalReducer } from './Global/global.reducer';
import I18nProvider from './I18nProvider';

export { I18nProvider };
// -- -- -- -- Auth -- -- -- --
export { useAuthState, useAuthDispatch, authReducer };

// -- -- -- -- Global -- -- -- --
export { useGlobalState, useGlobalDispatch, globalReducer };
