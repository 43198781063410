const NETWORK_SERVICE = '/network-service';
const API_VERSION = '/api/v1';

const network = {
  common: {
    followers: `${NETWORK_SERVICE}${API_VERSION}/followers`,
    followings: `${NETWORK_SERVICE}${API_VERSION}/followings`,
    relation: `${NETWORK_SERVICE}${API_VERSION}/follow-relation`,
    blocked: `${NETWORK_SERVICE}${API_VERSION}/blocked-users`,
    mutual: `${NETWORK_SERVICE}${API_VERSION}/mutual/mine`,
  },
  hashtag: {
    mostPopular: `${NETWORK_SERVICE}${API_VERSION}/hashtags/most-popular`,
    follow: `${NETWORK_SERVICE}${API_VERSION}/hashtag/follow`,
    unfollow: `${NETWORK_SERVICE}${API_VERSION}/hashtag/unfollow`,
    following: `${NETWORK_SERVICE}${API_VERSION}/hashtag/followings`,
    seenOne: (id: string): string =>
      `${NETWORK_SERVICE}${API_VERSION}/hashtag/${id}`,
  },
  page: {
    follow: `${NETWORK_SERVICE}${API_VERSION}/page/follow`,
    unfollow: `${NETWORK_SERVICE}${API_VERSION}/page/unfollow`,
    suggestions: `${NETWORK_SERVICE}${API_VERSION}/recommendation/suggest-page`,
    popular: `${NETWORK_SERVICE}${API_VERSION}/recommendation/popular-pages`,
    myFollowings: `${NETWORK_SERVICE}${API_VERSION}/page/followings`,
    myFollowers: `${NETWORK_SERVICE}${API_VERSION}/page/followers`,
    mutual: `${NETWORK_SERVICE}${API_VERSION}/page/mutual/mine`,
  },
  people: {
    follow: `${NETWORK_SERVICE}${API_VERSION}/person/follow`,
    unfollow: `${NETWORK_SERVICE}${API_VERSION}/person/unfollow`,
    suggestions: `${NETWORK_SERVICE}${API_VERSION}/recommendation/suggest-person`,
    popularPeople: `${NETWORK_SERVICE}${API_VERSION}/recommendation/popular-people`,
    myFollowRequests: `${NETWORK_SERVICE}${API_VERSION}/follow-requests`,
    acceptFollow: `${NETWORK_SERVICE}${API_VERSION}/person/follow/accept`,
    declineFollow: `${NETWORK_SERVICE}${API_VERSION}/person/follow/decline`,
    myFollowings: `${NETWORK_SERVICE}${API_VERSION}/person/followings/mine`,
    myFollowers: `${NETWORK_SERVICE}${API_VERSION}/person/followers/mine`,
    pendingRequests: `${NETWORK_SERVICE}${API_VERSION}/pending-requests`,
    cancelFollowRequest: `${NETWORK_SERVICE}${API_VERSION}/person/follow/cancel`,
    upcomingBirthDays: `${NETWORK_SERVICE}${API_VERSION}/upcoming-birth-days`,
    mutual: `${NETWORK_SERVICE}${API_VERSION}/person/mutual/mine`,
  },
};

export default network;
