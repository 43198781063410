'use client';

import React from 'react';
import { useFormikContext } from 'formik';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import pick from 'lodash/pick';
import type { FormikContextType, FormikValues } from 'formik';

export interface DataType {
  refs?: string[];
  props?: string[];
}

export type WithFormikProps<Props> = Props & {
  formikProps: FormikContextType<unknown>;
  formikRefs: {
    current: FormikContextType<unknown>;
  };
};

const withFormikContext =
  (dataType?: DataType) =>
  <Props,>(Component: React.FC<Props>): React.FC<WithFormikProps<Props>> =>
  (props: Props) => {
    const refs = React.useRef<FormikValues>({});
    const formikContext = useFormikContext();

    forEach(dataType?.refs, (ref) => {
      refs.current[ref] = get(formikContext, ref);
    });

    return (
      <Component
        {...props}
        formikProps={pick(formikContext, dataType?.props || [])}
        formikRefs={refs}
      />
    );
  };

export default withFormikContext;
