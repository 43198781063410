'use client';
import removeUserCookies from '../utils/removeUserCookies';
import removePageCookies from '../utils/removePageCookies';
import { landingRouteNames } from '@lobox/utils/constants/routeNames';
import getLocalforageMethods from './getLocalforageMethods';

const onSuccessLogout = () => {
  const { clear } = getLocalforageMethods();
  window.location.replace(landingRouteNames.login);
  removeUserCookies();
  removePageCookies();
  clear();
};

export default onSuccessLogout;
