export const apiUrlMaker = (
  pathname: string,
  params?: string,
  isWholePath?: boolean
): string => {
  const symbol = pathname?.includes('?') ? '&' : '?';
  if (isWholePath) return `${pathname}${params ? `${symbol}${params}` : ''}`;
  return pathname?.includes('http')
    ? `$${pathname}${params ? `${symbol}${params}` : ''}`
    : `${process.env.NEXT_PUBLIC_API_BASE_URL}/${pathname}${
        params ? `${symbol}${params}` : ''
      }`;
};
