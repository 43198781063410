const PAGE_SERVICE = '/page-service';
const API_VERSION = '/api/v1';

const base = `${PAGE_SERVICE}${API_VERSION}`;

const page = {
  acceptMemberShip: `${base}/page-member/accept`,
  declineMemberShip: `${base}/page-member/decline`,
  filterPages: `${PAGE_SERVICE}${API_VERSION}/pages`,
  myOwnPages: `${PAGE_SERVICE}${API_VERSION}/pages/mine`,
  title: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/title`,
  pageName: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/username`,
  category: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/category`,
  industry: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/industry`,
  description: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/description`,
  establishmentDate: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/establishment-date`,
  companySize: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/company-size`,
  publish: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/publish`,
  unpublish: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/un-publish`,
  adultContent: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/adult-content`,
  email: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/email`,
  phone: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/phone`,
  link: (id: string): string => `${PAGE_SERVICE}${API_VERSION}/page/${id}/link`,
  locations: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/locations`,
  deleteEp: (id: string): string => `${PAGE_SERVICE}${API_VERSION}/page/${id}`,
  allowOthersToShareYourPosts: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/allow-others-to-share-your-posts`,
  allowOthersToTagOrMentionYou: (id: string): string =>
    `${PAGE_SERVICE}${API_VERSION}/page/${id}/allow-others-to-tag-or-mention-you`,
};

export default page;
