import request from '../../utils/request';
import { networkEndpoints } from '../../constants';
import type {
  HashtagFollowType,
  HashtagType,
  PaginateParamType,
  PaginateResponse,
} from '../../types';

export const followHashtag = async (
  hashtagData: HashtagFollowType
): Promise<any> => {
  const { data } = await request.post(
    networkEndpoints.hashtag.follow,
    hashtagData
  );

  return data;
};

export const unfollowHashtag = async (
  hashtagData: HashtagFollowType
): Promise<any> => {
  const { data } = await request.post(
    networkEndpoints.hashtag.unfollow,
    hashtagData
  );

  return data;
};

export const getUserHashtags = async (
  params: PaginateParamType & {
    includefollowersCounter?: boolean;
    userId: string;
    text: string;
  }
): Promise<PaginateResponse<HashtagType>> => {
  const { includefollowersCounter = true, ...rest } = params || {};
  const { data } = await request.get(networkEndpoints.hashtag.following, {
    params: {
      includefollowersCounter,
      ...rest,
    },
  });

  return data;
};

export const getHashtagDetail = async (
  hashtag: string
): Promise<HashtagType> => {
  const { data } = await request.get(
    networkEndpoints.hashtag.seenOne(hashtag?.toLowerCase())
  );
  return data;
};

export default {
  followHashtag,
  unfollowHashtag,
  getUserHashtags,
  getHashtagDetail,
};
