import request from '../../utils/request';
import { networkEndpoints } from '../../constants';
import type {
  FollowType,
  IPage,
  PageType,
  PaginateParamType,
  PaginateResponse,
} from '../../types';
import networkNormalizer from '../../utils/normalizers/network';

export const followPage = async (pageData: FollowType): Promise<void> => {
  const { data } = await request.post(networkEndpoints.page.follow, pageData);

  return data;
};

export const unfollowPage = async (pageData: FollowType): Promise<void> => {
  const { data } = await request.post(networkEndpoints.page.unfollow, pageData);

  return data;
};

export const getSuggestionPages = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<IPage>> => {
  const { data } = await request.get(networkEndpoints.page.suggestions, {
    params,
  });

  return networkNormalizer.suggestPages(data);
};

export const getPopularPages = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<IPage>> => {
  const { data } = await request.get(networkEndpoints.page.popular, {
    params,
  });

  return networkNormalizer.suggestPages(data);
};

export const getMyFollowingPages = async (
  params: PaginateParamType & {
    userId?: string;
    text?: string;
  } = {}
): Promise<PaginateResponse<PageType>> => {
  const { data } = await request.get(networkEndpoints.page.myFollowings, {
    params,
  });

  return data;
};

export const getMyFollowerPages = async (
  params: PaginateParamType & {
    text?: string;
  } = {}
): Promise<PaginateResponse<PageType>> => {
  const { data } = await request.get(networkEndpoints.page.myFollowers, {
    params,
  });

  return data;
};

export const getMutualPages = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<IPage>> => {
  const { data } = await request.get(networkEndpoints.page.mutual, {
    params,
  });

  return networkNormalizer.suggestPages(data);
};

export default {
  followPage,
  unfollowPage,
  getSuggestionPages,
  getMyFollowingPages,
  getMyFollowerPages,
  getPopularPages,
  getMutualPages,
};
