import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

type UseScrollReachEndProps = {
  scrollEl: HTMLElement | string | null;
  callback?: (...args: any[]) => any;
  endThreshold?: number;
  exeDelay?: number;
  disabled?: boolean;
};

const useScrollReachEnd = ({
  scrollEl,
  callback,
  endThreshold = 0.55,
  exeDelay = 100,
  disabled,
}: UseScrollReachEndProps): void => {
  if (endThreshold > 1 || endThreshold < 0) {
    throw new Error('the endThreshold should be between 0~1');
  }

  useEffect(() => {
    if (disabled) {
      return;
    }
    const el: HTMLElement | null = isString(scrollEl)
      ? document.getElementById(scrollEl)
      : scrollEl;

    const listener = debounce(() => {
      const top = el?.scrollTop || 0;
      const scrollHeight = el?.scrollHeight || 2;
      const clientHeight = el?.clientHeight || 1;
      const scrollArea = scrollHeight - clientHeight;
      const scrollPercent = top / scrollArea;

      if (
        scrollEl &&
        scrollPercent >= endThreshold &&
        typeof callback === 'function'
      ) {
        callback();
      }
    }, exeDelay);

    el?.addEventListener('scroll', listener);

    return () => {
      el?.removeEventListener('scroll', listener);
    };
  }, []); // eslint-disable-line
};

export default useScrollReachEnd;
