import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { useGlobalDispatch } from '..';
import useEffectOnlyChange from './useEffectOnlyChange';

export type Callback<T> = (x: T) => T;
export type SetState<T> = (func: Callback<T>) => void;
export type InitialValue<T> = T extends Record<any, any> ? T : never;
export type ReturnUseURLState<T> = {
  state: T;
  push: SetState<T>;
  replace: SetState<T>;
};

const CUSTOM_EVENT_KEY = 'CUSTOM_EVENT_KEY';

export default function useUrlState<T = {}>(
  defaultState: T
): ReturnUseURLState<T> {
  const [state, setState] = useState<T>(() => {
    try {
      const query = getQuery('state');
      const newState = jsonParse<T>(query);
      if (Object?.keys?.(newState)?.length !== 0) return newState;
      return defaultState;
    } catch {
      return defaultState;
    }
  });

  const updateStateFromQueryParams = useCallback(() => {
    try {
      const query = getQuery('state');
      const newState = jsonParse<T>(query);
      setState(newState);
    } catch {
      // do nothing
    }
  }, []);

  useEffect(() => {
    window.addEventListener('CUSTOM_EVENT_KEY', updateStateFromQueryParams);
    return () =>
      window.removeEventListener(
        'CUSTOM_EVENT_KEY',
        updateStateFromQueryParams
      );
  }, []);
  const pathname = usePathname();
  useEffectOnlyChange(() => {
    updateStateFromQueryParams();
  }, [pathname]);

  const formatData = useCallback((newData: T) => {
    const newUrl = new URL(window.location.href);
    if (Object.keys(newData).length > 0) {
      newUrl.searchParams.set('state', JSON.stringify(newData));
    } else {
      newUrl.searchParams.delete('state');
    }

    return { newUrl, newStateData: newData };
  }, []);

  const updateState = useCallback(
    (callback: (prevState: T) => T, replace: boolean) => {
      const newState = callback(state);

      if (Object.is(newState, state)) {
        return;
      }
      const { newUrl, newStateData } = formatData(newState);

      const historyFunction = replace
        ? window.history.replaceState
        : window.history.pushState;

      historyFunction.apply(window.history, [{}, '', newUrl?.href]);
      setState(newStateData);
      const event = new CustomEvent(CUSTOM_EVENT_KEY);
      window.dispatchEvent(event);
    },
    [state, formatData]
  );

  const replace = useCallback(
    (callback: Callback<T>) => {
      updateState(callback, true);
    },
    [updateState]
  );
  const push = useCallback(
    (callback: Callback<T>) => {
      updateState(callback, false);
    },
    [updateState]
  );

  return { state, push, replace };
}

function getQuery(queryName: string): string | null {
  if (typeof window === 'undefined') return null;
  return new URLSearchParams(window.location.search)?.get(queryName);
}

function jsonParse<T>(
  str: string | undefined | null
): T extends Record<any, any> ? T : any {
  try {
    return JSON.parse(str || '{}');
  } catch (err) {
    // console.log(err);
    return {} as any;
  }
}
