import type {
  IPage,
  PageType,
  PaginateResponse,
  IPeople,
  PeopleType,
} from '../../types';
import type {
  IUpcomingBirthDay,
  IUpcomingBirthDayApi,
} from '../../types/network';
import beforeCachePageDetail from './beforeCachePageDetail';
import beforeCacheUserInfo from './beforeCacheUserInfo';

const isTodayBirthDay = (date: Date): boolean => {
  const now = new Date();
  const birth = `${date.getMonth()}_${date.getDate()}`;
  const today = `${now.getMonth()}_${now.getDate()}`;

  return today === birth;
};

const upComingBirthDaysNormalizer = (
  data: PaginateResponse<IUpcomingBirthDayApi>
): PaginateResponse<IUpcomingBirthDay> => ({
  ...data,
  content: data.content.reduce(
    (prev: Array<IUpcomingBirthDay>, curr: IUpcomingBirthDayApi) => {
      if (curr.hideIt) {
        return prev;
      }
      return [
        ...prev,
        {
          ...curr,
          fullName: `${curr.name} ${curr.surname}`,
          subTitle: `@${curr.username}`,
          isToday: isTodayBirthDay(new Date(curr.birthDate)),
        },
      ];
    },
    []
  ),
});

const suggestPeopleNormalizer = (
  data: PaginateResponse<PeopleType>
): PaginateResponse<IPeople> => ({
  ...data,
  content: data.content.reduce((prev: Array<IPeople>, curr: PeopleType) => {
    if (curr.hideIt) {
      return prev;
    }
    return [
      ...prev,
      {
        ...curr,
        fullName: `${curr.name} ${curr.surname}`,
        subTitle: `@${curr.username}`,
      },
    ];
  }, []),
});

const getMutualNormalizer = (
  data: PaginateResponse<PeopleType>
): PaginateResponse<IPeople> => ({
  ...data,
  content: data.content.reduce((prev: Array<any>, curr: any) => {
    const normalizedItem = curr.page
      ? beforeCachePageDetail(curr.page)
      : beforeCacheUserInfo(curr.person);
    return normalizedItem.hideIt ? prev : [...prev, normalizedItem];
  }, []),
});

const suggestPageNormalizer = (
  data: PaginateResponse<PageType>
): PaginateResponse<IPage> => ({
  ...data,
  content: data.content.reduce((prev: Array<IPage>, curr: PageType) => {
    if (curr.hideIt) {
      return prev;
    }
    return [
      ...prev,
      {
        ...curr,
        follow: curr.follow || false,
        type: 'PAGE',
        fullName: `${curr.title}`,
        subTitle: `@${curr.username}`,
      },
    ];
  }, []),
});

const networkNormalizer = {
  suggestPages: suggestPageNormalizer,
  suggestPeople: suggestPeopleNormalizer,
  upComingBirthDays: upComingBirthDaysNormalizer,
  getMutual: getMutualNormalizer,
};

export default networkNormalizer;
