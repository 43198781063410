import type { IJob, PaginateResponse, UserType } from '../../types';
import type { CompanyInfoType, IJobApi } from '../../types/job';
import {
  APP_ENTITIES,
  db,
  jobsDb,
  jobSelectedKeys,
  jobStatuses,
} from '../../constants';
import collectionToObjectByKey from '../collectionToObjectByKey';
import languageNormalizer from './languageNormalizer';
import skillNormalizer from './skillNormalizer';
import beforeCachePageDetail from './beforeCachePageDetail';
import beforeCacheUserInfo from './beforeCacheUserInfo';
import { addPrefix } from '../addPrefix';

const employments = collectionToObjectByKey(db.EMPLOYMENT_TYPES);
const experienceLevels = collectionToObjectByKey(jobsDb.experienceLevels);
const salaryPeriods = collectionToObjectByKey(jobsDb.SALARY_PERIOD);
const workPlaceTypes = collectionToObjectByKey(jobsDb.WORK_SPACE_MODEL);
const jobStats = collectionToObjectByKey(jobStatuses);
const questionTypes = collectionToObjectByKey(jobsDb.QUESTION_TYPES);

export const jobItemNormalizer = ({
  employmentType,
  categoryId,
  categoryName,
  experienceLevel,
  skills,
  languages,
  salaryPeriod,
  salaryCurrencyId,
  salaryCurrencySymbol,
  salaryCurrencyName,
  salaryCurrencyCode,
  location,
  title,
  titleId,
  languageId,
  workPlaceType,
  status,
  questions,
  page,
  jobApplicationStatus,
  applicants,
  ...item
}: IJobApi) => ({
  ...item,
  page: page && beforeCachePageDetail(page),
  languageId,
  title: {
    label: title,
    value: titleId,
  },
  experienceLevel: experienceLevels?.[experienceLevel],
  employmentType: employments?.[employmentType],
  salaryPeriod: salaryPeriods?.[salaryPeriod],
  workPlaceType: workPlaceTypes?.[workPlaceType],
  status: jobStats?.[status],
  isApplied: jobApplicationStatus === jobSelectedKeys.applied,
  isHired: jobApplicationStatus === jobSelectedKeys.hired,
  isClosed: status === jobSelectedKeys.closed,
  salaryCurrency: salaryCurrencyId
    ? {
        value: salaryCurrencyId,
        label: `${salaryCurrencyCode} - ${salaryCurrencySymbol} - ${salaryCurrencyName}`,
        symbol: salaryCurrencySymbol,
        name: salaryCurrencyName,
        code: salaryCurrencyCode,
      }
    : undefined,
  skills: skills?.map(({ skillId, skillLevel, skillType, skillName }: any) => {
    const { progress, level } = skillNormalizer({ level: skillLevel });
    return {
      id: skillId,
      label: skillName,
      progress,
      level,
      skillLevel,
      type: skillType,
    };
  }),
  questions: questions?.map(({ questionType, ...rest }: any) => ({
    ...rest,
    questionType,
    type: questionTypes[questionType],
  })),
  location: {
    lang: location?.lang,
    category: location?.category,
    value: location?.placeId,
    label: location?.name,
    lat: location?.lat,
    lon: location?.lon,
    position: {
      lat: location?.lat,
      lon: location?.lon,
    },
  },
  languages: languages?.map(
    ({ languageId: lngId, languageLevel, languageName }: any) => {
      const { progress, level } = languageNormalizer({
        level: languageLevel,
      } as any);
      return {
        id: lngId,
        label: languageName,
        progress,
        level,
        languageLevel,
      };
    }
  ),
  category: categoryId
    ? {
        value: categoryId,
        label: categoryName,
      }
    : undefined,
  applicants: applicants?.reduce((prev: Array<any>, curr: any) => {
    const normalizedItem = beforeCacheUserInfo(curr);
    // @ts-ignore
    return normalizedItem.hideIt ? prev : [...prev, normalizedItem];
  }, []),
});

const JobsListNormalizer = (
  data: PaginateResponse<IJobApi>
): PaginateResponse<IJob> => ({
  ...data,
  content: data.content.map(jobItemNormalizer),
  currentEntity: !!data?.currentEntity
    ? jobItemNormalizer(data.currentEntity)
    : null,
});

const JobDetailsNormalizer = (item: IJobApi): IJob => jobItemNormalizer(item);

const getPopularCategories = (
  data: PaginateResponse<any>
): PaginateResponse<any> => ({
  ...data,
  content: data.content.map(
    ({ categoryId, categoryImageUrl, categoryTitle }: any) => ({
      id: categoryId,
      title: categoryTitle,
      imageUrl: categoryImageUrl,
    })
  ),
});

const getOwners = (data: Array<any>): Array<Partial<UserType>> =>
  data?.map(({ user, ...rest }: any) => ({
    ...rest,
    croppedImageUrl: user.croppedImageUrl,
    usernameAtSign: `@${user.username}`,
    userId: user.id,
    fullName: `${user.name} ${user.surname}`,
    subTitle: `@${user.username}`,
  }));

const getCreator = (data: Record<string, any>): Partial<UserType> => {
  const { user, networkModel, occupationName } = data;

  return {
    // type: APP_ENTITIES.person,
    croppedImageUrl: user.croppedImageUrl,
    usernameAtSign: `@${user.username}`,
    id: user.id,
    fullName: `${user.name} ${user.surname}`,
    username: user.username,
    network: networkModel,
    occupation: { label: occupationName, value: undefined },
  };
};
const getSearchFilters = (data: any): any => {
  return {
    ...data,
    cities: data.cities?.map((city: { name: string; code: string }) => ({
      label: city?.name,
      value: city?.code,
    })),
    titles: data?.titles?.map?.((title: string) => ({
      value: title,
      label: title,
    })),
    pages: data?.pageInfos?.map((page: any) => ({
      value: page.id,
      label: page.title,
      image: page.croppedImageUrl,
      helperText: `@${page?.username}`,
    })),
    creators: data?.creators?.map((creator: any) => ({
      value: creator.id,
      label: `${creator.name} ${creator.surname}`,
      image: creator.croppedImageUrl,
      helperText: `@${creator?.username}`,
    })),
    categories: data?.categories?.map((category: any) => ({
      value: category.id,
      label: category.title,
      image: category.imageUrl,
    })),
    skills: data?.skills?.map((skill: any) => ({
      value: skill.skillName,
      label: skill.skillName,
      id: skill.skillId,
    })),
    languages: data?.languages
      ?.map((skill: any) => ({
        value: skill.languageId,
        label: skill.languageName,
      }))
      ?.filter((item: any) => !!item?.value && !!item?.label),
    benefits: data?.benefits?.map?.(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      })
    ),
    employmentTypes: data?.employmentTypes?.map?.((title: string) => ({
      value: title,
      label: title,
    })),
    experienceLevels: data?.experienceLevels?.map?.((title: string) => ({
      value: title,
      label: title,
    })),
    workPlaceTypes: data?.workPlaceTypes?.map?.((title: string) => ({
      value: title,
      label: title,
    })),
    datePosted: data?.datePosted?.map?.((title: string) => ({
      value: title,
      label: title,
    })),
    hashtags: data?.hashtags?.map?.((value: string) => ({
      value: value,
      label: `#${value}`,
    })),
    sortBy: data?.sortBy?.map?.((value: string) => ({
      value: value,
      label: addPrefix(value),
    })),
  };
};

const getJobCompanyInfo = (data: any): CompanyInfoType => {
  const pageDetail = beforeCachePageDetail(data);
  return {
    ...pageDetail,
    mutualConnectionsCount: Number(data?.mutualConnectionsCount),
    worksHereCount: Number(data?.worksHereCount),
    location: {
      label: data?.location?.name,
    },
    worksHere: (data.mutualWorksHere || []).reduce(
      (prev: Array<any>, curr: any) => {
        const normalizedItem = beforeCacheUserInfo(curr);
        // @ts-ignore
        return normalizedItem.hideIt ? prev : [...prev, normalizedItem];
      },
      []
    ),
    mutuals: data.mutualConnections.reduce((prev: Array<any>, curr: any) => {
      const normalizedItem = curr.page
        ? beforeCachePageDetail(curr.page)
        : beforeCacheUserInfo(curr.person);
      return normalizedItem.hideIt ? prev : [...prev, normalizedItem];
    }, []),
  };
};

export const searchJobsSkillsPages = (
  data: { id: string; title: string; type: string; imgUrl: string }[]
): { value: string; label: string; type: string; imgUrl: string }[] =>
  data?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.title,
    type: item?.type,
    imgUrl: item?.imgUrl,
  }));

const jobsNormalizer = {
  getJobsList: JobsListNormalizer,
  getJobDetails: JobDetailsNormalizer,
  jobItem: jobItemNormalizer,
  getPopularCategories,
  getOwners,
  getCreator,
  getSearchFilters,
  getJobCompanyInfo,
  searchJobsSkillsPages,
};

export default jobsNormalizer;
