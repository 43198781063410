const hereApiModuleType = {
  NEW_JOB: 'NEW_JOB',
  VOLUNTEER_EXPERIENCE: 'VOLUNTEER_EXPERIENCE',
  ENTREPRENEURSHIP: 'ENTREPRENEURSHIP',
  NEW_COURSE: 'NEW_COURSE',
  NEW_GRADUATION: 'NEW_GRADUATION',
  STUDY_ABROAD: 'STUDY_ABROAD',
  NEW_SCHOOL: 'NEW_SCHOOL',
  NEW_LICENSE: 'NEW_LICENSE',
  GET_FUNDED: 'GET_FUNDED',
  NEW_PATENT: 'NEW_PATENT',
  NEW_PUBLICATION: 'NEW_PUBLICATION',
  NEW_AWARD_ACHIEVEMENT: 'NEW_AWARD_ACHIEVEMENT',
  NEW_CERTIFICATION: 'NEW_CERTIFICATION',
  GET_INVESTED: 'GET_INVESTED',
};

export default hereApiModuleType;
