import type { Dispatch } from 'react';
import type { ActionType, StateType } from './global.reducer';
import useCreateContext from '../../hooks/useCreateContext';
import { globalReducer, initialState } from './global.reducer';

const [state, dispatch, provider] = useCreateContext(
  globalReducer,
  initialState
);

type Keys = keyof StateType;
type KeysAndString = keyof StateType | (string & {});

type UseGlobalStateType = <T extends KeysAndString>(
  x?: T
) => T extends Keys ? StateType[T] : any;

export const useGlobalState = state as UseGlobalStateType;
export const useGlobalDispatch = dispatch as () => Dispatch<ActionType>;
export const GlobalProvider = provider;
