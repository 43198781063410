export const initialState = {
  isLoggedIn: true,
  isMyProfile: false,
  isWSClosed: false,
};

type ActionType =
  | { type: 'TOGGLE_LOGGED_IN' }
  | { type: 'TOGGLE_IS_WS_CLOSED'; payload: boolean };

type StateType = typeof initialState;

export function authReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'TOGGLE_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: !state.isLoggedIn,
      };
    case 'TOGGLE_IS_WS_CLOSED':
      return {
        ...state,
        isWSClosed: action.payload,
      };
    default: {
      throw new Error(`Unsupported action type at Auth Reducer`);
    }
  }
}
