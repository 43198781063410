import urlsBuilder from '../utils/urlsBuilder';
import appEnvironment from './env';
import { routeNames } from './routeNames';

const {
  baseUrl: productionBaseUrl,
  storageBaseUrl,
  apiBaseUrl,
  port,
} = appEnvironment;

const baseUrl = (global as any).__DEV__
  ? `http://localhost:${port}`
  : productionBaseUrl;
const commonUrls = urlsBuilder({ baseUrl, storageBaseUrl, apiBaseUrl });

const urls = {
  base: `${baseUrl}`,
  home: `${baseUrl}${routeNames.home}`,
  login: commonUrls.login,
  signup: commonUrls.signUp,
  getName: commonUrls.getName,
  languagesAddress: commonUrls.languagesAddress,
  socialAuth: commonUrls.socialAuth,
  twoFactorAuthGetCode: commonUrls.twoFactorAuthGetCode,
  userProfile: (username: string): string => `${baseUrl}/${username}`,
};

export default urls;
