const getVideoDuration = (file: File) =>
  new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');

    videoElement.onloadedmetadata = () => {
      resolve(videoElement.duration);
      URL.revokeObjectURL(videoElement.src); // Clean up the object URL
    };

    videoElement.onerror = (error) => {
      reject(error);
    };

    videoElement.src = URL.createObjectURL(file);
  });

export default getVideoDuration;
