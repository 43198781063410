import useReactQuery from './useReactQuery';
import request from '../utils/request';
import { removeHtmlTagsInstring } from '..';

interface IMetaData {
  title: string;
  description?: string;
  image?: string;
  siteName?: string;
}
const urlRegex = /(https?:\/\/[^?]+)/;

const checkImageUrl = async (url: string) => {
  try {
    const response = await fetch(url);

    const contentType = response.headers['Content-Type'];
    return contentType && contentType?.startsWith('image/');
  } catch (error) {
    return false;
  }
};

const getLinkMetaData = async ({ params }: any) => {
  const { data } = await request.get(params.linkPreviewUrl, {
    params,
    isWholePath: true,
  });

  if (!data?.metadata) {
    return null;
  }
  const { og, meta } = data?.metadata || {};

  return {
    title: og?.title || meta?.title || '',
    description: og?.description || meta?.description || '',
    image: og?.image,
    siteName: og?.site_name,
  };
};

const useGetLinkMetaData = ({
  url,
  linkPreviewUrl,
  body,
}: {
  url: string;
  linkPreviewUrl: string;
  body?: string;
}) => {
  const link = url.indexOf('://') === -1 ? `https://${url}` : url;
  const bodyUrl = body?.match(urlRegex)?.[0];

  const { data: isImageUrl, isLoading: isLoadingImage } = useReactQuery({
    action: {
      key: ['check-is-image', bodyUrl || url],
      apiFunc: () => checkImageUrl(removeHtmlTagsInstring(bodyUrl)),
    },
    config: { staleTime: 1000 * 60 * 30, refetchOnMount: false },
  });

  const reactQueryResult = useReactQuery<IMetaData>({
    action: {
      key: ['linkPreview', link],
      apiFunc: getLinkMetaData,
      params: { linkPreviewUrl, url: removeHtmlTagsInstring(link) },
    },
    config: {
      staleTime: 1000 * 60 * 30,
      refetchOnMount: false,
    },
  });

  return {
    ...reactQueryResult,
    isLoading: reactQueryResult.isLoading || isLoadingImage,
    data: {
      ...(reactQueryResult.data || {}),
      ...(isImageUrl ? { image: bodyUrl } : {}),
    },
  };
};

export default useGetLinkMetaData;
