const NOTIFICATION_SERVICE = '/notification-service';
const POST_SERVICE = '/post-service';
const POST_SEARCH_SERVICE = 'post-search-service';
const API_VERSION = '/api/v1';

const base = (id?: string): string =>
  `${POST_SERVICE}${API_VERSION}/post${id ? `/${id}` : ''}`;

const collectionBase = (id?: string): string =>
  `${POST_SERVICE}${API_VERSION}/collection${id ? `/${id}` : ''}`;

const commentBase = (id?: string): string =>
  `${POST_SERVICE}${API_VERSION}/comment${id ? `/${id}` : ''}`;

const collection = {
  list: `${POST_SERVICE}${API_VERSION}/collections`,
  item: (id: string): string => collectionBase(id),
  create: collectionBase(),
  deletePostFrom: (collectionId: string): string =>
    `${collectionBase(collectionId)}/post`,
  savePostTo: (collectionId: string): string =>
    `${collectionBase(collectionId)}/post`,
};
const comment = {
  list: `${POST_SEARCH_SERVICE}${API_VERSION}/comments`,
  add: commentBase(),
  edit: (commentId: string): string => `${commentBase(commentId)}`,
  delete: (commentId: string): string => `${commentBase(commentId)}`,
  reply: `${POST_SERVICE}${API_VERSION}/reply`,
  replies: `${POST_SEARCH_SERVICE}${API_VERSION}/comments/replies`,
  hide: (commentId: string): string =>
    `${POST_SEARCH_SERVICE}${API_VERSION}/comment/${commentId}/hide`,
  unHide: (commentId: string): string =>
    `${POST_SEARCH_SERVICE}${API_VERSION}/comment/${commentId}/un-hide`,
};

const postEndpoints: any = {
  menuItemsStatus: (postId: string): string =>
    `${POST_SERVICE}${API_VERSION}/post/${postId}/menu-helper`,
  create: base(),
  edit: (id: string) => base(id),
  deletePost: (postId: string): string => `${base(postId)}`,
  hide: (postId: string): string =>
    `${POST_SEARCH_SERVICE}${API_VERSION}/post/${postId}/hide`,
  unHide: (postId: string): string =>
    `${POST_SEARCH_SERVICE}${API_VERSION}/post/${postId}/un-hide`,
  reaction: `${POST_SERVICE}${API_VERSION}/action`,
  userPosts: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/just-posts`,
  userHighlights: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/just-highlights`,
  userAllPosts: `${POST_SEARCH_SERVICE}${API_VERSION}/feed/all-posts`,
  getReactions: `${POST_SERVICE}${API_VERSION}/actions`,
  collection,
  comment,
  toggleNotifications: `${NOTIFICATION_SERVICE}${API_VERSION}/turn-off`,
};

export default postEndpoints;
