import { useInfiniteQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import type { PaginateResponse } from '../types';

export type UseInfiniteQueryType<TData = unknown, TError = unknown> = Omit<
  UseInfiniteQueryResult<TData, TError>,
  'data'
> & {
  isEmpty: boolean;
  data: Array<TData>;
  totalElements: number;
  lastPageIndex: number;
  totalPages: number;
};

const useReactInfiniteQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: {
    func: QueryFunction<TQueryFnData, TQueryKey> | Function;
    size?: number;
    extraProps?: {
      [key: string]: any;
    };
  },
  options: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey
  > = {}
): UseInfiniteQueryType<TData, TError> => {
  const isNotDisabled =
    !('enabled' in options) || ('enabled' in options && !!options.enabled);

  const result = useInfiniteQuery<PaginateResponse<TData>>({
    queryKey,
    queryFn: (props: any) =>
      queryFn.func({
        // @ts-ignore
        page: props.pageParam,
        size: queryFn.size,
        ...(queryFn.extraProps || {}),
      }),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 10,
    ...(options as any),
    getNextPageParam: (lastPage: any) =>
      !lastPage?.last ? parseInt(lastPage?.number || 0, 10) + 1 : undefined,
  });
  const pages = result.data?.pages || [];

  const a: Array<TData> = [];
  pages?.forEach(
    (page) =>
      page?.content.forEach((contentItem) => {
        a.push(contentItem);
      })
  );

  return {
    ...result,
    data: a,
    totalElements: parseInt(pages[0]?.totalElements || '0', 10),
    isEmpty: pages[0]?.empty,
    totalPages: parseInt(pages[0]?.totalPages || '0', 10),
    lastPageIndex: parseInt(pages[pages.length - 1]?.number || '0', 10),
    isLoading: result.isLoading && isNotDisabled,
  } as UseInfiniteQueryType<TData, TError>;
};

export default useReactInfiniteQuery;
