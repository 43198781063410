import request from '../../utils/request';
import { networkEndpoints } from '../../constants';
import type {
  FollowType,
  IUpcomingBirthDay,
  IUpcomingBirthDayApi,
  PageAndPeopleType,
  PaginateParamType,
  PaginateResponse,
  PeopleFollowResponseType,
  PeopleFollowType,
  PeopleType,
} from '../../types';
import type { NetworkObjectNormalizerType } from '../../utils/normalizers/networkObjectNormalizer';
import networkObjectNormalizer from '../../utils/normalizers/networkObjectNormalizer';
import followingNormalizer from '../../utils/normalizers/followingNormalizer';
import pendingNormalizer from '../../utils/normalizers/pendingNormalizer';
import networkNormalizer from '../../utils/normalizers/network';

export const followPeople = async (
  peopleData: FollowType
): Promise<PeopleFollowResponseType> => {
  const { data } = await request.post(
    networkEndpoints.people.follow,
    peopleData
  );

  return data;
};

export const unfollowPeople = async (peopleData: FollowType): Promise<void> => {
  const { data } = await request.post(
    networkEndpoints.people.unfollow,
    peopleData
  );

  return data;
};

export const getSuggestionPeople = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(networkEndpoints.people.suggestions, {
    params,
  });

  return networkNormalizer.suggestPeople(data);
};

export const getPopularPeople = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(networkEndpoints.people.popularPeople, {
    params,
  });

  return networkNormalizer.suggestPeople(data);
};

export const getMyFollowRequests = async (
  params: PaginateParamType = {}
): Promise<NetworkObjectNormalizerType> => {
  const { data } = await request.get<PaginateResponse<PageAndPeopleType>>(
    networkEndpoints.people.myFollowRequests,
    {
      params,
    }
  );

  // @ts-ignore
  return networkObjectNormalizer(data);
};

export const acceptFollow = async (
  peopleData: PeopleFollowType
): Promise<void> => {
  const { data } = await request.post(
    networkEndpoints.people.acceptFollow,
    peopleData
  );

  return data;
};

export const declineFollow = async (
  peopleData: PeopleFollowType
): Promise<void> => {
  const { data } = await request.post(
    networkEndpoints.people.declineFollow,
    peopleData
  );

  return data;
};

export const cancelFollowRequest = async (
  pageData: FollowType
): Promise<void> => {
  const { data } = await request.post(
    networkEndpoints.people.cancelFollowRequest,
    pageData
  );

  return data;
};

export const getMyFollowingPeople = async (
  params: PaginateParamType & {
    text?: string;
  } = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(networkEndpoints.people.myFollowings, {
    params,
  });

  return followingNormalizer<PeopleType>(data);
};

export const getMyFollowerPeople = async (
  params: PaginateParamType & {
    text?: string;
  } = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(networkEndpoints.people.myFollowers, {
    params,
  });

  function normalizer(data: any) {
    data.content = data?.content?.map((item) => ({
      ...item,
      fullName: `${item?.name} ${item?.surname}`,
    }));
    return data;
  }

  return normalizer(data);
};

export const getPendingRequests = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get<PaginateResponse<PageAndPeopleType>>(
    networkEndpoints.people.pendingRequests,
    {
      params,
    }
  );

  // @ts-ignore
  return pendingNormalizer(networkObjectNormalizer(data));
};

export const getUpcomingBirthDays = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<IUpcomingBirthDay>> => {
  const { data } = await request.get<PaginateResponse<IUpcomingBirthDayApi>>(
    networkEndpoints.people.upcomingBirthDays,
    {
      params,
    }
  );

  return networkNormalizer.upComingBirthDays(data);
};

export const getMutualPeoples = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(networkEndpoints.people.mutual, {
    params,
  });

  return networkNormalizer.suggestPeople(data);
};

export const getMutual = async (
  params: PaginateParamType = {}
): Promise<PaginateResponse<PeopleType>> => {
  const { data } = await request.get(networkEndpoints.common.mutual, {
    params,
  });

  return networkNormalizer.getMutual(data);
};

export default {
  followPeople,
  unfollowPeople,
  getSuggestionPeople,
  getMyFollowRequests,
  acceptFollow,
  declineFollow,
  getMyFollowingPeople,
  getMyFollowerPeople,
  getPendingRequests,
  cancelFollowRequest,
  getUpcomingBirthDays,
  getMutualPeoples,
  getMutual,
  getPopularPeople,
};
