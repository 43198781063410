import { useSearchParams } from 'next/navigation';
import useClientRouter from './useClientRouter';

const useUrlQuery = () => {
  // TODO: figureout something different that the ||
  const queryParams = useSearchParams();
  const { query } = useClientRouter({});

  return {
    get: (prop) => query.get(prop) || queryParams.get(prop),
    getAll: (prop) =>
      query.getAll(prop)?.length
        ? query.getAll(prop)
        : queryParams.getAll(prop),
  };
};

export default useUrlQuery;
