import request from '../utils/request';
import Endpoints from '../constants/endpoints';

export type UploadFileProps = {
  file: any;
  multiSizeImage?: boolean;
  onProgress?: any;
  cancelRef?: any;
  signal?: any;
};

export const uploadFile = ({
  file,
  multiSizeImage = false,
  onProgress,
  cancelRef,
}: UploadFileProps): Promise<any> => {
  const formData = new FormData();
  const extension = file.type.split('/')[1];
  const fileName = file.name ? file.name : `filename.${extension}`;
  formData.append('file', file, fileName);
  formData.append('multiSizeImage', multiSizeImage.toString());

  return request.post(Endpoints.App.Common.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 0,
    // TODO use fetch cancel here
    // cancelToken: !cancelRef
    //   ? undefined
    //   : new CancelToken((c: any) => {
    //       cancelRef.current = c;
    //     }),
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress?.(percentCompleted);
    },
  });
};

const uploadCroppedImage = async ({
  onProgress,
  image: { original, cropped },
}: any): Promise<{
  originalResponse: any;
  croppedResponse: any;
}> => {
  const result = await Promise.all([
    uploadFile({ onProgress, file: original, multiSizeImage: true }),
    uploadFile({ file: cropped, multiSizeImage: true }),
  ]);

  return { originalResponse: result?.[0], croppedResponse: result?.[1] };
};

export default { uploadFile, uploadCroppedImage };
