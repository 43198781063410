import type {
  PaginateResponse,
  IGetFollowers,
  PageAndPeopleType,
  PageOrPeopleType,
} from '../../types';

export type NetworkObjectNormalizerType<T = PageOrPeopleType> =
  PaginateResponse<
    T & {
      usernameAtSign: string;
      to: string;
      fullName: string;
      isPage: boolean;
    }
  >;

const networkObjectNormalizer = (
  { content, ...rest }: PaginateResponse<PageAndPeopleType>,
  isAuthObjectFollowing?: boolean
): NetworkObjectNormalizerType<IGetFollowers> => ({
  ...rest, // @ts-ignore
  content: content.reduce(
    // @ts-ignore
    (prev: Array<IGetFollowers>, curr: PageAndPeopleType) => {
      if (curr.page?.hideIt || curr.person?.hideIt) {
        return prev;
      }
      return [
        ...prev,
        curr.page
          ? {
              ...curr.page,
              usernameAtSign: `@${curr.page.username}`,
              to: `/${curr.page.username}`,
              fullName: curr.page.title,
              subTitle: `@${curr.page.username}`,
              isPage: true,
              ...(isAuthObjectFollowing ? { follow: true } : {}),
            }
          : {
              ...curr.person,
              usernameAtSign: `@${curr.person.username}`,
              to: `/${curr.person.username}`,
              fullName: `${curr.person.name} ${curr.person.surname}`,
              subTitle: `@${curr.person.username}`,
              isPage: false,
              ...(isAuthObjectFollowing
                ? { follow: true, followStatus: 'ACCEPTED' }
                : {}),
            },
      ];
    },
    []
  ),
});

export default networkObjectNormalizer;
