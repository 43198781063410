export const getVideoImage = (
  src: string,
  timeInSecond = 1,
): Promise<any> => new Promise((resolve, reject) => {
  const video = document.createElement('video');
  video.crossOrigin = 'anonymous';
  video.autoplay = false;
  video.src = src;
  video.currentTime = timeInSecond;

  video.onloadeddata = () => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas?.getContext('2d')?.drawImage(
      video, 0, 0, canvas.width, canvas.height,
    );

    resolve(canvas.toDataURL());
  };
  video.onerror = (error) => {
    reject(error);
  }
});

export default getVideoImage;
