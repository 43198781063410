import request from '../utils/request';
import { networkEndpoints, searchEndPoints } from '../constants';
import type { IFeedElement, PaginateResponse, Response } from '../types';
import type {
  ISearchPeople,
  ISearchPeopleApi,
  ISuggestHashtagApi,
  ISuggestObject,
  ISuggestObjectApi,
  ISuggestHashtag,
  ISearchPage,
  ISearchPageApi,
  ISearchHashtagApi,
  ISearchHashtag,
  ISearchPostApi,
  IUserMention,
} from '../types/search';
import searchNormalizer from '../utils/normalizers/search';
import factorFeeds from '../utils/normalizers/factorFeeds';

export interface IPaginateParams {
  size: number;
  page: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

interface ISearchParams extends IPaginateParams {
  text: string;
}

const search = async <T>({
  url,
  ...params
}: ISearchParams & { url: string }): Promise<PaginateResponse<T>> => {
  const { data } = await request.get<PaginateResponse<T>>(url, { params });
  return data;
};

export const suggestObjects = async (
  params: ISearchParams
): Promise<PaginateResponse<ISuggestObject>> => {
  params.includeUsername = true;
  const url = searchEndPoints.suggestObject;
  const data = await search<ISuggestObjectApi>({ ...params, url });

  return searchNormalizer.object(data);
};

export const suggestHashtags = async (
  params: ISearchParams
): Promise<PaginateResponse<ISuggestHashtag>> => {
  const url = searchEndPoints.suggestHashtag;
  const { text, ...rest } = params || {};
  const data = await search<ISuggestHashtagApi>({
    url,
    text: text?.toLowerCase(),
    ...rest,
  });

  return searchNormalizer.suggestHashtags(data);
};

export const searchPerson = async (
  params: ISearchParams
): Promise<PaginateResponse<ISearchPeople>> => {
  const url = searchEndPoints.searchPerson;
  const data = await search<ISearchPeopleApi>({ ...params, url });

  return searchNormalizer.person(data);
};

export const getSearchPersonFilters = async ({ params }: any): Promise<any> => {
  const { data } = await request.get<any>(searchEndPoints.searchPersonFilter, {
    params,
  });
  return searchNormalizer.personFilters(data);
};

export const getSearchPagesFilters = async ({ params }: any): Promise<any> => {
  const { data } = await request.get<any>(searchEndPoints.searchPagesFilter, {
    params,
  });
  return searchNormalizer.personFilters(data);
};

export const getSearchPostFilters = async ({ params }: any): Promise<any> => {
  const { data } = await request.get<any>(searchEndPoints.searchPostFilter, {
    params,
  });
  return searchNormalizer.personFilters(data);
};

export const searchPages = async (
  params: ISearchParams
): Promise<PaginateResponse<ISearchPage>> => {
  const url = searchEndPoints.searchPage;
  const data = await search<ISearchPageApi>({ ...params, url });

  return searchNormalizer.page(data);
};

export const searchHashtags = async (
  params: ISearchParams
): Promise<PaginateResponse<ISearchHashtag>> => {
  const url = searchEndPoints.searchHashtags;
  const { text, ...rest } = params || {};
  const data = await search<ISearchHashtagApi>({
    ...rest,
    text: text?.toLowerCase(),
    url,
  });

  return searchNormalizer.hashtag(data);
};

export const searchPosts = async (
  params: ISearchParams
): Promise<PaginateResponse<IFeedElement>> => {
  const url = searchEndPoints.searchPosts;
  const data = await search<ISearchPostApi>({ ...params, url });

  // @ts-ignore
  return factorFeeds(data, true);
};

export const getUsersToMention = async (params: {
  size: number;
  text: string;
}): Promise<Response<IUserMention>> => {
  const url = searchEndPoints.mentions;
  const { data } = await request.get<Response<IUserMention>>(url, { params });
  // @ts-ignore
  return data.reduce((prev: Array<ISearchPage>, curr: any) => {
    if (curr.hideIt) {
      return prev;
    }
    return [...prev, curr];
  }, []);
};

export const getPopularHashtags = async (params: {
  size: number;
  includeMyFollowing: boolean;
  includefollowersCounter: boolean;
}): Promise<Response<ISuggestHashtag>> => {
  const url = networkEndpoints.hashtag.mostPopular;
  const { data } = await request.get<Response<ISuggestHashtag>>(url, {
    params,
  });
  return data;
};

export const getObjectById = async ({
  params,
}: {
  params: {
    ids: Array<string>;
  };
}): Promise<Array<ISuggestObject>> => {
  const { data } = await request.get(searchEndPoints.getObjectById, {
    params,
  });
  return data.map((item: ISuggestObjectApi) => ({
    ...item,
    type: item.userType,
    fullName:
      item.userType === 'PAGE'
        ? (item.title as string)
        : `${item.name} ${item.surname}`,
    subTitle:
      item.userType === 'PAGE'
        ? item.industryName
        : (item.occupationName as any),
    locationTitle: item?.locationTitle || item?.locations?.[0]?.title,
  }));
};

export const getObjectDetailByIds = async (
  ids: string
): Promise<{ [key: string]: ISuggestObject }> => {
  const { data } = await request.get(searchEndPoints.getObjectById, {
    params: {
      ids,
    },
  });
  return searchNormalizer.objectDetailById(data);
};

export default {
  suggestHashtags,
  suggestObjects,
  searchPerson,
  searchPages,
  searchHashtags,
  searchPosts,
  getUsersToMention,
  getPopularHashtags,
  getObjectById,
  getObjectDetailByIds,
  getSearchPersonFilters,
  getSearchPagesFilters,
  getSearchPostFilters,
};
