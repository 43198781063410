import UA from 'ua-parser-js';

interface DeviceInfoType {
  os: {
    name?: string;
    version?: string;
  };
  browser: {
    name?: string;
    version?: string;
  };
}

const deviceInfo = (userAgent: string): DeviceInfoType => {
  const parser = UA(userAgent);

  return {
    os: {
      name: parser?.os?.name,
      version: parser?.os?.version,
    },
    browser: {
      name: parser?.browser?.name,
      version: parser?.browser?.version,
    },
  };
};

export default deviceInfo;
