import { useEffect } from 'react';
import useStateCallback from './useStateCallback';
import type { SetStateCallbackGeneric } from './useStateCallback';

const useDebounce = <T = any>(
  initialValue: T,
  delay: number,
  callback?: Function
): T | undefined => {
  const [value, setValue] = useStateCallback<T>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(initialValue, callback);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, initialValue]); // eslint-disable-line

  return value;
};

type UseDebounceStateType<T> = {
  value?: T;
  debounceValue?: T;
  setValue: SetStateCallbackGeneric<T>;
};

const useDebounceState = <T = any>(
  initialValue: T,
  delay: number
): UseDebounceStateType<T> => {
  const [value, setValue] = useStateCallback<T>(initialValue);
  const debounceValue = useDebounce(value, delay);

  return { value, setValue, debounceValue };
};

export { useDebounce, useDebounceState };
