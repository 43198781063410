const LandingEndpoints = {
  Auth: {
    postLogin: '/auth-service/api/v1/login',
    postForgetPassword: '/auth-service/api/v1/forgot-password',
    postForgetPasswordConfirm: '/auth-service/api/v1/reset-password',
    postEmail: '/auth-service/api/v1/signup',
    postDetail: 'auth-service/api/v1/signup-completion',
    postResendEmail: '/auth-service/api/v1/resend-email-verification',
    checkToken: '/auth-service/api/v1/verify-email',
    logout: 'auth-service/api/v1/logout',
    getAuthUserInfo: 'auth-service/api/v1/users/me',
    birthDate: '/auth-service/api/v1/users/birth-date',
    setLocation: '/auth-service/api/v1/users/location',
    setUserAvatar: '/auth-service/api/v1/users/image',
  },
  Search: {
    getProfiles: '/profile-service/api/v1/profiles',
  },
  Common: {
    getCountries: '/lookup/v1/api/location/search-countries',
    getCities: '/lookup/v1/api/location/search-cities',
    getOccupations: '/lookup-service/api/v1/occupation/search',
  },
};

export default LandingEndpoints;
