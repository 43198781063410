type MyKey = 'title' | 'type' | 'id';

type DataItemType = Record<MyKey, string>;
type DataType = DataItemType[];

const skillsResponseNormalizer = (response: DataType) =>
  response
    ?.map(({ id, title, type }) => ({
      value: id,
      label: title,
      type,
    }))
    .slice(0, 6);

export default skillsResponseNormalizer;
