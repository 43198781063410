import { format } from 'date-fns';

const formatDate = (date: Date, formatValue = 'MMM d, y'): string => {
  try {
    return format(new Date(date), formatValue);
  } catch (err) {
    console.log(err);
    return 'Invalid date';
  }
};

export default formatDate;
