import { useEffect, useRef } from 'react';

type Keys = 'Escape' | 'Enter';

interface IOptions {
  key?: Keys | Keys[];
}

const useKeyPress = (
  callback: Function,
  options: IOptions = {}
): { elementRef: React.ForwardedRef<any> } => {
  const elementRef = useRef(null);
  useEffect(() => {
    let element: any = window;
    if (elementRef.current) {
      element = elementRef.current;
    }
    element.addEventListener('keyup', (e: any) => {
      const { key = 'Escape' } = options;
      if (typeof key === 'string') {
        if (e.key === key) {
          if (typeof callback === 'function') callback();
        }
      } else if (Array.isArray(key)) {
        if (key.includes(e.key)) {
          if (typeof callback === 'function') callback();
        }
      }
    });

    return () => {
      element.removeEventListener('keyup', () => null, {});
    };
  }, []);

  return { elementRef };
};

export default useKeyPress;
